import { useSession } from 'context/sessionManagementContext/context';

import ProfileDropdown from './profileDropdown';
import { IProfileDropdownContainer } from './types';

const ProfileDropdownContainer: React.FC<IProfileDropdownContainer> = ({ onOpen }) => {
  const { logout } = useSession();

  const handleLogout = () => {
    onOpen();
    logout();
  };

  return <ProfileDropdown onLogout={handleLogout} />;
};

export default ProfileDropdownContainer;
