/* eslint-disable no-use-before-define */
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import ModalsContext from 'context/modalsContext/context';
import { Modals } from 'context/modalsContext/types';
import { initialSecurityValues } from 'constants/formik';
import {
  DISABLE_MFA,
  ENABLE_EMAIL_MFA,
  ENABLE_GOOGLE_MFA,
  UPDATE_LOGIN_REQUIRE_MFA,
  USER_SECURITY,
} from 'graphql/auth';
import { IUserSecurity } from 'types';
import { getUserSecurityMethod } from 'helpers/auth';
import { isDisabledChangeMFAButton } from 'helpers/buttons';

import AccountSecurity from './accountSecurity';
import { ISecurityValues } from './types';

const AccountSecurityContainer: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const formik = useFormik({
    initialValues: initialSecurityValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const [currentSecurity, setCurrentSecurity] = useState<IUserSecurity>();

  const { data: userSecurityData, loading: userSecurityLoading } = useQuery(USER_SECURITY, {
    fetchPolicy: 'cache-and-network',
  });
  const [updateLoginRequired] = useMutation(UPDATE_LOGIN_REQUIRE_MFA, {
    fetchPolicy: 'no-cache',
    refetchQueries: [USER_SECURITY],
  });
  const [enableEmailMFA] = useMutation(ENABLE_EMAIL_MFA, { fetchPolicy: 'no-cache', refetchQueries: [USER_SECURITY] });
  const [enableGoogleMFA] = useMutation(ENABLE_GOOGLE_MFA, {
    fetchPolicy: 'no-cache',
    refetchQueries: [USER_SECURITY],
  });
  const [disableMFA] = useMutation(DISABLE_MFA, { fetchPolicy: 'no-cache', refetchQueries: [USER_SECURITY] });

  useEffect(() => {
    if (userSecurityData) {
      const userSecurity = pathOr<IUserSecurity | null>(null, ['userSecurity'], userSecurityData);

      if (userSecurity) {
        formik.setFieldValue('mfaType', getUserSecurityMethod(userSecurity));
        formik.setFieldValue('requredAtLogin', userSecurity.requireLogin_2fa);
        setCurrentSecurity(userSecurity);
      }
    }
  }, [userSecurityData]);

  const changeLoginRequired = async (isRequired: boolean, code?: string) => {
    const variables = { isRequired, code };

    await updateLoginRequired({ variables });
  };

  const changeMFA = async (type: 'disable' | 'email' | 'google', code?: string) => {
    const variables = { code };

    if (type === 'disable') {
      await disableMFA({ variables });
    }

    if (type === 'email') {
      await enableEmailMFA({ variables });
    }

    if (type === 'google') {
      await enableGoogleMFA({ variables });
    }
  };

  const handleUpdate = async (code?: string, onError?: (err: string) => void) => {
    const { mfaType, requredAtLogin } = formik.values;

    const changes = [];

    if (requredAtLogin !== currentSecurity?.requireLogin_2fa) {
      changes.push(changeLoginRequired(requredAtLogin, code));
    }

    if (mfaType === 'none' && (currentSecurity?.email_2fa || currentSecurity?.google_2fa)) {
      changes.push(changeMFA('disable', code));
    }

    if (
      (mfaType === 'email' && !currentSecurity?.email_2fa) ||
      (mfaType === 'google' && !currentSecurity?.google_2fa)
    ) {
      changes.push(changeMFA(mfaType, code));
    }

    await Promise.all(changes)
      .then(() => showModal({ modal: undefined }))
      .catch((err) => (onError ? onError(err.message) : null));
  };

  const mfaModal = (type: 'email' | 'google') => {
    showModal({
      modal: Modals.mfa,
      data: { mfaType: type, sendEmail: true, action: handleUpdate },
    });
  };

  function handleSubmit(values: ISecurityValues) {
    const { mfaType, requredAtLogin } = values;

    if (
      (mfaType === 'none' && (currentSecurity?.email_2fa || currentSecurity?.google_2fa)) ||
      requredAtLogin !== currentSecurity?.requireLogin_2fa
    ) {
      mfaModal(currentSecurity?.email_2fa ? 'email' : 'google');
    }

    if (mfaType === 'email' && !currentSecurity?.email_2fa) {
      mfaModal('email');
    }

    if (mfaType === 'google' && !currentSecurity?.google_2fa) {
      showModal({ modal: Modals.authenticator, data: { action: handleUpdate } });
    }
  }

  return (
    <AccountSecurity
      formik={formik}
      loading={userSecurityLoading}
      disabled={isDisabledChangeMFAButton(formik.values, currentSecurity)}
    />
  );
};

export default AccountSecurityContainer;
