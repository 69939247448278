import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import UserContext from 'context/userContext/context';
import { ITableRow } from 'components/common/table/tableRow/types';
import { DASHBOARD_TABLE_HEAD, ITEMS_PER_PAGE } from 'constants/table';
import { AFFILIATE_REFERRALS } from 'graphql/referrals';
import { getDateForRequest, getOffset, getAffiliateReferralsVariables } from 'helpers/request';
import { getAffiliateReferralsRows } from 'helpers/table';
import { ChangedDates } from 'components/common/dateRange/types';

import TableContainer from 'components/common/table/tableWithPagination';
import DashboardReferralActivityFilter from './components/dashboardReferralActivityFilter';

const DashboardReferralActivityTable: React.FC = () => {
  const { profile } = useContext(UserContext);

  const [rows, setRows] = useState<ITableRow[]>([]);
  const [active, setActive] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [showPerPage, setShowPerPage] = useState(ITEMS_PER_PAGE);

  const { data, loading, refetch } = useQuery(AFFILIATE_REFERRALS, {
    fetchPolicy: 'cache-and-network',
    skip: !profile,
    variables: getAffiliateReferralsVariables({}),
  });

  useEffect(() => {
    if (data) {
      const referrals = pathOr([], ['affiliateReferrals', 'items'], data);
      const referralsCount = pathOr(0, ['affiliateReferrals', 'total'], data);
      const actvieReferrals = pathOr(0, ['affiliateReferrals', 'active'], data);
      const referralsRows = getAffiliateReferralsRows(referrals);

      setRows(referralsRows);
      setCount(referralsCount);
      setActive(actvieReferrals);
    }
  }, [data]);

  const handleChangePage = (newPage: number) => {
    const offset = getOffset(newPage, showPerPage);
    const variables = getAffiliateReferralsVariables({ offset, limit: showPerPage });

    setPage(newPage);
    refetch(variables);
  };

  const handleChangePerPage = (newCount: string | null) => {
    if (newCount) {
      const limit = Number(newCount);
      const offset = getOffset(page, limit);
      const variables = getAffiliateReferralsVariables({ offset, limit });

      setShowPerPage(limit);
      refetch(variables);
    }
  };

  const handleDatesChange = (dates: ChangedDates) => {
    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      const dateFrom = getDateForRequest(startDate);
      const dateTo = getDateForRequest(endDate);
      const offset = getOffset(page, showPerPage);
      const variables = getAffiliateReferralsVariables({ from: dateFrom, to: dateTo, offset, limit: showPerPage });

      refetch(variables);
      return;
    }

    if (!startDate && !endDate) {
      const offset = getOffset(page, showPerPage);
      const variables = getAffiliateReferralsVariables({ offset, limit: showPerPage });

      refetch(variables);
    }
  };

  return (
    <div className="flex h-[700px] w-full flex-col">
      <TableContainer
        headCells={DASHBOARD_TABLE_HEAD}
        rows={rows}
        page={page}
        count={count}
        showPerPage={showPerPage}
        loading={loading}
        onChangePage={handleChangePage}
      >
        <DashboardReferralActivityFilter
          count={count}
          active={active}
          showPerPage={showPerPage}
          onChangePerPage={handleChangePerPage}
          onDatesChange={handleDatesChange}
        />
      </TableContainer>
    </div>
  );
};

export default DashboardReferralActivityTable;
