import MobileMenu from 'components/common/base/mobileMenu';
import Logo from 'components/common/base/logo';
import ProfileContainer from 'components/common/profile';
import UnauthButtonsContainer from 'components/common/unauthButtons';

import { IHeader } from './types';
import styles from './styles.module.scss';

const Header: React.FC<IHeader> = ({ authorized, mobileMenuVisible, onShowMenu }) => {
  return (
    <>
      {authorized ? <MobileMenu mobileMenuVisible={mobileMenuVisible} onShowMenu={onShowMenu} /> : null}
      <header className="sticky top-0 z-40 flex h-[68px] w-full items-center bg-semi-black px-6 py-4">
        {authorized ? (
          <div className="hidden items-center max-[1023px]:flex">
            <button type="button" className={styles.burger} onClick={onShowMenu}>
              <span className="visually-hidden">Menu</span>
            </button>
          </div>
        ) : null}
        <Logo auth={authorized} />
        <div className="relative ml-auto">{authorized ? <ProfileContainer /> : <UnauthButtonsContainer />}</div>
      </header>
    </>
  );
};

export default Header;
