import Button from 'components/common/buttons/button';
import Box from 'components/common/content/box';

import { IDashboardOverviewPromo } from './types';

const DashboardOverviewPromo: React.FC<IDashboardOverviewPromo> = ({ onView }) => {
  return (
    <Box
      component="li"
      className="mr-4 h-[118px] w-full max-w-[25%] max-[1280px]:max-w-[48.5%] max-[720px]:mb-4 max-[720px]:mr-0 max-[720px]:max-w-full"
    >
      <h5 className="mb-5 font-medium">Promo Materials:</h5>
      <div className="flex items-center">
        <Button title="View Materials" variant="main" wrapperClassList="mr-4" onClick={onView} />
      </div>
    </Box>
  );
};

export default DashboardOverviewPromo;
