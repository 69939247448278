import { ExternalLinks } from 'constants/nav';

import Logo from 'components/common/base/logo';

const Footer: React.FC = () => {
  return (
    <footer className="flex items-center justify-between bg-semi-black px-20 py-4 max-[730px]:flex-col max-[730px]:justify-center max-[450px]:px-10">
      <Logo />
      <p className="text-center text-xs max-[730px]:mt-4">
        Copyright © 2023{' '}
        <a href={ExternalLinks.starbets} target="_blank" rel="noreferrer">
          Starbets.io
        </a>
        . All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
