import dayjs from 'dayjs';
import { useContext } from 'react';

import { ITEMS_PER_PAGE_OPTION } from 'constants/table';
import UserContext from 'context/userContext/context';

import DateRange from 'components/common/dateRange';
import Select from 'components/common/select';

import { IReferralsFilter } from './types';

const ReferralFilter: React.FC<IReferralsFilter> = ({ onDatesChange, showPerPage, onChangePerPage, pageOptions }) => {
  const { profile } = useContext(UserContext);

  return (
    <div className="flex items-center max-sm:w-full">
      <div className="mr-4 flex items-center max-sm:mr-0 max-sm:w-full max-sm:justify-between">
        <span className="min-md:mb-4 text-md min-sm:mr-0 mr-2 whitespace-nowrap text-light-gray">Date:</span>
        <DateRange
          className="h-[38px]"
          minDate={dayjs(profile?.user.createdAt).toDate()}
          maxDate={new Date()}
          onDatesChange={onDatesChange}
        />
      </div>
      <div className="flex items-center max-sm:hidden">
        <Select
          label="Show"
          labelAlign="center"
          labelClassList="m-0"
          labelWrapperClassList="flex flex-row space-x-2 items-center"
          containerClassName="!pl-4"
          menuClassName="!z-20"
          selected={String(showPerPage)}
          optionClassName="!px-4"
          options={pageOptions || ITEMS_PER_PAGE_OPTION}
          onSelect={onChangePerPage}
        />
      </div>
    </div>
  );
};

export default ReferralFilter;
