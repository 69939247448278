/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CheckIcon } from 'assets/common/check.svg';

import { ICheckbox } from './types';

const Checkbox: React.FC<ICheckbox> = ({
  formik,
  label = '',
  lableWrapperClassList = '',
  errorMessage,
  onChange,
  ...props
}) => {
  const wrapperStyles = twMerge('flex items-center group', lableWrapperClassList);
  const checkboxWrapperStyles = twMerge(
    'relative animation flex h-[17px] min-h-[17px] w-[17px] min-w-[17px] p-[2px] cursor-pointer rounded-[6px]\
    bg-light-gray group-hover:bg-purple',
    errorMessage ? 'bg-red' : '',
  );
  const checkboxStyles = twMerge('w-full h-full bg-dark-blue rounded-[4px]');
  const checkIcon = twMerge(
    'animation absolute left-1/2 top-1/2 z-10 h-auto w-[12px] -translate-x-1/2 -translate-y-1/2 fill-green opacity-0',
    props?.checked ? 'opacity-100' : '',
  );
  const labelStyles = twMerge('ml-2 cursor-pointer text-sm text-light-gray selection:pointer-events-none');

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (formik) {
      formik.setErrors({});
    }

    if (onChange) onChange(ev);
  };

  return (
    <label className={wrapperStyles}>
      <input className="visually-hidden" type="checkbox" onChange={handleChange} {...props} />
      <i className={checkboxWrapperStyles}>
        <i className={checkboxStyles} />
        <CheckIcon className={checkIcon} />
      </i>
      <p className={labelStyles}>{label}</p>
    </label>
  );
};

export default Checkbox;
