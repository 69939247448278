import { useState, useEffect, useContext } from 'react';

import UserContext from 'context/userContext/context';

import Header from './header';

const HeaderContainer: React.FC = () => {
  const { profile } = useContext(UserContext);

  const [authorized, setAuthorized] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  useEffect(() => {
    setAuthorized(Boolean(profile));
  }, [profile]);

  const handleShowMenu = () => {
    setMobileMenuVisible((v) => !v);
  };

  return <Header authorized={authorized} mobileMenuVisible={mobileMenuVisible} onShowMenu={handleShowMenu} />;
};

export default HeaderContainer;
