import successIcon from 'assets/login/success.webp';
import clsx from 'clsx';

import Modal from 'components/common/base/modal';
import Button from 'components/common/buttons/button';

import styles from './styles.module.scss';
import { IRegistrationSuccessModal } from './types';

const RegistrationSuccessModal: React.FC<IRegistrationSuccessModal> = ({ text, onClose }) => {
  return (
    <Modal title="" modalClassName="max-w-[350px] w-full" withPaddings={false}>
      <div className={clsx('w-full pb-16', styles.imageWrapper)}>
        <img className={clsx('h-auto w-full', styles.image)} src={successIcon} alt="Success!" />
      </div>
      <div className="flex flex-col items-center px-6 pb-6">
        <h6 className="mb-2 text-2xl font-bold">Success!</h6>
        <p className="mb-4 text-center text-sm">{text}</p>
        <Button variant="main" title="Close" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default RegistrationSuccessModal;
