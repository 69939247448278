import { useContext } from 'react';

import { Modals } from 'context/modalsContext/types';

import Button from 'components/common/buttons/button';
import ModalsContext from 'context/modalsContext/context';

const CampaignsCreateNew: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const handleCreate = () => {
    showModal({ modal: Modals.createCampaign });
  };

  return <Button wrapperClassList="mt-2" variant="secondary" title="Create Campaign" onClick={handleCreate} />;
};

export default CampaignsCreateNew;
