/* eslint-disable jsx-a11y/click-events-have-key-events */
import { twMerge } from 'tailwind-merge';
import { IMfaMethod } from './types';

const MfaMethod: React.FC<IMfaMethod> = ({ checked, title, description, icon: Icon, onCheck }) => {
  return (
    <li
      className={twMerge(
        'animation mr-4 flex h-full w-full flex-grow cursor-pointer flex-col items-center justify-center rounded-[20px] border-2 py-4 text-gray last:mr-0 max-[520px]:mb-4 max-[520px]:mr-0 max-[520px]:last:mb-0',
        checked ? 'border-purple bg-white/5' : '',
      )}
      onClick={onCheck}
    >
      <div className="mb-2 flex items-center justify-center">
        <Icon className="h-auto w-[50px]" />
      </div>
      <div className="flex flex-col items-center">
        <p
          className={twMerge('text-sm font-bold max-[960px]:text-xs max-[870px]:text-sm', checked ? 'text-white' : '')}
        >
          {title}
        </p>
        <p className={twMerge('text-xs font-medium', checked ? 'text-white' : '')}>{description}</p>
      </div>
    </li>
  );
};

export default MfaMethod;
