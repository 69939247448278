import { Helmet } from 'react-helmet';

import transactionsIcon from 'assets/nav/transactions.webp';

import PageTitle from 'components/common/text/title/page';
import TransactionsTableContainer from './components/transactionsTable';

const TransactionsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Transactions - StarBets Affiliates</title>
      </Helmet>
      <div className="flex h-full max-h-[calc(100vh-148px)] w-full flex-col overflow-hidden">
        <PageTitle title="Transactions" icon={transactionsIcon} color="green" />
        <div className="flex h-full w-full flex-grow flex-col overflow-hidden">
          <TransactionsTableContainer />
        </div>
      </div>
    </>
  );
};

export default TransactionsPage;
