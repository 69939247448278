import HomepageTitle from 'components/common/text/title/homapage';
import HomepageDescription from 'components/common/text/description/homepageDescription';

import RatesContainer from './components/rates';

const CommissionRates: React.FC = () => {
  return (
    <section className="container mb-20 flex flex-col items-center">
      <HomepageTitle component="h2">Commission Rates</HomepageTitle>
      <HomepageDescription classList="mb-10">
        Our affiliates earn commission rates of up to 50% and receive our full support to integrate the campaigns. To
        become an affiliate - sign up for an account, and an affiliate manager will be in touch with you. Alternatively,
        send a query to{' '}
        <a className="!text-green" href="mailto:affiliate@starbets.io">
          affiliate@starbets.io
        </a>{' '}
        or contact{' '}
        <a className="!text-green" href="mailto:support@starbets.io">
          support@starbets.io
        </a>{' '}
        if you&apos;re an existing affiliate and encounter technical issues.
      </HomepageDescription>
      <RatesContainer />
    </section>
  );
};

export default CommissionRates;
