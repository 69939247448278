import clsx from 'clsx';

import HomepageTitle from 'components/common/text/title/homapage';
import Button from 'components/common/buttons/button';

import { IInvitation } from './types';
import styles from './styles.module.scss';

const Invitation: React.FC<IInvitation> = ({ onRegistration }) => {
  return (
    <section className={clsx('flex flex-col items-center justify-center', styles.wrapper)}>
      <div className="container flex flex-col items-center">
        <HomepageTitle component="h2">Join StarBets Affiliate Program</HomepageTitle>
        <Button
          type="button"
          buttonsClassList="min-w-[150px]"
          title="Join Now"
          variant="main"
          onClick={onRegistration}
        />
      </div>
    </section>
  );
};

export default Invitation;
