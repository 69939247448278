import { useContext, useRef, useEffect, useState } from 'react';

import UserContext from 'context/userContext/context';
import { StarbetsLinks } from 'constants/config';

import DashboardOverviewLink from './dashboardOvervieLink';

const DsahboardOverviewLinkContainer: React.FC = () => {
  const { profile } = useContext(UserContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const [referralLink, setReferralLink] = useState('');

  useEffect(() => {
    if (profile) {
      const { user } = profile;

      setReferralLink(`${StarbetsLinks.affiliate}${user.referralCode}`);
    }
  }, [profile]);

  const handleLinkClick = () => {
    if (inputRef?.current) {
      inputRef.current.select();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
  };

  return (
    <DashboardOverviewLink
      inputRef={inputRef}
      referralLink={referralLink}
      onLinkClick={handleLinkClick}
      onCopy={handleCopy}
    />
  );
};

export default DsahboardOverviewLinkContainer;
