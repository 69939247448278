import { string } from 'yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';

import ModalsContext from 'context/modalsContext/context';
import { RESET_PASSWORD } from 'graphql/auth';
import { Modals } from 'context/modalsContext/types';

import ForgotPasswordModal from './forgotPassword';

const ForgotPasswordContainer: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [resetEmail, { data, loading, error }] = useLazyQuery(RESET_PASSWORD, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      showModal({ modal: Modals.success, data: { message: 'Check your email' } });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  const handleChangeEmail = (newEmail: string) => {
    if (errorMessage) setErrorMessage('');

    setEmail(newEmail);
  };

  const handleResetPassword = async () => {
    if (errorMessage) return;

    const isValid = await string()
      .email('Please enter a valid email')
      .required('Email is required')
      .validate(email)
      .then(() => true)
      .catch((err) => {
        setErrorMessage(err.message);
        return false;
      });

    if (isValid) {
      const captcha = await recaptchaRef.current?.executeAsync();
      const variables = { captcha, email: email.toLowerCase() };

      await resetEmail({ variables });
    }
  };

  return (
    <ForgotPasswordModal
      recaptcha={recaptchaRef}
      email={email}
      errorMessage={errorMessage}
      loading={loading}
      onChangeEmail={handleChangeEmail}
      onResetPassword={handleResetPassword}
    />
  );
};

export default ForgotPasswordContainer;
