import { ITEMS_PER_PAGE_OPTION_TENS } from 'constants/table';

import { ReactComponent as SearchIcon } from 'assets/common/search.svg';

import ReferralFilter from 'components/common/referralFilter';
import Select from 'components/common/select';
import Input from 'components/common/inputs/input';
import Button from 'components/common/buttons/button';

import { ICampaignReferralsFilter } from './types';

const CampaignReferralsFilter: React.FC<ICampaignReferralsFilter> = ({
  count,
  active,
  campaign,
  onCampaignChange,
  alias,
  aliasError,
  campaignOptions,
  showPerPage,
  onChangePerPage,
  onDatesChange,
  onAliasChange,
  onAliasSubmit,
}) => {
  const aliasErrorFormatted = aliasError ? aliasError.charAt(0).toUpperCase() + aliasError.slice(1) : '';

  return (
    <div className="relative mb-6 flex flex-col items-start space-y-8 max-[900px]:flex-col max-[900px]:items-start">
      <div className="flex w-full items-center text-xl font-bold max-[900px]:mb-4 max-[520px]:text-base">
        <p className="mr-6">
          Referrals: <span className="text-orange">{count}</span>
        </p>
        <p>
          Active: <span className="text-orange">{active}</span>
        </p>
      </div>
      <form
        onSubmit={onAliasSubmit}
        className="flex w-full items-center justify-between max-[1400px]:flex-col max-[1400px]:items-start"
      >
        <div className="flex items-center max-[1400px]:mb-4 max-[870px]:w-full max-[870px]:flex-col max-[870px]:items-start min-[1400px]:mr-4">
          <div className="mr-4 flex items-center max-[870px]:mb-4 max-[870px]:mr-0 max-[870px]:w-full max-[360px]:flex-col">
            <Select
              label="Campaign:"
              labelAlign="center"
              labelClassList="m-0"
              labelWrapperClassList="flex flex-row space-x-2 max-[870px]:max-w-full items-center max-sm:justify-between"
              containerClassName="!pl-4"
              menuClassName="!z-20"
              className="max-sm:w-[300px] max-[530px]:!w-[200px]"
              placeholder="Select Campaign"
              selected={campaign}
              optionClassName="!px-4"
              options={[{ label: 'All Campaigns', value: 'all' }, ...campaignOptions]}
              onSelect={onCampaignChange}
            />
          </div>
          <ReferralFilter
            pageOptions={ITEMS_PER_PAGE_OPTION_TENS}
            showPerPage={showPerPage}
            onChangePerPage={onChangePerPage}
            onDatesChange={onDatesChange}
          />
        </div>
        <div className="flex items-baseline space-x-4">
          <div className="flex !h-[50px] flex-col space-y-2">
            <Input
              type="search"
              className="px-[16px] py-[10px] max-[1400px]:max-w-[250px] min-[1400px]:w-[250px]"
              value={alias}
              errorMessage={aliasErrorFormatted}
              placeholder="Search by Alias"
              icon={SearchIcon}
              iconClassList={`left-auto right-4 ${alias ? 'hidden' : ''}`}
              onChange={(e) => onAliasChange(e.target.value)}
            />
          </div>
          <Button type="submit" variant="secondary" aria-label="search-btn" title="Search" />
        </div>
      </form>
    </div>
  );
};

export default CampaignReferralsFilter;
