/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import { useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { pathOr } from 'ramda';

import ModalsContext from 'context/modalsContext/context';
import { initialChangePasswordValues } from 'constants/formik';
import { changePasswordValidationSchema } from 'constants/validation';
import { CHANGE_PASSWORD } from 'graphql/user';
import { USER_SECURITY } from 'graphql/auth';
import { IUserSecurity } from 'types';
import {
  getChangePasswordData,
  getUserSecurityMethod,
  persistChangePasswordData,
  removePersistedChangePasswordData,
} from 'helpers/auth';
import { IChangePasswordData, Modals } from 'context/modalsContext/types';
import { getChangePasswordVariables } from 'helpers/request';
import { parseErrorMessage } from 'helpers';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';

import ChangePasswordModal from './changePassword';

const ChangePasswordContainer: React.FC = () => {
  const { data, showModal } = useContext(ModalsContext);

  const formik = useFormik({
    initialValues: initialChangePasswordValues,
    validationSchema: changePasswordValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const [mfaMethod, setMfaMethod] = useState<'google' | 'email' | 'none'>('none');
  const [errorMessage, setErrorMessage] = useState('');

  const [changePassword, { loading }] = useLazyQuery(CHANGE_PASSWORD, { fetchPolicy: 'no-cache' });
  const { data: userSecurityData, loading: userSecurityLoading } = useQuery(USER_SECURITY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      const { errorMessage: newErrorMessage } = data as IChangePasswordData;

      if (newErrorMessage) {
        const parsedMessage = parseErrorMessage(newErrorMessage);

        setErrorMessage(parsedMessage?.error_description || newErrorMessage || DEFAULT_ERROR_MESSAGE);
      }

      const parsedData = getChangePasswordData();

      if (parsedData) {
        const { currentPassword, newPassword } = parsedData;

        formik.setFieldValue('currentPassword', currentPassword || '');
        formik.setFieldValue('newPassword', newPassword || '');
        formik.setFieldValue('confirmPassword', newPassword || '');
      }

      removePersistedChangePasswordData();
    }
  }, [data]);

  useEffect(() => {
    if (userSecurityData) {
      const userSecurity = pathOr<IUserSecurity | null>(null, ['userSecurity'], userSecurityData);

      if (userSecurity) {
        const method = getUserSecurityMethod(userSecurity);

        setMfaMethod(method);
      }
    }
  }, [userSecurityData]);

  useEffect(() => {
    if (Object.values(formik.errors).length) {
      setErrorMessage('');
    }
  }, [formik.values]);

  const handleChangePassword = async (code?: string) => {
    const { currentPassword, newPassword } = formik.values;
    const variables = getChangePasswordVariables({ currentPassword, newPassword }, code);

    await changePassword({ variables }).then((res) => {
      if (res.data) {
        removePersistedChangePasswordData();
        showModal({ modal: Modals.success, data: { message: 'Password changed successfully' } });
      }

      if (res.error) {
        showModal({ modal: Modals.changePassword, data: { errorMessage: res.error?.message } });
      }
    });
  };

  async function handleSubmit() {
    if (mfaMethod !== 'none') {
      const { currentPassword, newPassword } = formik.values;
      const variables = getChangePasswordVariables({ currentPassword, newPassword });

      persistChangePasswordData(variables);
      showModal({
        modal: Modals.mfa,
        data: { mfaType: mfaMethod, sendEmail: true, action: handleChangePassword },
      });
    }
  }

  const handleClose = () => {
    removePersistedChangePasswordData();
  };

  return (
    <ChangePasswordModal
      formik={formik}
      errorMessage={errorMessage}
      loading={loading || userSecurityLoading}
      onClose={handleClose}
    />
  );
};

export default ChangePasswordContainer;
