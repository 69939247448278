import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import useMediaQuery from 'hooks/useMediaQuery';
import UserContext from 'context/userContext/context';
import { IAffiliateBalance, IExchangeRate, Settings } from 'types';
import { AFFILIATE_BALANCE, ALL_CURRENCIES_TO_WALLET, CURRENCY_TO_WALLET } from 'graphql/affiliate';
import { EXCHANGE_RATES_SETTINGS } from 'graphql/token';
import { WALLETS } from 'graphql/user';
import {
  convertTokensToUSD,
  getAffiliateBalances,
  getBalanceOptions,
  getConvertedCurrency,
  getDefaultBalances,
  IBalance,
} from 'helpers/currencies';
import { isDisableCollectInCurrenciesButton } from 'helpers/buttons';
import { getSetting } from 'helpers';
import { ISelectOption } from 'components/common/select/types';
import ModalsContext from 'context/modalsContext/context';
import { Modals } from 'context/modalsContext/types';

import CurrencyCard from 'components/common/content/currencyCard';
import Big from 'big.js';

const DashboardOverviewAvailableBalance: React.FC = () => {
  const { tokensWithNetwork, settings } = useContext(UserContext);
  const { showModal } = useContext(ModalsContext);

  const isMobileView = useMediaQuery('(max-width: 500px)');

  const [conversionFee, setConversionFee] = useState('0');
  const [title, setTitle] = useState('Available Balance');
  const [currency, setCurrency] = useState<string | null>(null);
  const [availableBalances, setAvailableBalances] = useState<IBalance[]>([]);
  const [collectedBalance, setCollectedBalance] = useState<IBalance[]>([]);
  const [exchangeRates, setExchangeRates] = useState<IExchangeRate[]>([]);
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [totalCollectedAmount, setTotalCollectedAmount] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const { data: affiliateBalanceData } = useQuery(AFFILIATE_BALANCE, { fetchPolicy: 'cache-only' });
  const { data: exchangeRatesData } = useQuery(EXCHANGE_RATES_SETTINGS, { fetchPolicy: 'cache-only' });

  const [affiliateConvertBalances] = useMutation(CURRENCY_TO_WALLET, {
    refetchQueries: [WALLETS, AFFILIATE_BALANCE, EXCHANGE_RATES_SETTINGS],
  });

  const [affiliateCollectBalances] = useMutation(ALL_CURRENCIES_TO_WALLET, {
    refetchQueries: [WALLETS, AFFILIATE_BALANCE, EXCHANGE_RATES_SETTINGS],
  });

  useEffect(() => {
    if (affiliateBalanceData && tokensWithNetwork) {
      const newBalances = pathOr<IAffiliateBalance[]>([], ['affiliateBalances'], affiliateBalanceData);
      const balancesWithDefault = getDefaultBalances(tokensWithNetwork, newBalances);
      const { available, collected } = getAffiliateBalances(balancesWithDefault);

      setAvailableBalances(available);
      setCollectedBalance(collected);
    }
  }, [affiliateBalanceData, tokensWithNetwork]);

  useEffect(() => {
    if (exchangeRatesData) {
      const rates = pathOr<IExchangeRate[]>([], ['exchangeRates'], exchangeRatesData);

      setExchangeRates(rates);
    }
  }, [exchangeRatesData]);

  useEffect(() => {
    if (options.length) {
      setCurrency(options[0].value);
    }
  }, [options]);

  useEffect(() => {
    if (isMobileView) {
      setTitle('Available');
    } else {
      setTitle('Available Balance');
    }
  }, [isMobileView]);

  useEffect(() => {
    if (settings) {
      const newFee = getSetting(settings, Settings.conversionFee);

      setConversionFee(newFee);
    }
  }, [settings]);

  useEffect(() => {
    if (exchangeRates.length) {
      const fee = getSetting(settings, Settings.conversionFee);

      const totalAvailable = convertTokensToUSD(availableBalances, exchangeRates);
      const totalCollected = convertTokensToUSD(collectedBalance, exchangeRates);
      const options = getBalanceOptions(availableBalances, fee, exchangeRates);

      setTotalAmount(totalAvailable);
      setTotalCollectedAmount(totalCollected);
      setOptions(options);
    }
  }, [exchangeRates, availableBalances, collectedBalance]);

  useEffect(() => {
    const collectedBalance = currency
      ? getConvertedCurrency(currency, availableBalances, conversionFee, exchangeRates)
      : '';
    const collectedBalanceValid = collectedBalance ? Big(collectedBalance.amount).gt(0) : true;
    const disable = isDisableCollectInCurrenciesButton(availableBalances);

    setDisableButton(disable || !collectedBalanceValid);
  }, [currency, availableBalances, conversionFee, exchangeRates]);

  const handleSelectCurrency = (c: string | null) => {
    setCurrency(c);
  };

  const handleCollect = async () => {
    setDisableButton(true);
    try {
      if (currency) {
        const variables = { token: currency };

        await affiliateConvertBalances({ variables });
      } else {
        await affiliateCollectBalances();
      }

      const collectedCurrencies = !currency
        ? availableBalances
        : [getConvertedCurrency(currency, availableBalances, conversionFee, exchangeRates)];

      showModal({ modal: Modals.collect, data: { currencies: collectedCurrencies } });

      setCurrency(null);
      setDisableButton(false);
    } catch {
      setDisableButton(false);
    }
  };

  return (
    <CurrencyCard
      title={title}
      description={
        <>
          Affiliate balance can be collected at any time. When collected, the affiliate balance will be transferred to
          your StarBets wallet. <b className="font-medium text-green">{conversionFee}%</b> conversion fee will be
          applied for collecting in specific currency.
        </>
      }
      totalAmount={totalAmount}
      totalAmountCollected={totalCollectedAmount}
      options={options}
      selectedOption={currency}
      currencies={availableBalances}
      collectedCurrencies={collectedBalance}
      withTotalCollected
      onSelectOption={handleSelectCurrency}
      disableButton={disableButton}
      buttonTitle="Collect"
      onSubmit={handleCollect}
    />
  );
};

export default DashboardOverviewAvailableBalance;
