/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import ModalsContext from 'context/modalsContext/context';
import { CAMPAIGNS, UPDATE_CAMPAIGN } from 'graphql/campaigns';
import { IUpdateCampaignData } from 'context/modalsContext/types';

import UpdateCampaignModal from './updateCampaign';

const UpdateCampaignContainer: React.FC = () => {
  const { data, showModal } = useContext(ModalsContext);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [updateAffiliateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN, {
    refetchQueries: [CAMPAIGNS],
  });

  useEffect(() => {
    if (data) {
      const { id: currentId, name: currentName } = data as IUpdateCampaignData;

      setId(currentId);
      setName(currentName);
    }
  }, [data]);

  const handleChangeName = (newName: string) => {
    if (errorMessage) setErrorMessage('');

    setName(newName);
  };

  const handleSubmit = async () => {
    if (name.length < 6) {
      setErrorMessage('Campaign name must be at least 6 characters');
      return;
    }

    if (name.length > 15) {
      setErrorMessage('Campaign name cannot be more than 15 characters');
      return;
    }

    const variables = { id, name };

    await updateAffiliateCampaign({ variables })
      .then(() => showModal({ modal: undefined }))
      .catch((err) => {
        console.log('[UPDATE_CAMPAIGN_ERROR]:', err);

        setErrorMessage(err.message);
      });
  };

  return (
    <UpdateCampaignModal
      name={name}
      errorMessage={errorMessage}
      loading={loading}
      onChangeName={handleChangeName}
      onSubmit={handleSubmit}
    />
  );
};

export default UpdateCampaignContainer;
