import dayjs from 'dayjs';

import { ITEMS_PER_PAGE } from 'constants/table';
import { IChangePasswordVariables } from 'components/modals/changePassword/types';

interface ITransactionsVariables {
  dateFrom?: string | null;
  dateTo?: string | null;
  limit?: number | null;
  offset?: number | null;
  type?: string | null;
}

interface IAffiliateReferrals {
  offset?: number | null;
  limit?: number | null;
  from?: string | null;
  to?: string | null;
}

export interface IAffiliateCampaignReferrals extends IAffiliateReferrals {
  campaignId?: string;
  alias?: string;
}

interface ILoginActivity {
  activity?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
}

export const getOffset = (page: number, showPerPage: number) => {
  return page === 1 ? 0 : (page - 1) * showPerPage;
};

export const getDateForRequest = (date: string | number | Date) => {
  return dayjs(date).format('ddd MMM D YYYY');
};

export const getTransactionsVariables = (variables: ITransactionsVariables) => {
  const { dateFrom = null, dateTo = null, limit = ITEMS_PER_PAGE, offset = null, type = null } = variables;

  return {
    input: {
      dateFrom,
      dateTo,
      limit,
      offset,
      order: {
        createdAt: 'DESC',
      },
      type,
    },
  };
};

export const getAffiliateReferralsVariables = (variables: IAffiliateReferrals) => {
  const { offset = 0, limit = ITEMS_PER_PAGE, from = null, to = null } = variables;

  return {
    offset,
    limit,
    from,
    to,
  };
};

export const getAffiliateCampaignReferralVariables = (variables: IAffiliateCampaignReferrals) => {
  const { campaignId, alias, ...rest } = variables;
  const referralVars = getAffiliateReferralsVariables(rest);

  return {
    campaignId,
    alias,
    ...referralVars,
  };
};

export const getLoginActivityVariables = (variables: ILoginActivity) => {
  const {
    activity = null,
    offset = 0,
    limit = ITEMS_PER_PAGE,
    dateFrom = null,
    dateTo = null,
    order = null,
  } = variables;

  return {
    input: {
      activity,
      dateFrom,
      dateTo,
      offset,
      limit,
      order,
    },
  };
};

export const getChangePasswordVariables = (vars: Partial<IChangePasswordVariables>, code?: string) => {
  const { currentPassword, newPassword } = vars;

  const variables: IChangePasswordVariables = {
    currentPassword: currentPassword || '',
    newPassword: newPassword || '',
    securityCode: null,
    emailOtp: null,
  };

  if (code) {
    variables.securityCode = code;
    variables.emailOtp = code;
  }

  return variables;
};
