/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactComponent as CopyIcon } from 'assets/common/copy.svg';

import Box from 'components/common/content/box';
import Tooltip from 'components/common/text/tooltip';

import { IDashboardOverviewLink } from './types';

const DashboardOverviewLink: React.FC<IDashboardOverviewLink> = ({ inputRef, referralLink, onLinkClick, onCopy }) => {
  return (
    <Box
      component="li"
      className="mr-4 h-[118px] w-full max-w-[25%] max-[1280px]:mb-4 max-[1280px]:mr-0 max-[1280px]:max-w-[48.5%] max-[720px]:max-w-full"
    >
      <h5 className="mb-5 font-medium">Referral Link:</h5>
      <div className="relative mt-auto rounded-[20px] bg-dark-blue px-4 py-2" onClick={onLinkClick}>
        <input
          ref={inputRef}
          className="w-full bg-transparent align-middle text-xs outline-none max-[1300px]:text-[0.6rem] max-[1280px]:text-sm max-[720px]:text-sm  max-[455px]:text-xs  max-[424px]:text-[0.6rem]"
          value={referralLink}
          onChange={() => null}
        />
        <Tooltip
          text="Copied"
          showOnAction
          position="top"
          className="absolute right-0 top-1/2 ml-auto h-[42px] w-[42px] -translate-y-1/2"
        >
          <button
            type="button"
            className="bgg-green group/button flex h-[42px] w-[42px] items-center justify-center rounded-full"
            onClick={onCopy}
          >
            <CopyIcon className="h-auto w-[20px] group-hover/button:opacity-80" />
          </button>
        </Tooltip>
      </div>
    </Box>
  );
};

export default DashboardOverviewLink;
