import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import ModalsContext from 'context/modalsContext/context';
import { IExchangeRate, IWallet, TokenCode } from 'types';
import { convertTokensToUSD, getWalletBalance, IBalance } from 'helpers/currencies';
import { EXCHANGE_RATES_SETTINGS } from 'graphql/token';
import { isDisableCollectInCurrenciesButton } from 'helpers/buttons';
import { WALLETS } from 'graphql/user';

import CurrencyCard from 'components/common/content/currencyCard';
import { Modals } from 'context/modalsContext/types';

const DashboardOverviewWallet: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const [exchangeRates, setExchangeRates] = useState<IExchangeRate[]>([]);
  const [wallets, setWallets] = useState<IBalance[]>([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const { data: exchangeRatesData } = useQuery(EXCHANGE_RATES_SETTINGS, { fetchPolicy: 'cache-only' });
  const { data: walletsData } = useQuery(WALLETS, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    if (walletsData) {
      const newWallets = pathOr<IWallet[]>([], ['wallets'], walletsData);
      const walletsBalance = getWalletBalance(newWallets);

      setWallets(walletsBalance);
    }
  }, [walletsData]);

  useEffect(() => {
    if (exchangeRatesData) {
      const rates = pathOr<IExchangeRate[]>([], ['exchangeRates'], exchangeRatesData);

      setExchangeRates(rates);
    }
  }, [exchangeRatesData]);

  useEffect(() => {
    if (exchangeRates && wallets.length) {
      const filteredWallets = wallets.filter((w) => w.tokenCode !== TokenCode.STAR);
      const totalAvailable = convertTokensToUSD(filteredWallets, exchangeRates);

      setTotalAmount(totalAvailable);
    }
  }, [exchangeRates, wallets]);

  useEffect(() => {
    const disable = isDisableCollectInCurrenciesButton(wallets);

    setDisableButton(disable);
  }, [wallets]);

  const handleWithdraw = () => {
    showModal({ modal: Modals.withdraw, data: { tokenCode: null } });
  };

  return (
    <CurrencyCard
      title="Wallet"
      totalAmount={totalAmount}
      currencies={wallets}
      buttonTitle="Withdraw"
      disableButton={disableButton}
      onWithdraw={handleWithdraw}
    />
  );
};

export default DashboardOverviewWallet;
