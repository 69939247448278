import ReferralFilter from 'components/common/referralFilter';
import { IDashboardReferralActivityFilter } from './types';

const DashboardReferralActivityFilter: React.FC<IDashboardReferralActivityFilter> = ({
  count,
  active,
  showPerPage,
  onChangePerPage,
  onDatesChange,
}) => {
  return (
    <div className="relative mb-6 flex items-center justify-between max-[900px]:flex-col max-[900px]:items-start">
      <div className="flex items-center text-xl font-bold max-[900px]:mb-4 max-[520px]:text-base">
        <p className="mr-4">
          Total: <span className="text-orange">{count}</span>
        </p>
        <p>
          Active: <span className="text-orange">{active}</span>
        </p>
      </div>
      <ReferralFilter onDatesChange={onDatesChange} onChangePerPage={onChangePerPage} showPerPage={showPerPage} />
    </div>
  );
};

export default DashboardReferralActivityFilter;
