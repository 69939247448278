import { NavLink } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from 'assets/common/arrow.svg';
import { ReactComponent as LogoutIcon } from 'assets/common/logout.svg';

import { USER_MENU } from 'constants/nav';

import { IProfileDropdown } from './types';

const ProfileDropdown: React.FC<IProfileDropdown> = ({ onLogout }) => {
  return (
    <article className="shadow-black absolute right-0 top-[calc(100%+18px)] w-[180px] rounded-[10px] bg-semi-black bg-opacity-90 px-6 py-4">
      <ul className="mb-4">
        {USER_MENU.map((m) => (
          <li key={m.id} className="mb-4 flex items-center last:mb-0 hover:text-orange hover:text-opacity-90">
            <NavLink to={m.link} className={({ isActive }) => `flex items-center ${isActive ? 'text-orange' : ''}`}>
              <ArrowIcon className="mr-2 h-auto w-[22px] rotate-[270deg] fill-[currentColor]" />
              <span className="text-sm font-bold">{m.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="border-t-[1px] border-light-gray pt-4">
        <button
          type="button"
          className="flex items-center last:mb-0 hover:text-orange hover:text-opacity-90"
          onClick={onLogout}
        >
          <LogoutIcon className="mr-4 h-auto w-[15px] fill-[currentColor]" />
          <span className="text-sm font-bold">Log Out</span>
        </button>
      </div>
    </article>
  );
};

export default ProfileDropdown;
