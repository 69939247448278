import { Helmet } from 'react-helmet';

import dashboardIcon from 'assets/nav/dashboard.webp';

import PageTitle from 'components/common/text/title/page';
import DashboardOverview from './components/dashboardOverview';
import DashboardTable from './components/dashboardTable';
import DashboardWalletOverview from './components/dashboardWalletOverview';

const DashboardPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard - StarBets Affiliates</title>
      </Helmet>
      <div className="flex h-full w-full flex-grow flex-col">
        <PageTitle title="Dashboard" icon={dashboardIcon} />
        <DashboardOverview />
        <DashboardWalletOverview />
        <DashboardTable />
      </div>
    </>
  );
};

export default DashboardPage;
