import alien from 'assets/common/alien.gif';

const PageLoader: React.FC = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-dark-blue">
      <img className="h-auto w-[300px]" src={alien} alt="Alien Loader" />
    </div>
  );
};

export default PageLoader;
