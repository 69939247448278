import ReCAPTCHA from 'react-google-recaptcha';

import banner from 'assets/login/banner.webp';

import config from 'constants/config';

import Modal from 'components/common/base/modal';
import PlainButton from 'components/common/buttons/plainButton';

import RegistrationFirstStep from './components/registrationFirstStep';
import RegistrationSecondStep from './components/registrationSecondStep';

import { IRegistration } from './types';

const { captchaKey } = config;

const Registration: React.FC<IRegistration> = ({
  formikFirstStep,
  formikSecondStep,
  step,
  recaptcha,
  loading,
  errorMessage,
  onLogin,
  onSubmit,
  onClose,
}) => {
  return (
    <Modal modalClassName="w-full" withPaddings={false}>
      <div className="flex w-full">
        <img
          className="pointer-events-none h-auto w-full max-w-[45%] rounded-l-[20px] selection:pointer-events-none max-[1100px]:hidden"
          src={banner}
          alt="Banner"
        />
        <div className="relative flex min-h-[657px] flex-grow flex-col items-center px-14 py-10 max-[550px]:px-10 max-[400px]:px-4">
          {step === 1 ? <RegistrationFirstStep formik={formikFirstStep} onSubmit={onSubmit} onClose={onClose} /> : null}
          {step === 2 ? (
            <RegistrationSecondStep
              formik={formikSecondStep}
              loading={loading}
              errorMessage={errorMessage}
              onSubmit={onSubmit}
            />
          ) : null}
          <div className="mt-4 flex items-center text-sm text-light-gray max-[350px]:flex-col">
            <p className="mr-1">Already have an account?</p>
            <PlainButton classList="underline underline-offset-4" type="button" title="Log In" onClick={onLogin} />
          </div>
        </div>
        <ReCAPTCHA ref={recaptcha} size="invisible" sitekey={captchaKey} />
      </div>
    </Modal>
  );
};

export default Registration;
