/* eslint-disable no-control-regex */
import coinIcon from 'assets/currencies/coin.webp';

import { ISelectOption } from 'components/common/select/types';
import { AffiliatePercentage } from 'types';

export const COLLECT_ALL_OPTION: ISelectOption = { label: 'Collect All Currencies', value: null, icon: coinIcon };
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again later';

export const RATES: { id: string; percent: AffiliatePercentage; wager: string }[] = [
  { id: 'rate-10', percent: '10', wager: '1000' },
  { id: 'rate-15', percent: '15', wager: '2500' },
  { id: 'rate-20', percent: '20', wager: '5000' },
  { id: 'rate-25', percent: '25', wager: '7500' },
  { id: 'rate-30', percent: '30', wager: '10000' },
  { id: 'rate-35', percent: '35', wager: '25000' },
  { id: 'rate-40', percent: '40', wager: '50000' },
  { id: 'rate-45', percent: '45', wager: '75000' },
  { id: 'rate-50', percent: '50', wager: '100000' },
];

export const RATES_CLASSES: { [key in AffiliatePercentage]: string } = {
  '10': 'percent10',
  '15': 'percent15',
  '20': 'percent20',
  '25': 'percent25',
  '30': 'percent30',
  '35': 'percent35',
  '40': 'percent40',
  '45': 'percent45',
  '50': 'percent50',
};

export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm;
