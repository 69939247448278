import { twMerge } from 'tailwind-merge';

import { ITextSkeleton } from './types';

const TextSkeleton: React.FC<ITextSkeleton> = ({ className = ' ' }) => {
  return (
    <div className={twMerge('h-[1rem] w-full animate-pulse rounded-[10px] bg-light-gray bg-opacity-40', className)} />
  );
};

export default TextSkeleton;
