/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import { ApolloLink, HttpLink, DefaultContext, Observable, FetchResult } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import config from 'constants/config';
import { GraphqlErrors } from 'constants/auth';
import { getAuthToken } from 'helpers/auth';
import { RefreshTokens } from 'context/sessionManagementContext/types';

const { graphqlUrl } = config;

export const createHTTPLink = () => {
  return new HttpLink({ uri: graphqlUrl });
};

export const createAuthLink = () => {
  return new ApolloLink((operation, forward) => {
    const authToken = getAuthToken();

    operation.setContext(({ headers = {} }: DefaultContext) => ({
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : null,
      },
    }));

    return forward(operation);
  });
};

export const createErrorLink = (refreshTokens: RefreshTokens, logout: VoidFunction) => {
  return onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const unauthenticatedError = graphQLErrors.find((e) => e.message === GraphqlErrors.unauthorized);

      if (unauthenticatedError) {
        const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
          (async () => {
            try {
              await refreshTokens().then((res) => {
                if (!res) return forward(operation);

                const { authToken } = res;

                if (authToken) {
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${authToken}`,
                    },
                  }));

                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  forward(operation).subscribe(subscriber);
                } else {
                  logout();
                }
              });
            } catch (err) {
              observer.error(err);
            }
          })();
        });

        return observable;
      }
    }
  });
};
