/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { twMerge } from 'tailwind-merge';

import premiumAvatarsIcon from 'assets/avatars/premium-avatars.webp';

import { AvatarType } from 'types';
import { getUserAvatar } from 'helpers';

import Modal from 'components/common/base/modal';
import Loader from 'components/common/loaders/loader';
import Button from 'components/common/buttons/button';

import styles from './styles.module.scss';
import { IAvatarModal } from './types';

const AvatarModal: React.FC<IAvatarModal> = ({
  type,
  avatars,
  selectedAvatar,
  premiumAvatarDisabled,
  premiumAvatarRank,
  loading,
  onChangeType,
  onChangeSelectedAvatar,
  onSubmit,
}) => {
  return (
    <Modal title="Choose Your Avatar" titleClassName="text-3xl" modalClassName="max-w-[500px] w-full">
      <div className="relative mx-auto flex w-full flex-col items-center pb-6 max-[400px]:w-4/5">
        {loading ? <Loader /> : null}
        <div className="mb-6 flex w-full items-center justify-center">
          <button
            type="button"
            className={twMerge(
              'mr-2 rounded-[20px] px-4 py-2 font-semibold uppercase outline-none',
              type === AvatarType.default ? 'bgg-purple' : 'bg-dark-blue',
            )}
            onClick={() => onChangeType(AvatarType.default)}
          >
            Default
          </button>
          <button
            type="button"
            className={twMerge(
              'rounded-[20px] px-4 py-2 font-semibold uppercase outline-none',
              type === AvatarType.premium ? 'bgg-purple' : 'bg-dark-blue',
            )}
            onClick={() => onChangeType(AvatarType.premium)}
          >
            Premium
          </button>
        </div>
        <div
          className={twMerge(
            'relative mb-6 grid h-[350px] w-full grid-cols-4 overflow-hidden overflow-y-auto max-[550px]:grid-cols-3 max-[400px]:grid-cols-2',
            type === AvatarType.premium && premiumAvatarDisabled ? 'pointer-events-none' : '',
          )}
        >
          {avatars.map((a) => (
            <div
              key={a.id}
              className={twMerge(
                'h-full w-full',
                selectedAvatar === a.id ? 'border-4 border-purple' : 'cursor-pointer',
                type === AvatarType.premium && premiumAvatarDisabled ? 'pointer-events-none' : '',
              )}
              onClick={() => onChangeSelectedAvatar(a.id!)}
            >
              <img
                className="pointer-events-none h-full w-full selection:pointer-events-none"
                src={getUserAvatar(a.avatar)}
                alt={a.avatar}
              />
            </div>
          ))}
          {type === AvatarType.premium && premiumAvatarDisabled ? (
            <div className="absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center bg-dark-blue/80">
              <p className={twMerge(styles.textGold, 'text-center text-2xl font-semibold')}>Premium</p>
              <p className={twMerge(styles.textGold, 'mb-4 text-center text-2xl font-semibold')}>Avatars Available</p>
              <img className="mb-4 h-[140px] w-auto" src={premiumAvatarsIcon} alt="Premium Avatars" />
              <p className="text-lg font-semibold">from Rank {premiumAvatarRank}</p>
            </div>
          ) : null}
        </div>
        <Button
          disabled={type === AvatarType.premium && premiumAvatarDisabled}
          type="button"
          variant="main"
          title="Continue"
          onClick={onSubmit}
        />
      </div>
    </Modal>
  );
};

export default AvatarModal;
