import { SIDEBAR_MENU } from 'constants/nav';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  return (
    <aside className="sticky top-[68px] h-[calc(100vh-68px)] w-full max-w-[100px] flex-col items-center bg-semi-black py-6 max-[1023px]:hidden">
      <ul className="flex w-full flex-col items-center">
        {SIDEBAR_MENU.map((s) => (
          <li key={s.id} className="mb-6 last:mb-0 hover:opacity-80">
            <Link to={s.link} className="flex flex-col items-center">
              <img
                className="pointer-events-none mb-2 h-auto w-[28px] selection:pointer-events-none"
                src={s.icon}
                alt={s.title}
              />
              <p className="text-xs font-bold">{s.title}</p>
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
