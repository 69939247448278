/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { twMerge } from 'tailwind-merge';

import { IToggle } from './types';
import styles from './styles.module.scss';

const Toggle: React.FC<IToggle> = ({ label = '', className = '', ...props }) => {
  return (
    <div className="flex items-center">
      <label className={twMerge('relative inline-block h-[20px] w-[40px]', styles.wrapper, className)}>
        <input className="visually-hidden group" type="checkbox" {...props} />
        <span
          className={twMerge(
            'absolute bottom-0 left-0 right-0 top-0 cursor-pointer overflow-hidden rounded-[30px] border-[4px] border-transparent duration-200',
            styles.slider,
          )}
        />
        <span className="absolute -right-[calc(100%+1rem)] w-full cursor-pointer whitespace-nowrap text-sm font-bold selection:pointer-events-none">
          {label}
        </span>
      </label>
    </div>
  );
};

export default Toggle;
