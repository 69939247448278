import { twMerge } from 'tailwind-merge';

// import { ReactComponent as InfoIcon } from 'assets/common/info.svg';

import { RATES_CLASSES } from 'constants/index';
// import { getFormattedAmount } from 'helpers';

import Box from 'components/common/content/box';
import BlockSkeleton from 'components/common/skeleton/blockSkeleton';
// import TextSkeleton from 'components/common/skeleton/textSkeleton';
// import Tooltip from 'components/common/text/tooltip';

import { IDashboardOverviewRate } from './types';

const DashboardOverviewRate: React.FC<IDashboardOverviewRate> = ({ percentage, loading }) => {
  return (
    <Box
      component="li"
      className="flex h-[118px] w-full max-w-[25%] flex-col max-[1280px]:max-w-[48.5%] max-[720px]:max-w-full"
    >
      <div className="mb-5 flex items-center justify-between">
        <h5 className="font-medium">Commission Rate:</h5>
        {/* <Tooltip text="tooltip placeholder test test test">
          <InfoIcon className="h-auto w-[18px] hover:opacity-80" />
        </Tooltip> */}
      </div>
      <div className="mb-3 mt-auto flex items-center justify-center font-medium">
        <div className="mr-4">
          {loading ? (
            <BlockSkeleton width={83} height={37} />
          ) : (
            <span
              className={twMerge(
                'pointer-events-none rounded-[4px] px-4 py-1 text-2xl font-bold selection:pointer-events-none',
                RATES_CLASSES[percentage],
              )}
            >
              {percentage}%
            </span>
          )}
        </div>
        {/* <div className="flex-grow">
          {loading ? (
            <TextSkeleton className="mb-1" />
          ) : (
            <p className="text-sm font-semibold">{getFormattedAmount(wager)}</p>
          )}
          {loading ? <TextSkeleton /> : <p className="text-xs text-light-blue">{month} wager</p>}
        </div> */}
      </div>
    </Box>
  );
};

export default DashboardOverviewRate;
