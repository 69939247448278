import { ACCOUNT_TITLES, AccountTitle } from 'constants/account';

import TableTitles from 'components/common/table/tableTitles';

import AccountActivityTable from './components/accountActivity';

import { IAccountTable } from './types';

const AccountTable: React.FC<IAccountTable> = ({ title, onChangeTitle }) => {
  return (
    <section className="mb-4 flex w-full flex-col">
      <TableTitles activeTitle={title} titles={ACCOUNT_TITLES} onChangeTitle={onChangeTitle} />
      {title === AccountTitle.loginActivity ? <AccountActivityTable /> : null}
    </section>
  );
};

export default AccountTable;
