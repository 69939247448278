/* eslint-disable react/destructuring-assignment */
import { components } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { ISelectNoOption } from './types';

const { NoOptionsMessage } = components;

const SelectNoOption: React.FC<ISelectNoOption> = ({ message, children, ...props }) => {
  return (
    <NoOptionsMessage {...props} className={twMerge(props.className)}>
      {message || children}
    </NoOptionsMessage>
  );
};

export default SelectNoOption;
