/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import sanitize from 'sanitize-html';

import { ReactComponent as ArrowIcon } from 'assets/common/arrow.svg';

import { IFAQArticle } from './types';

const FAQArticle: React.FC<IFAQArticle> = ({ open, article, onOpen }) => {
  return (
    <li
      className="animate__animated animate__fadeInDown cursor-pointer border-b-[1px] border-light-gray py-6 selection:pointer-events-none first:pt-0 last:border-b-0 last:pb-0"
      onClick={onOpen}
    >
      <div className={clsx('flex items-center', open ? 'mb-4' : '')}>
        <h6 className="w-4/5 font-bold">{article.title}</h6>
        <ArrowIcon className={clsx('animation ml-auto h-auto !w-[24px] fill-white', open ? 'rotate-180' : '')} />
      </div>
      <div className="w-full overflow-hidden">
        <div
          className={clsx(
            'animate__animated animate__fadeInDown overflow-hidden text-sm text-light-blue',
            open ? 'flex flex-col' : 'hidden',
          )}
          dangerouslySetInnerHTML={{ __html: sanitize(article.body) }}
        />
      </div>
    </li>
  );
};

export default FAQArticle;
