import { ISelectOption } from 'components/common/select/types';
import { IHeadCell } from 'components/common/table/tableHeader/types';

export const ITEMS_PER_PAGE = 10;
export const ITEMS_PER_PAGE_OPTION_TENS: ISelectOption[] = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
];
export const ITEMS_PER_PAGE_OPTION: ISelectOption[] = [
  { label: '10', value: '10' },
  { label: '15', value: '15' },
  { label: '20', value: '20' },
  { label: '25', value: '25' },
  { label: '30', value: '30' },
];

export const TRANSACTION_TABLE_HEAD: IHeadCell[] = [
  { id: 'transaction-head-date', title: 'Date', colSize: 1, className: 'max-[410px]:hidden' },
  { id: 'transaction-head-type', title: 'Type', className: 'max-[410px]:rounded-l-[14px]', center: true, colSize: 1 },
  {
    id: 'transaction-head-amount',
    title: 'Amount',
    className: 'max-[770px]:rounded-r-[14px] max-[770px]:text-center',
    colSize: 2,
  },
  {
    id: 'transaction-head-status',
    title: 'Status',
    center: true,
    className: 'max-[910px]:rounded-r-[14px] max-[770px]:hidden',
    colSize: 1,
  },
  { id: 'transaction-head-info', title: 'Info', className: 'max-[910px]:hidden', colSize: 1 },
];

export const DASHBOARD_TABLE_HEAD: IHeadCell[] = [
  { id: 'dashboard-head-alias', title: 'Alias', colSize: 1, className: 'max-[520px]:text-xs' },
  { id: 'dashboard-head-date', title: 'Join Date', colSize: 1, className: 'max-[520px]:text-xs' },
  { id: 'dashboard-head-wager', title: 'Total Wager', colSize: 1, className: 'max-[520px]:text-xs' },
];

export const CAMPAIGNS_TABLE_HEAD: IHeadCell[] = [
  { id: 'campaigns-head-name', title: 'Name', colSize: 1 },
  { id: 'campaigns-head-url', title: 'URL', colSize: 2 },
  { id: 'campaigns-head-date', title: 'Created Date', colSize: 1, className: 'max-[920px]:hidden' },
  {
    id: 'campaigns-head-users',
    title: (
      <>
        <span className="max-[780px]:hidden">Registered </span>Users
      </>
    ),
    colSize: 1,
    className: 'max-[500px]:hidden',
  },
];

export const CAMPAIGN_REFERRALS_TABLE_HEAD: IHeadCell[] = [
  { id: 'dashboard-head-alias', title: 'Alias', colSize: 1, className: 'max-[520px]:text-xs' },
  { id: 'dashboard-head-date', title: 'Join Date', colSize: 1, className: 'max-[520px]:text-xs' },
  { id: 'dashboard-head-rewards', title: 'Total Rewards', colSize: 1, className: 'max-[520px]:text-xs' },
];

export const LOGIN_ACTVITY_TABLE_HEAD: IHeadCell[] = [
  { id: 'activity-head-date', title: 'Date', colSize: 2 },
  { id: 'activity-head-activity', title: 'Activity', colSize: 2 },
  { id: 'activity-head-device', title: 'Device', colSize: 2, className: 'max-[600px]:hidden' },
  { id: 'activity-head-ip', title: 'IP Address', colSize: 2, className: 'max-[480px]:hidden' },
  { id: 'activity-head-status', title: 'Status', colSize: 1, center: true },
];
