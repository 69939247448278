import { useRef, useState, useEffect } from 'react';

import useOnScreen from 'hooks/useOnScreen';

import FeaturesList from './featureList';

const FeaturesListContainer: React.FC = () => {
  const listRef = useRef<HTMLUListElement>(null);
  const isVisible = useOnScreen(listRef);

  const [showItems, setShowItems] = useState(false);

  useEffect(() => {
    if (isVisible && !showItems) {
      setShowItems(true);
    }
  }, [isVisible]);

  return <FeaturesList listRef={listRef} showItems={showItems} />;
};

export default FeaturesListContainer;
