import { gql } from '@apollo/client';

export const TESTIMONIALS = gql`
  query testimonials {
    testimonials {
      id
      logo
      website
      testimonial
    }
  }
`;
