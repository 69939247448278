import clsx from 'clsx';

import { ReactComponent as CheckIcon } from 'assets/common/check.svg';
import { ReactComponent as PendingIcon } from 'assets/common/pending.svg';
import { ReactComponent as CrossIcon } from 'assets/common/cross-bold.svg';

import { IAffiliateReferralUser, IUserActivity } from 'requestTypes';
import { ActivityStatus, IAffiliateCampaign, ITransaction, TransactionStatus } from 'types';
import { IRow, ITableRow } from 'components/common/table/tableRow/types';
import { getCurrencyIcon, getFormattedAmount, getFormattedDate } from 'helpers';
import { StarbetsLinks } from 'constants/config';

const getTransactionIcon = (status: TransactionStatus) => {
  if (status.toLowerCase() === TransactionStatus.complete.toLowerCase()) {
    return { icon: CheckIcon, color: 'text-green' };
  }

  if (status.toLowerCase() === TransactionStatus.pending.toLowerCase()) {
    return { icon: PendingIcon, color: 'text-light-gray' };
  }

  return { icon: CrossIcon, color: 'text-red' };
};

const getTransactionRow = (transaction: ITransaction): IRow[] => {
  const externalTransaction = transaction.network && transaction.info?.toLowerCase() !== 'internal transaction';
  const iconData = getTransactionIcon(transaction.status);

  const date: IRow = {
    id: `${transaction.id}-date`,
    content: getFormattedDate(transaction.createdAt),
    colSize: 1,
    tdClassName: 'max-[410px]:hidden',
  };

  const type: IRow = {
    id: `${transaction.id}-type`,
    content: transaction.type,
    colSize: 1,
    wrapperClassName: 'justify-center w-full',
    tdClassName: 'max-[410px]:rounded-l-[14px]',
  };

  const amount: IRow = {
    id: `${transaction.id}-amount`,
    content: `${getFormattedAmount(transaction.amount)} ${transaction.token.displayName}`,
    colSize: 2,
    amountIcon: getCurrencyIcon(transaction.token.displayName),
    amountTokenCode: transaction.token.displayName,
    iconAfter: iconData.icon,
    iconAfterClassName: clsx('hidden ml-auto max-[770px]:flex', iconData.color),
    wrapperClassName: 'max-[770px]:w-full',
    tdClassName: 'max-[770px]:rounded-r-[14px]',
  };

  const status: IRow = {
    id: `${transaction.id}-status`,
    content: transaction.status,
    colSize: 1,
    tdClassName: clsx(iconData.color, 'max-[910px]:rounded-r-[14px] max-[770px]:hidden'),
    icon: iconData.icon,
    textClassName: 'max-[920px]:hidden',
    wrapperClassName: 'justify-center w-full',
  };

  const info: IRow = {
    id: `${transaction.id}-info`,
    content: transaction.info,
    colSize: 1,
    textClassName: externalTransaction
      ? 'truncate max-w-[180px] underline underline-offset-2 cursor-pointer animation hover:opacity-70'
      : '',
    onClick: externalTransaction
      ? () => window.open(`${transaction.network?.explorer}${transaction.info}`, '_blank')
      : undefined,
    onCopy: externalTransaction ? () => navigator.clipboard.writeText(transaction.info) : undefined,
    tdClassName: 'max-[910px]:hidden',
  };

  return [date, type, amount, status, info];
};

export const getTransactionsRows = (transactions: ITransaction[]): ITableRow[] => {
  const tableRows = transactions.map<ITableRow>((t) => ({
    id: t.id,
    rows: getTransactionRow(t),
  }));

  return tableRows;
};

const getReferralActivityRow = (referral: IAffiliateReferralUser) => {
  const alias: IRow = {
    id: `${referral.alias}-alias`,
    content: referral.alias,
    colSize: 1,
  };

  const date: IRow = {
    id: `${referral.alias}-date`,
    content: getFormattedDate(referral.createdAt),
    colSize: 1,
  };

  const reward: IRow = {
    id: `${referral.alias}-reward`,
    content: `${getFormattedAmount(referral?.totalWager || '0')} BTC`,
    colSize: 1,
    amountIcon: getCurrencyIcon('BTC'),
    amountTokenCode: 'BTC',
    amountIconClassName: 'max-[440px]:hidden',
  };

  return [alias, date, reward];
};

export const getAffiliateReferralsRows = (referrals: IAffiliateReferralUser[]): ITableRow[] => {
  const tableRows = referrals.map<ITableRow>((r) => ({
    id: r.alias,
    rows: getReferralActivityRow(r),
  }));

  return tableRows;
};

const getAffiliateCampaignRow = (campaign: IAffiliateCampaign, onChange: (id: string, name: string) => void) => {
  const link = `${StarbetsLinks.affiliate}${campaign.code}`;

  const name: IRow = {
    id: `${campaign.id}-name`,
    content: campaign.name,
    colSize: 1,
  };

  const url: IRow = {
    id: `${campaign.id}-url`,
    content: (
      <>
        <span className="max-[600px]:hidden">{link}</span>
        <span className="hidden max-[600px]:inline-block">{campaign.code}</span>
      </>
    ),
    colSize: 2,
    wrapperClassName: 'w-full',
    textClassName: 'truncate underline underline-offset-2 cursor-pointer animation hover:opacity-70',
    changeTooltip: 'Edit Campaign',
    changeClassName: 'hidden max-[500px]:flex',
    onCopy: () => navigator.clipboard.writeText(link),
    onClick: () => window.open(link, '_blank'),
    onChange: () => onChange(campaign.id, campaign.name),
  };

  const date: IRow = {
    id: `${campaign.id}-date`,
    content: getFormattedDate(campaign.createdAt),
    colSize: 1,
    tdClassName: 'max-[920px]:hidden',
  };

  const users: IRow = {
    id: `${campaign.id}-users`,
    content: campaign.registrations,
    colSize: 1,
    tdClassName: 'max-[500px]:hidden',
    wrapperClassName: 'w-full',
    changeTooltip: 'Edit Campaign',
    onChange: () => onChange(campaign.id, campaign.name),
  };

  return [name, url, date, users];
};

export const getAffiliateCampaignsRows = (
  campaigns: IAffiliateCampaign[],
  onChange: (id: string, name: string) => void,
): ITableRow[] => {
  const tableRows = campaigns.map<ITableRow>((c) => ({
    id: c.id,
    rows: getAffiliateCampaignRow(c, onChange),
  }));

  return tableRows;
};

const getActivityIcon = (status: ActivityStatus) => {
  if (status.toLowerCase() === ActivityStatus.completed.toLocaleLowerCase()) {
    return { icon: CheckIcon, color: 'text-green' };
  }

  return { icon: CrossIcon, color: 'text-red' };
};

const getLoginActvityRow = (activity: IUserActivity) => {
  const iconData = getActivityIcon(activity.status);

  const date: IRow = {
    id: `${activity.createdAt}-date`,
    content: getFormattedDate(activity.createdAt),
    colSize: 2,
  };

  const userActivity: IRow = {
    id: `${activity.createdAt}-activity`,
    content: activity.activity,
    colSize: 2,
  };

  const device: IRow = {
    id: `${activity.createdAt}-device`,
    content: activity.device,
    colSize: 2,
    tdClassName: 'max-[600px]:hidden',
  };

  const ipAddress: IRow = {
    id: `${activity.createdAt}-ipAddress`,
    content: activity.ipAddress,
    colSize: 2,
    tdClassName: 'max-[480px]:hidden',
  };

  const status: IRow = {
    id: `${activity.createdAt}-status`,
    content: '',
    tdClassName: iconData.color,
    icon: iconData.icon,
    colSize: 1,
    wrapperClassName: 'justify-center w-full',
  };

  return [date, userActivity, device, ipAddress, status];
};

export const getLoginActvityRows = (activity: IUserActivity[]): ITableRow[] => {
  const tableRows = activity.map<ITableRow>((a) => ({
    id: a.createdAt,
    rows: getLoginActvityRow(a),
  }));

  return tableRows;
};
