import { IBalance } from 'helpers/currencies';

export enum Modals {
  login = 'login',
  mfa = 'mfa',
  registration = 'registration',
  forgotPassword = 'forgotPassword',
  authenticator = 'authenticator',
  success = 'success',
  forbidden = 'forbidden',
  withdraw = 'withdraw',
  verifyEmail = 'verifyEmail',
  unavailable = 'unavailable',
  blockedIp = 'blockedIp',
  collect = 'collect',
  createCampaign = 'createCampaign',
  updateCampaign = 'updateCampaign',
  avatar = 'avatar',
  changePassword = 'changePassword',
}

export interface IMFAData {
  mfaType: 'google' | 'email';
  sendEmail?: boolean;
  action?: (code: string, onError: (err: string) => void) => Promise<void>;
}

export interface IAuthenticatorData {
  action?: (code: string, onError: (err: string) => void) => Promise<void>;
}

interface ILoginModal {
  modal: Modals.login;
  data?: undefined;
}

export interface IVerifyEmailData {
  email: string;
  password: string;
}

export interface IWithdrawData {
  tokenCode: string | null;
}

export interface IUpdateCampaignData {
  id: string;
  name: string;
}

export interface ISuccessModalData {
  message: string;
  onClose?: VoidFunction;
}

export interface IChangePasswordData {
  errorMessage?: string;
}

interface ICollectData {
  currencies: IBalance[];
}

interface IMFAModal {
  modal: Modals.mfa;
  data: IMFAData;
}

interface IRegistrationModal {
  modal: Modals.registration;
  data?: undefined;
}

interface IForgotPasswordModal {
  modal: Modals.forgotPassword;
  data?: undefined;
}

interface IAuthenticatorModal {
  modal: Modals.authenticator;
  data: IAuthenticatorData;
}

interface ISuccessModal {
  modal: Modals.success;
  data: ISuccessModalData;
}

interface HideModal {
  modal: undefined;
  data?: undefined;
}

interface IForbiddenModal {
  modal: Modals.forbidden;
  data: string;
}

interface IVerifyEmailModal {
  modal: Modals.verifyEmail;
  data: IVerifyEmailData;
}

interface IUnavailableModal {
  modal: Modals.unavailable;
  data?: undefined;
}

interface IBlockedIpModal {
  modal: Modals.blockedIp;
  data?: undefined;
}

interface IWithdrawModal {
  modal: Modals.withdraw;
  data: IWithdrawData;
}

interface ICollectModal {
  modal: Modals.collect;
  data: ICollectData;
}

interface ICreateCampaignModal {
  modal: Modals.createCampaign;
  data?: undefined;
}

interface IUpdateCampaignModal {
  modal: Modals.updateCampaign;
  data: IUpdateCampaignData;
}

interface IAvatarModal {
  modal: Modals.avatar;
  data?: undefined;
}

interface IChangePasswordModal {
  modal: Modals.changePassword;
  data?: IChangePasswordData;
}

export type TModalSetup =
  | HideModal
  | ILoginModal
  | IMFAModal
  | IRegistrationModal
  | IForgotPasswordModal
  | IAuthenticatorModal
  | ISuccessModal
  | IForbiddenModal
  | IWithdrawModal
  | IVerifyEmailModal
  | IUnavailableModal
  | ICollectModal
  | ICreateCampaignModal
  | IUpdateCampaignModal
  | IBlockedIpModal
  | IAvatarModal
  | IChangePasswordModal;
export type TModalData = TModalSetup['data'];

export interface IModalsContext {
  modal?: Modals;
  data?: TModalData;
  showModal: (data: TModalSetup) => void;
}
