import monsterIcon from 'assets/withdraw/monster.webp';

import Modal from 'components/common/base/modal';
import Loader from 'components/common/loaders/loader';
import WithdrawFirstStep from './components/withdrawFirstStep';
import WithdrawSecondStep from './components/withdrawSecondStep';

import { IWithdrawModal } from './types';

const WithdrawModal: React.FC<IWithdrawModal> = ({
  withdrawalEnabled,
  step,
  formik,
  walletsOptions,
  networksOptions,
  wallet,
  network,
  withMemo,
  loading,
  onChangeAmount,
  onMaxAmount,
  onChangeWithMFA,
  onWithdraw,
}) => {
  return (
    <Modal title="Withdraw" modalClassName="max-w-[500px] w-full">
      {withdrawalEnabled ? (
        <div className="relative mx-auto flex w-full flex-col items-center py-6">
          {loading ? <Loader /> : null}
          {step === 1 ? (
            <WithdrawFirstStep
              formik={formik}
              walletsOptions={walletsOptions}
              networksOptions={networksOptions}
              wallet={wallet}
              network={network}
              withMemo={withMemo}
              onChangeAmount={onChangeAmount}
              onMaxAmount={onMaxAmount}
              onChangeWithMFA={onChangeWithMFA}
            />
          ) : null}
          {step === 2 ? <WithdrawSecondStep formik={formik} network={network} onWithdraw={onWithdraw} /> : null}
        </div>
      ) : (
        <div className="flex h-[350px] flex-col items-center justify-center">
          <p className="text-center font-bold">Sorry, withdrawals are temporarily unavailable for maintenance.</p>
          <img className="h-auto w-[250px]" src={monsterIcon} alt="Monster Icon" />
        </div>
      )}
    </Modal>
  );
};

export default WithdrawModal;
