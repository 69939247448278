import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import UserContext from 'context/userContext/context';
import { AffiliatePercentage } from 'types';

import DashboardOverviewRate from './dashboardOverviewRate';

const DashboardOverviewRateContainer: React.FC = () => {
  const { profile, loading } = useContext(UserContext);

  const [percentage, setPercentage] = useState<AffiliatePercentage>('10');
  const [month, setMonth] = useState('');
  const [wager, setWager] = useState('');

  useEffect(() => {
    const newPercentage = pathOr<AffiliatePercentage>('10', ['percentage'], profile);
    const usdWager = pathOr('0', ['totalWager'], profile);
    const newMonth = dayjs().subtract(1, 'month').format('MMMM').toLowerCase();

    setPercentage(newPercentage);
    setWager(usdWager);
    setMonth(newMonth);
  }, [profile]);

  return <DashboardOverviewRate percentage={percentage} month={month} wager={wager} loading={loading} />;
};

export default DashboardOverviewRateContainer;
