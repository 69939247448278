import { Links } from 'constants/nav';

import Input from 'components/common/inputs/input';
import Button from 'components/common/buttons/button';
import Checkbox from 'components/common/inputs/checkbox';

import { IRegistrationFirstStep } from './types';

const RegistrationFirstStep: React.FC<IRegistrationFirstStep> = ({ formik, onSubmit, onClose }) => {
  return (
    <>
      <h3 className="mb-2 text-3xl font-bold uppercase">Join Now</h3>
      <p className="mb-4 text-sm font-semibold text-light-blue">Step 1/2</p>
      <form className="flex w-full flex-grow flex-col" onSubmit={onSubmit}>
        <Input
          formik={formik}
          autoComplete="username"
          name="email"
          type="email"
          placeholder="Email"
          label="Email:"
          labelAlign="center"
          value={formik.values.email}
          errorMessage={formik.errors?.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <Input
          formik={formik}
          autoComplete="new-password"
          name="password"
          type="password"
          placeholder="Password"
          label="Password:"
          labelAlign="center"
          value={formik.values.password}
          errorMessage={formik.errors?.password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <Input
          formik={formik}
          autoComplete="new-password"
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          lableWrapperClassList="mb-4"
          label="Confirm Password:"
          labelAlign="center"
          value={formik.values.confirmPassword}
          errorMessage={formik.errors?.confirmPassword}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <Checkbox
          formik={formik}
          name="isAdult"
          lableWrapperClassList="mb-4"
          label={
            <>
              I agree that I am 18 years or older and agree to the{' '}
              <a
                href={Links.tos}
                className="text-yellow underline underline-offset-4 hover:text-opacity-70"
                target="_blank"
                rel="noreferrer"
                onClick={onClose}
              >
                Terms of Service
              </a>
            </>
          }
          checked={formik.values.isAdult}
          errorMessage={formik.errors?.isAdult}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <Button
          title="Continue"
          type="submit"
          wrapperClassList="mt-auto w-full"
          buttonsClassList="w-full"
          variant="secondary"
        />
      </form>
    </>
  );
};

export default RegistrationFirstStep;
