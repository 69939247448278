import axios from 'axios';

import config, { ZendeskEndpoints } from 'constants/config';
import { IZendeskArticlesResponse, IZendeskSectionsResponse } from 'requestTypes';
import { ZendeskSettings, zEType } from 'context/contextZendesk/generalType';

const { zendeskHCUrl } = config;

const getSections = async () => {
  return axios
    .get<IZendeskSectionsResponse>(`${zendeskHCUrl}${ZendeskEndpoints.sections}`)
    .then((res) => res.data.sections)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('[ZENDESK_SECTIONS_ERROR]:', err);

      return null;
    });
};

const getArticles = async () => {
  return axios
    .get<IZendeskArticlesResponse>(`${zendeskHCUrl}${ZendeskEndpoints.articles}`)
    .then((res) => res.data.articles)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('[ZENDESK_ARTICLES_ERROR]:', err);

      return null;
    });
};

export const getZendeskArticles = async (sectionName: string) => {
  const sections = await getSections();

  if (sections) {
    const currentSection = sections.find((s) => s.name?.toLowerCase() === sectionName?.toLowerCase());

    if (currentSection) {
      const articles = await getArticles();

      return articles ? articles.filter((a) => !a.draft && a.section_id === currentSection.id) : [];
    }
  }

  return [];
};

export const updateZendeskScript = (jwtToken: string): void => {
  window.zESettings = {
    webWidget: {
      authenticate: {
        chat: {
          jwtFn: (callback) => callback(jwtToken),
        },
      },
      answerBot: {
        suppress: false,
        title: {
          '*': 'Live Chat',
        },
        contactOnlyAfterQuery: true,
        search: {
          labels: false,
        },
        avatar: {
          url: 'https://theme.zdassets.com/theme_assets/15266025/c08b19ba5061923217980aff47cf9e68173f2d15.png',
          name: {
            '*': 'StarBets',
          },
        },
      },
    },
  };
};

declare global {
  interface Window {
    zESettings?: ZendeskSettings;
    zE?: zEType;
  }
}
