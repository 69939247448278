import React from 'react';

import { IUserContext } from './types';

const UserContext = React.createContext<IUserContext>({
  profile: null,
  settings: [],
  tokensWithNetwork: [],
  loading: false,
});

export default UserContext;
