import React from 'react';

import { IModalsContext } from './types';

const ModalsContext = React.createContext<IModalsContext>({
  modal: undefined,
  showModal: () => null,
});

export default ModalsContext;
