import clsx from 'clsx';

import { ITableHeader } from './types';

const TableHeader: React.FC<ITableHeader> = ({ headCells }) => {
  return (
    <thead className="sticky top-0 z-10">
      <tr>
        {headCells.map((h) => {
          const { id, title, colSize = 0, center = false, className = '' } = h;

          return (
            <th
              key={id}
              colSpan={colSize}
              className={clsx(
                'pointer-events-none bg-[#28395d] py-4 text-left text-sm text-light-blue selection:pointer-events-none first:rounded-l-[14px] first:pl-6 last:rounded-r-[14px] last:pr-6',
                center ? 'text-center' : '',
                className,
              )}
            >
              {title}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
