import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

import { RefreshTokens } from 'context/sessionManagementContext/types';

import { createAuthLink, createHTTPLink, createErrorLink } from './service';

async function createClient(refreshTokens: RefreshTokens, logout: VoidFunction) {
  const authLink = createAuthLink();
  const errorLink = createErrorLink(refreshTokens, logout);
  const httpLink = createHTTPLink();

  const cache = new InMemoryCache();

  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache,
    name: 'Affiliates App',
    version: '0.0.1',
  });
}

export default createClient;
