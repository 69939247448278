import ReactDOM from 'react-dom/client';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/index.scss';
import 'animate.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from 'containers/app';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
