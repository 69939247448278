import { twMerge } from 'tailwind-merge';

import { IImageSkeleton } from './types';

const ImageSkeleton: React.FC<IImageSkeleton> = ({ width, className = '' }) => {
  return (
    <div
      style={{ width, height: width }}
      className={twMerge('h-[42px] w-[42px] animate-pulse rounded-full bg-light-gray bg-opacity-40', className)}
    />
  );
};

export default ImageSkeleton;
