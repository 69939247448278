import { gql } from '@apollo/client';

export const SETTINGS = gql`
  query getSettings {
    getSettings {
      name
      value
    }
  }
`;
