import { useContext, useEffect, useState } from 'react';

import ModalsContext from 'context/modalsContext/context';
import { IBalance } from 'helpers/currencies';

import Collect from './collect';
import { ICollectContainer } from './types';

const CollectContainer: React.FC = () => {
  const { data } = useContext(ModalsContext);
  const { showModal } = useContext(ModalsContext);

  const [currencies, setCurrencies] = useState<IBalance[]>([]);

  useEffect(() => {
    const { currencies: dataCurrencies } = data as ICollectContainer;

    setCurrencies(dataCurrencies);
  }, [data]);

  const handleClose = () => showModal({ modal: undefined });

  return <Collect onClick={handleClose} currencies={currencies} />;
};

export default CollectContainer;
