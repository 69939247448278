import * as yup from 'yup';

import { AuthErrors } from './auth';

export const loginValidationSchema = yup.object({
  email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: yup.string().max(255).required('Password is required'),
});

export const registrationFirstStepValidationSchema = yup.object({
  email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: yup
    .string()
    .test('contain-uppercase', AuthErrors.uppercaseLetter, (pass = '') => {
      const UPPERCASE_REGEX = /[A-Z]/gm;
      const hasUpperCaseLetter = UPPERCASE_REGEX.test(pass);

      return hasUpperCaseLetter;
    })
    .test('contain-lowercase', AuthErrors.lowercaseLetter, (pass = '') => {
      const LOWERCASE_REGEX = /[a-z]/gm;
      const hasLowerCaseLetter = LOWERCASE_REGEX.test(pass);

      return hasLowerCaseLetter;
    })
    .test('contain-number', AuthErrors.number, (pass = '') => {
      const NUMBER_REGEX = /[0-9]/gm;
      const hasNumber = NUMBER_REGEX.test(pass);

      return hasNumber;
    })
    .test('min-length', AuthErrors.passLength, (pass = '') => {
      return pass.length >= 8;
    })
    .test('only-default-sybmols', AuthErrors.forbiddenCharacters, (pass = '') => {
      const ONLY_DEFAULT_SYMBOLS_REGEX = /^[a-zA-Z0-9*.!@$%^`'&(){}[\]:;<>,.?\\/~_+-=|]+$/gm;
      const hasOnlyDefaultSybmols = ONLY_DEFAULT_SYMBOLS_REGEX.test(pass);

      return hasOnlyDefaultSybmols;
    })
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], AuthErrors.passwordDoesNotMatch)
    .required('Confirm Password is required'),
  isAdult: yup.boolean().oneOf([true], AuthErrors.notAdult),
});

const WebsiteRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const registrationSecondStepValidationSchema = yup.object({
  name: yup.string().max(32).required('Name is required'),
  type: yup.string().required('Type is required'),
  website: yup.string().matches(WebsiteRegex, 'Please enter valid website').required('URL is required'),
  contact: yup
    .string()
    .min(3, 'Username must be at least 3 characters')
    .max(32, 'Username must be at most 32 characters')
    .required('Username is required'),
});

export const withdrawValidationSchema = yup.object({
  currency: yup.string().required('Currency is required'),
  network: yup.string().required('Network is required'),
  amount: yup.string().required('Amount is required'),
  memo: yup.number(),
});

export const changePasswordValidationSchema = yup.object({
  currentPassword: yup.string().required('Password is required'),
  newPassword: yup
    .string()
    .test('contain-uppercase', AuthErrors.uppercaseLetter, (pass = '') => {
      const UPPERCASE_REGEX = /[A-Z]/gm;
      const hasUpperCaseLetter = UPPERCASE_REGEX.test(pass);

      return hasUpperCaseLetter;
    })
    .test('contain-lowercase', AuthErrors.lowercaseLetter, (pass = '') => {
      const LOWERCASE_REGEX = /[a-z]/gm;
      const hasLowerCaseLetter = LOWERCASE_REGEX.test(pass);

      return hasLowerCaseLetter;
    })
    .test('contain-number', AuthErrors.number, (pass = '') => {
      const NUMBER_REGEX = /[0-9]/gm;
      const hasNumber = NUMBER_REGEX.test(pass);

      return hasNumber;
    })
    .test('min-length', AuthErrors.passLength, (pass = '') => {
      return pass.length >= 8;
    })
    .test('only-default-sybmols', AuthErrors.forbiddenCharacters, (pass = '') => {
      const ONLY_DEFAULT_SYMBOLS_REGEX = /^[a-zA-Z0-9*.!@$%^`'&(){}[\]:;<>,.?\\/~_+-=|]+$/gm;
      const hasOnlyDefaultSybmols = ONLY_DEFAULT_SYMBOLS_REGEX.test(pass);

      return hasOnlyDefaultSybmols;
    })
    .required('New Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], AuthErrors.passwordDoesNotMatch)
    .required('Confirm New Password is required'),
});

export const aliasValidationSchema = yup.object({
  alias: yup.string().min(2),
});
