import { useState } from 'react';
import FAQArticle from './faqArticle';

import { IFAQArticleContainer } from './types';

const FAQArticleContainer: React.FC<IFAQArticleContainer> = ({ article }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((o) => !o);
  };

  return <FAQArticle open={open} article={article} onOpen={handleOpen} />;
};

export default FAQArticleContainer;
