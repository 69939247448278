import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { IToken } from 'types';
import useMediaQuery from 'hooks/useMediaQuery';
import { TOKENS_LIST } from 'graphql/token';
import { getDefaultCurrenciesList, IBalance } from 'helpers/currencies';

import CurrencyCard from './currencyCard';
import { ICurrencyCardContainer } from './types';

const CurrencyCardContainer: React.FC<ICurrencyCardContainer> = (props) => {
  const { withStarToken = false } = props;

  const isMobileView = useMediaQuery('(max-width: 590px)');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mode, setMode] = useState<'total' | 'default'>('default');
  const [title, setTitle] = useState('Total Collected');
  const [defaultBalances, setDefaultBalances] = useState<IBalance[]>([]);

  const { data: tokens } = useQuery(TOKENS_LIST, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (tokens) {
      const tokensWithNetworks = pathOr<IToken[]>([], ['tokenWithNetworks'], tokens);
      const defaultCurrencies = getDefaultCurrenciesList(tokensWithNetworks, withStarToken);

      setDefaultBalances(defaultCurrencies);
    }
  }, [tokens, withStarToken]);

  useEffect(() => {
    if (isMobileView) {
      setTitle('Total');
    } else {
      setTitle('Total Collected');
    }
  }, [isMobileView]);

  const handleChangeMode = (value: 'total' | 'default') => {
    setMode(value);
  };

  if (!defaultBalances.length) return null;

  return (
    <CurrencyCard
      mode={mode}
      defaultBalances={defaultBalances}
      totalCollectedTitle={title}
      onChangeMode={handleChangeMode}
      {...props}
    />
  );
};

export default CurrencyCardContainer;
