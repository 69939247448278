import { ILoginValues } from 'components/modals/login/types';
import { AffiliateContactType, AffiliateType } from 'types';
import { IRegistrationSecondStepValues, IRegistrationFirstStepValues } from 'components/modals/registration/types';
import { IWithdrawValues } from 'components/modals/withdraw/types';
import { ISecurityValues } from 'components/pages/account/components/accountSecurity/types';
import { IChangePasswordValues } from 'components/modals/changePassword/types';

export const initialLoginValues: ILoginValues = {
  email: '',
  password: '',
};

export const initialRegistrationFirstStepValues: IRegistrationFirstStepValues = {
  email: '',
  password: '',
  confirmPassword: '',
  isAdult: false,
};

export const initialRegistrationSecondStepValues: IRegistrationSecondStepValues = {
  name: '',
  type: AffiliateType.default,
  website: '',
  contactType: AffiliateContactType.telegram,
  contact: '',
};

export const initialWithdrawValues: IWithdrawValues = {
  currency: '',
  network: '',
  amount: '',
  address: '',
  memo: '',
};

export const initialSecurityValues: ISecurityValues = {
  mfaType: 'none',
  requredAtLogin: false,
};

export const initialChangePasswordValues: IChangePasswordValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};
