import clsx from 'clsx';

import Button from 'components/common/buttons/button';
import Loader from 'components/common/loaders/loader';
import Modal from 'components/common/base/modal';
import VerificationCode from 'components/common/verificationCode';
import ErrorMessage from 'components/common/text/errorMessage';

import { IVerifyEmailModal } from './types';

const VerifyEmailModal: React.FC<IVerifyEmailModal> = ({
  email,
  code,
  emailSent,
  errorMessage,
  loading,
  onChangeCode,
  onSubmit,
  onResendEmail,
}) => {
  return (
    <Modal title="Email verification" modalClassName="max-w-[500px]">
      <div className="relative mx-auto flex w-full flex-col items-center overflow-hidden pb-6">
        {loading ? <Loader /> : null}
        <p className="mb-8 max-w-[80%] text-sm text-light-gray">
          An email with a confirmation code has been sent to {email}. It might take several minutes before you get the
          email. Please enter the code from the email below to activate your account.
        </p>
        <VerificationCode
          className="mb-8 max-w-[80%] max-[450px]:mx-0 max-[450px]:max-w-full"
          onChangeCode={onChangeCode}
        />
        <ErrorMessage className="mb-8 mt-0 w-full" message={errorMessage} />
        <Button
          variant="secondary"
          disabled={code.length < 6}
          wrapperClassList="w-full mb-8 max-w-[80%]"
          buttonsClassList="w-full"
          title="Submit"
          onClick={onSubmit}
        />
        <p className="max-w-[80%] text-center text-xs">
          Haven&apos;t received the email?{' '}
          <button
            type="button"
            className="font-bold underline underline-offset-4"
            disabled={emailSent}
            onClick={onResendEmail}
          >
            Click here
          </button>
        </p>
        <p
          className={clsx(
            'absolute bottom-6 left-1/2 -translate-x-1/2 whitespace-nowrap rounded-[10px] bg-semi-black bg-opacity-90 px-8 py-2 text-center text-sm',
            emailSent ? 'animation translate-y-0' : 'translate-y-[200%]',
          )}
        >
          Check your Email
        </p>
      </div>
    </Modal>
  );
};

export default VerifyEmailModal;
