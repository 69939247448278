/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import Big from 'big.js';
import WAValidator from 'multicoin-address-validator';
import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import UserContext from 'context/userContext/context';
import ModalsContext from 'context/modalsContext/context';
import { IWithdrawData, Modals } from 'context/modalsContext/types';
import { initialWithdrawValues } from 'constants/formik';
import { getWalletBalance, IBalance } from 'helpers/currencies';
import { INetwork, IToken, IUserSecurity, IWallet, Settings, TokenCode } from 'types';
import { IShrinkedToken, IShrinkedTokenNetwork } from 'requestTypes';
import { WALLETS } from 'graphql/user';
import { USER_SECURITY } from 'graphql/auth';
import { ACTIVE_TOKENS, NETWORKS } from 'graphql/token';
import { getCurrencyIcon, getFormattedAmount, getSetting } from 'helpers';
import { ISelectOption } from 'components/common/select/types';
import { withdrawValidationSchema } from 'constants/validation';
import { WITHDRAW } from 'graphql/transactions';
import { getUserSecurityMethod } from 'helpers/auth';

import WithdrawModal from './withdraw';
import { IWithdrawValues } from './types';
import { NETWORKS_WITH_MEMO } from './constants';

const WithdrawContainer: React.FC = () => {
  const { settings, tokensWithNetwork, loading } = useContext(UserContext);
  const { data, showModal } = useContext(ModalsContext);

  const formik = useFormik<IWithdrawValues>({
    initialValues: initialWithdrawValues,
    validationSchema: withdrawValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const [withdrawalEnabled, setWithdrawalEnabled] = useState(true);
  const [step, setStep] = useState(1);
  const [currentWallet, setCurrentWallet] = useState<IBalance>();
  const [wallets, setWallets] = useState<IBalance[]>([]);
  const [walletsOptions, setWalletsOptions] = useState<ISelectOption[]>([]);
  const [currentToken, setCurrentToken] = useState<IShrinkedToken>();
  const [currentNetwork, setCurrentNetwork] = useState<IShrinkedTokenNetwork>();
  const [networks, setNetworks] = useState<INetwork[]>([]);
  const [networksOptions, setNetworksOptions] = useState<ISelectOption[]>([]);
  const [withMemo, setWithMemo] = useState(false);
  const [withMFA, setWithMFA] = useState(true);
  const [mfaMethod, setMfaMethod] = useState<'google' | 'email' | 'none'>('none');

  const { data: walletsData, loading: walletsLoading } = useQuery(WALLETS, { fetchPolicy: 'cache-only' });
  const { data: tokensData, loading: tokensLoading } = useQuery(ACTIVE_TOKENS, { fetchPolicy: 'cache-and-network' });
  const { data: networksData, loading: networksLoading } = useQuery(NETWORKS, { fetchPolicy: 'cache-and-network' });
  const { data: userSecurityData, loading: userSecurityLoading } = useQuery(USER_SECURITY, {
    fetchPolicy: 'cache-and-network',
  });

  const [withdraw] = useMutation(WITHDRAW, { fetchPolicy: 'no-cache', refetchQueries: [WALLETS] });

  useEffect(() => {
    if (Object.values(formik.errors).length) {
      formik.setErrors({});
    }
  }, [formik.values]);

  useEffect(() => {
    const withdrawalStatus = getSetting(settings, Settings.withdrawal);

    setWithdrawalEnabled(withdrawalStatus === 'true');
  }, [settings]);

  useEffect(() => {
    if (walletsData) {
      const newWallets = pathOr<IWallet[]>([], ['wallets'], walletsData);
      const walletsBalance = getWalletBalance(newWallets);

      setWallets(walletsBalance);
    }
  }, [walletsData]);

  useEffect(() => {
    if (networksData) {
      const newNetworks = pathOr<INetwork[]>([], ['networks'], networksData);

      setNetworks(newNetworks);
    }
  }, [networksData]);

  useEffect(() => {
    if (userSecurityData) {
      const methods = pathOr<IUserSecurity | null>(null, ['userSecurity'], userSecurityData);

      if (methods) {
        const method = getUserSecurityMethod(methods);

        setMfaMethod(method);
      }
    }
  }, [userSecurityData]);

  useEffect(() => {
    if (tokensData) {
      const activeTokens = pathOr<IToken[]>([], ['activeTokens'], tokensData);

      if (activeTokens.length) {
        const newOptions = [...activeTokens]
          .sort((a, b) => (a.weight || 0) - (b.weight || 0))
          .filter((t) => t.tokenCode !== TokenCode.STAR)
          .map<ISelectOption>((w) => ({
            label: w.displayName,
            value: w.tokenCode,
            icon: getCurrencyIcon(w.tokenCode),
          }));

        setWalletsOptions(newOptions);
      }
    }
  }, [tokensData]);

  useEffect(() => {
    if (data) {
      const { tokenCode } = data as IWithdrawData;

      if (tokenCode) {
        formik.setFieldValue('currency', tokenCode);
      }
    }
  }, [data]);

  useEffect(() => {
    if (formik.values.currency) {
      formik.setFieldValue('network', '');
      formik.setFieldValue('amount', '');
      setCurrentNetwork(undefined);

      const { currency } = formik.values;

      const wallet = wallets.find((w) => w.tokenCode === currency);
      const token = tokensWithNetwork.find((t) => t.tokenCode === currency);

      setCurrentWallet(wallet);
      setCurrentToken(token);

      if (token) {
        const { networks: tokenNetworks } = token;

        const newNetworksOptions = tokenNetworks
          .map((n) => networks.find((nt) => nt.code === n.network.code))
          .map<ISelectOption>((n) => ({
            label: n?.standard || '',
            value: n?.code || '',
            icon: getCurrencyIcon(n?.code || ''),
          }));

        setNetworksOptions(newNetworksOptions);
      }
    } else {
      formik.setFieldValue('network', '');
      formik.setFieldValue('amount', '');
      setCurrentNetwork(undefined);
      setCurrentToken(undefined);
    }
  }, [formik.values.currency, tokensWithNetwork]);

  useEffect(() => {
    if (formik.values.network && currentToken) {
      const newNetwork = currentToken.networks.find((n) => n.network.code === formik.values.network);

      setCurrentNetwork(newNetwork);
    }
  }, [formik.values.network, currentToken]);

  useEffect(() => {
    if (currentNetwork) {
      const networkCode = currentNetwork.network.code;
      const newWithMemo = NETWORKS_WITH_MEMO.includes(networkCode);

      setWithMemo(newWithMemo);
      return;
    }

    setWithMemo(false);
  }, [currentNetwork]);

  const getMaximumAmount = () => {
    const walletBalance = currentWallet?.amount || '0';
    const networkFee = currentNetwork?.withdrawalFee || '0';
    const maximumAmount = Big(walletBalance).minus(networkFee).toFixed(10);

    if (Big(maximumAmount).lte(0)) {
      return getFormattedAmount(0);
    }

    return maximumAmount;
  };

  const getMinimumAmount = () => {
    const minWithdrawal = currentNetwork?.minimumWithdrawal || '0';
    const networkFee = currentNetwork?.withdrawalFee || '0';

    const minimumAmount = Big(minWithdrawal).plus(networkFee).toFixed(10);

    return getFormattedAmount(minimumAmount);
  };

  const handleChangeAmount = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // const decimalNumberRegex = /^\d*\.?\d+$/gm;
    const decimalNumberRegex = /^\d*\.?\d*$/gm;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isDelete = (ev.nativeEvent as any).inputType === 'deleteContentBackward';
    const amount = ev.target.value;

    if (!amount) {
      formik.setFieldValue('amount', '');
      return;
    }

    if (isDelete) {
      formik.setFieldValue('amount', amount);
      return;
    }

    if (amount === '00') {
      formik.setFieldValue('amount', '0.0');
      return;
    }

    if (!decimalNumberRegex.test(amount)) {
      return;
    }

    const walletBalance = currentWallet?.amount || '0';
    const networkFee = currentNetwork?.withdrawalFee || '0';
    const maximumAmount = Big(walletBalance).minus(networkFee);

    if (Big(amount).gt(maximumAmount)) {
      const newAmount = getMaximumAmount();

      formik.setFieldValue('amount', newAmount);
      return;
    }

    formik.setFieldValue('amount', amount);
  };

  const handleMaxAmount = () => {
    const newAmount = getMaximumAmount();

    formik.setFieldValue('amount', newAmount);
  };

  const handleChangeWithMFA = (value: boolean) => {
    setWithMFA(value);
  };

  async function handleSubmit(values: IWithdrawValues) {
    // eslint-disable-next-line no-console
    const { amount, address, currency, network } = values;

    const isValid = await formik.validateForm();

    if (!isValid) return;

    const addressValid = WAValidator.validate(address, currentToken?.displayName || currency, 'both');

    if (!addressValid) {
      formik.setFieldError('address', `Enter valid ${network} address`);
      return;
    }

    if (Big(amount || '').lte(currentNetwork?.minimumWithdrawal || '0')) {
      formik.setFieldError('amount', `The minimum withdrawal amount is ${getMinimumAmount()}.`);
      return;
    }

    setStep(2);
  }

  const withdrawal = async (code?: string, onError?: (error: string) => void) => {
    const variables: Record<'payload', Record<string, string | null>> = {
      payload: {
        address: formik.values.address,
        amount: formik.values.amount,
        networkCode: formik.values.network,
        tokenCode: formik.values.currency,
        memo: formik.values.memo || null,
        emailOtp: null,
        otp: null,
      },
    };

    if (code) {
      variables.payload.emailOtp = code;
      variables.payload.otp = code;
    }

    await withdraw({ variables })
      .then(() => showModal({ modal: undefined }))
      .catch((err) => (onError ? onError(err.message) : null));
  };

  const handleWithdraw = async () => {
    if (withMFA && mfaMethod !== 'none') {
      showModal({
        modal: Modals.mfa,
        data: {
          mfaType: mfaMethod,
          sendEmail: true,
          action: (code, onError) => withdrawal(code, onError),
        },
      });
    } else {
      await withdrawal();
    }
  };

  return (
    <WithdrawModal
      withdrawalEnabled={withdrawalEnabled}
      step={step}
      formik={formik}
      walletsOptions={walletsOptions}
      networksOptions={networksOptions}
      wallet={currentWallet}
      network={currentNetwork}
      withMemo={withMemo}
      loading={walletsLoading || loading || networksLoading || userSecurityLoading || tokensLoading}
      onChangeAmount={handleChangeAmount}
      onMaxAmount={handleMaxAmount}
      onChangeWithMFA={handleChangeWithMFA}
      onWithdraw={handleWithdraw}
    />
  );
};

export default WithdrawContainer;
