import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { TESTIMONIALS } from 'graphql/testimonials';
import { ITestimonial } from 'types';

import Testimonials from './testimonials';

const TestimonialsContainer: React.FC = () => {
  const [allHidden, setAllHidden] = useState(true);
  const [testimonials, setTestimonials] = useState<ITestimonial[]>([]);

  const { data } = useQuery(TESTIMONIALS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data) {
      const newTestimonials = pathOr<ITestimonial[]>([], ['testimonials'], data);

      setTestimonials(newTestimonials);
    }
  }, [data]);

  const handleShowAll = () => {
    setAllHidden((h) => !h);
  };

  return testimonials.length ? (
    <Testimonials testimonials={testimonials} allHidden={allHidden} onShowAll={handleShowAll} />
  ) : null;
};

export default TestimonialsContainer;
