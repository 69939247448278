import { useContext } from 'react';

import UserContext from 'context/userContext/context';
import { useSession } from 'context/sessionManagementContext/context';

import MobileMenu from './mobileMenu';

import { IMobileMenuContainer } from './types';

const MobileMenuContainer: React.FC<IMobileMenuContainer> = (props) => {
  const { profile } = useContext(UserContext);
  const { logout } = useSession();

  const handleLogout = () => {
    logout();
  };

  return <MobileMenu authorized={Boolean(profile)} onLogout={handleLogout} {...props} />;
};

export default MobileMenuContainer;
