import { twMerge } from 'tailwind-merge';

import { IBoxTitle } from './types';

const BoxTitle: React.FC<IBoxTitle> = ({ title, className = '' }) => {
  return (
    <h5
      className={twMerge(
        'mb-4 text-2xl font-extrabold max-[1350px]:text-xl max-[1250px]:text-base max-[870px]:text-2xl max-[350px]:text-base',
        className,
      )}
    >
      {title}
    </h5>
  );
};

export default BoxTitle;
