/* eslint-disable react/destructuring-assignment */
import { components } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { TSelectOption } from './types';

const { Option } = components;

const SelectOption: React.FC<TSelectOption> = (props) => {
  const { label, icon } = props.data;

  return (
    <Option {...props} className={twMerge(props.className, '!relative !mb-1 !flex !items-center !py-2')}>
      {icon ? <img src={icon} className="absolute left-2 h-auto w-[32px]" alt={label} /> : null}
      <span>{label}</span>
    </Option>
  );
};

export default SelectOption;
