import HomepageTitle from 'components/common/text/title/homapage';

import FeaturesListContainer from './components/feautresList';

const Features: React.FC = () => {
  return (
    <section className="container mb-20 flex flex-col items-center">
      <HomepageTitle component="h2">Features</HomepageTitle>
      <FeaturesListContainer />
    </section>
  );
};

export default Features;
