/* eslint-disable no-use-before-define */
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import ModalsContext from 'context/modalsContext/context';
import { CAMPAIGNS } from 'graphql/campaigns';
import { IAffiliateCampaign } from 'types';
import { getAffiliateCampaignsRows } from 'helpers/table';
import { CAMPAIGNS_TABLE_HEAD, ITEMS_PER_PAGE } from 'constants/table';
import { ITableRow } from 'components/common/table/tableRow/types';
import { getOffset } from 'helpers/request';

import TableContainer from 'components/common/table/tableWithPagination';
import { Modals } from 'context/modalsContext/types';

const CampaignsTable: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const [campaigns, setCampaigns] = useState<IAffiliateCampaign[]>([]);
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(CAMPAIGNS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data) {
      const newCampaigns = pathOr<IAffiliateCampaign[]>([], ['affiliateCampaigns'], data);

      setCampaigns(newCampaigns);
      setCount(newCampaigns.length);
    }
  }, [data]);

  useEffect(() => {
    const offset = getOffset(page, ITEMS_PER_PAGE);
    const slicedCampaigns = campaigns.slice(offset, page * ITEMS_PER_PAGE);
    const campaignsRows = getAffiliateCampaignsRows(slicedCampaigns, handleShowEditModal);

    setRows(campaignsRows);
  }, [campaigns, page]);

  const handleShowEditModal = (id: string, name: string) => {
    showModal({ modal: Modals.updateCampaign, data: { id, name } });
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <TableContainer
      headCells={CAMPAIGNS_TABLE_HEAD}
      rows={rows}
      page={page}
      count={count}
      loading={loading}
      onChangePage={handleChangePage}
    >
      <p className="mb-6 flex items-center text-xl font-bold max-[900px]:mb-4 max-[520px]:text-base">
        <span className="mr-2">Total Campaigns:</span>
        <span className="text-orange">{count}</span>
      </p>
    </TableContainer>
  );
};

export default CampaignsTable;
