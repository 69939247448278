import { useContext } from 'react';

import ModalsContext from 'context/modalsContext/context';
import { Modals } from 'context/modalsContext/types';

import Invitation from './invitation';

const InvitationContainer: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const handleRegistration = () => {
    showModal({ modal: Modals.registration });
  };

  return <Invitation onRegistration={handleRegistration} />;
};

export default InvitationContainer;
