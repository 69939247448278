import { Link } from 'react-router-dom';

import { ReactComponent as GearIcon } from 'assets/common/gear.svg';

import { Links } from 'constants/nav';
import { getUserAvatar } from 'helpers';

import Box from 'components/common/content/box';
import ImageSkeleton from 'components/common/skeleton/imageSkeleton';
import TextSkeleton from 'components/common/skeleton/textSkeleton';

import { IDashboardOverviewProfile } from './types';

const DashboardOverviewProfile: React.FC<IDashboardOverviewProfile> = ({ email, alias, avatar, loading }) => {
  return (
    <Box
      component="li"
      className="mr-4 h-[118px] w-full max-w-[25%] overflow-hidden max-[1280px]:mb-4 max-[1280px]:max-w-[48.5%] max-[720px]:mr-0 max-[720px]:max-w-full max-[455px]:h-auto"
    >
      <h5 className="mb-5 font-medium">Profile:</h5>
      <div className="flex max-w-full items-center justify-between max-[455px]:flex-col max-[455px]:items-start">
        <div className="flex max-w-[80%] flex-grow items-center max-[455px]:mb-4 max-[455px]:max-w-full">
          <div className="shadow-filter bgg-purple mr-4 flex h-[42px] w-[42px] min-w-[42px] items-center justify-center rounded-full">
            {loading ? (
              <ImageSkeleton className="h-[38px] w-[38px]" />
            ) : (
              <img className="h-auto w-[38px] rounded-full" src={getUserAvatar(avatar)} alt="Avatar" />
            )}
          </div>
          <div className="flex flex-grow flex-col overflow-hidden text-sm">
            {loading ? <TextSkeleton className="mb-1" /> : <p className="truncate">{alias}</p>}
            {loading ? <TextSkeleton /> : <p className="truncate text-yellow">{email}</p>}
          </div>
        </div>
        <Link
          to={Links.account}
          className="bgg-purple group ml-2 flex h-[42px] w-[42px] min-w-[42px] items-center justify-center rounded-full hover:animate-spin-slow max-[455px]:self-center"
        >
          <div className="flex h-[38px] w-[38px] items-center justify-center rounded-full bg-dark-purple">
            <GearIcon className="h-auto w-[20px] group-hover:opacity-80" />
          </div>
        </Link>
      </div>
    </Box>
  );
};

export default DashboardOverviewProfile;
