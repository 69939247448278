/* eslint-disable react/button-has-type */
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { IButton } from './types';
import styles from './styles.module.scss';

const Button: React.FC<IButton> = ({
  title,
  variant = 'main',
  size = 'normal',
  disabled,
  wrapperClassList = '',
  buttonsClassList = '',
  ...props
}) => {
  const wrapper = twMerge(
    'flex items-center justify-center w-max h-max m-0 animation',
    variant === 'main' ? clsx('bgg-green', styles.shadowGreen) : '',
    variant === 'secondary' ? 'bgg-purple sh-purple' : '',
    size === 'normal' ? 'p-[2px] rounded-[30px]' : '',
    size === 'small' ? 'p-[1px] rounded-[24px]' : '',
    disabled ? 'opacity-70 pointer-events-none selection:pointer-events-none' : '',
    wrapperClassList,
  );
  const button = twMerge(
    'text-xs uppercase tracking-[1.1px] outline-none selection:pointer-events-none',
    variant === 'secondary' ? 'bg-dark-purple rounded-[19px]' : '',
    size === 'normal' ? 'px-[15px] py-[8px] font-semibold' : '',
    size === 'small' ? 'px-[12px] py-[4px] font-medium' : '',
    disabled ? 'pointer-events-none' : '',
    buttonsClassList,
  );

  return (
    <div className={wrapper}>
      <button className={button} disabled={disabled} {...props}>
        {title}
      </button>
    </div>
  );
};

export default Button;
