/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactComponent as CopyIcon } from 'assets/common/copy.svg';

import { getAuthenticatorLink } from 'helpers';

import Modal from 'components/common/base/modal';
import Loader from 'components/common/loaders/loader';
import Tooltip from 'components/common/text/tooltip';
import Button from 'components/common/buttons/button';
import Checkbox from 'components/common/inputs/checkbox';
import VerificationCode from 'components/common/verificationCode';

import ErrorMessage from 'components/common/text/errorMessage';
import { twMerge } from 'tailwind-merge';
import { IAuthenticatorModal } from './types';

const AuthenticatorModal: React.FC<IAuthenticatorModal> = ({
  inputRef,
  qrCode,
  recoveryCode,
  saved,
  code,
  errorMessage,
  loading,
  onToggleSaved,
  onChangeCode,
  onCodeClick,
  onCopy,
  onSubmit,
}) => {
  return (
    <Modal title="2-Factor Authentication" titleClassName="text-center" modalClassName="w-[450px]">
      <div className="relative mx-auto flex w-full flex-col items-center overflow-hidden overflow-y-auto pb-4 max-[400px]:w-4/5">
        {loading ? <Loader /> : null}
        <p className="mb-4 text-sm">
          Install{' '}
          <a href={getAuthenticatorLink()} className="cursor-pointer font-bold" target="_blank" rel="noreferrer">
            Google Authenticator
          </a>{' '}
          or similar app on your mobile device and Scan QR code with the authenticator.
        </p>
        <div className="flex w-full max-[450px]:flex-col-reverse max-[450px]:items-center">
          <div className="mr-4 flex flex-col">
            <div className="relative mb-4 rounded-[20px] bg-dark-blue px-4 py-2" onClick={onCodeClick}>
              <input
                ref={inputRef}
                className="w-full bg-transparent align-middle text-sm font-bold outline-none max-[1300px]:text-[0.6rem] max-[1280px]:text-sm max-[720px]:text-sm  max-[455px]:text-xs  max-[424px]:text-[0.6rem]"
                value={recoveryCode}
                onChange={() => null}
              />
              <Tooltip
                text="Copied"
                showOnAction
                position="top"
                className="absolute right-0 top-1/2 ml-auto h-[42px] w-[42px] -translate-y-1/2"
              >
                <button
                  type="button"
                  className="bgg-green group/button flex h-[42px] w-[42px] items-center justify-center rounded-full"
                  onClick={onCopy}
                >
                  <CopyIcon className="h-auto w-[20px] group-hover/button:opacity-80" />
                </button>
              </Tooltip>
            </div>
            <p className="text-xs">
              Save the hard copy of your secret code and put it in a safe place. If your phone gets lost or stolen, you
              will need this code to access StarBets.
            </p>
          </div>
          <img className="h-[130px] w-auto max-[450px]:mb-4" src={qrCode} alt="QR Code" />
        </div>
        <div className="flex w-full flex-col" onKeyDown={(ev) => (ev.key === 'Enter' ? onSubmit() : null)}>
          <Checkbox
            lableWrapperClassList="my-4"
            checked={saved}
            label={<>I saved my secret code</>}
            onChange={onToggleSaved}
          />
          <p className="mb-8 text-center text-sm">
            Enter the authentication code generated by Google Authenticator on your device
          </p>
          <VerificationCode
            className={twMerge('self-center', errorMessage ? 'mb-2' : 'mb-8')}
            onChangeCode={onChangeCode}
          />
          <ErrorMessage className="mb-4" message={errorMessage} />
          <Button
            wrapperClassList="self-center"
            buttonsClassList="w-[150px]"
            disabled={!saved || code.length < 6}
            type="button"
            variant="secondary"
            title="Submit"
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AuthenticatorModal;
