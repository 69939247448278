import HeaderContainer from 'components/common/base/header';
import ModalsContainer from 'containers/modals';

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex min-h-screen w-full flex-col bg-dark-blue text-base text-white">
      <HeaderContainer />
      <main className="flex flex-grow flex-col">{children}</main>
      <ModalsContainer />
    </div>
  );
};

export default Layout;
