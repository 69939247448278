import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { TRANSACTION_TABLE_HEAD, ITEMS_PER_PAGE } from 'constants/table';
import { TRANSACTIONS } from 'graphql/transactions';
import { getTransactionsVariables } from 'helpers/request';
import { ITableRow } from 'components/common/table/tableRow/types';
import UserContext from 'context/userContext/context';
import { getTransactionsRows } from 'helpers/table';

import TableContainer from 'components/common/table/tableWithPagination';

const TransactionsTableContainer: React.FC = () => {
  const { profile } = useContext(UserContext);

  const [rows, setRows] = useState<ITableRow[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const { data, loading, refetch } = useQuery(TRANSACTIONS, {
    fetchPolicy: 'cache-and-network',
    skip: !profile,
    variables: getTransactionsVariables({}),
  });

  useEffect(() => {
    if (data) {
      const transactions = pathOr([], ['paginatedTransactions', 'items'], data);
      const transactionsCount = pathOr(0, ['paginatedTransactions', 'count'], data);
      const transactionsRows = getTransactionsRows(transactions);

      setRows(transactionsRows);
      setCount(transactionsCount);
    }
  }, [data]);

  const handleChangePage = (newPage: number) => {
    const offset = newPage === 1 ? 0 : (newPage - 1) * ITEMS_PER_PAGE;
    const variables = getTransactionsVariables({ offset });

    setPage(newPage);
    refetch(variables);
  };

  return (
    <TableContainer
      headCells={TRANSACTION_TABLE_HEAD}
      rows={rows}
      page={page}
      count={count}
      loading={loading}
      onChangePage={handleChangePage}
    />
  );
};

export default TransactionsTableContainer;
