import { twMerge } from 'tailwind-merge';

import { IPageDescription } from './types';

const PageDescription: React.FC<IPageDescription> = ({ variant = 'main', className = '', children }) => {
  const styles = twMerge(
    'text-sm',
    variant === 'main' ? 'mb-4' : '',
    variant === 'secondary' ? 'mb-2 text-light-gray' : '',
    className,
  );

  return <p className={styles}>{children}</p>;
};

export default PageDescription;
