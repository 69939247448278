import { Helmet } from 'react-helmet';

import tosBanner from 'assets/tos/tos.webp';

import {
  DEFINITIONS,
  AFFILIATE_OBLIGATIONS,
  AFFILIATE_RIGHTS,
  COMPANY_OBLIGATIONS,
  COMPANY_RIGHTS,
  COMMISSION_AND_PAYMENT,
  CONFIDENTIAL_INFORMATION,
  TERM_AND_TERMINATION,
  MISCELLANEOUS,
} from 'constants/tos';

import Footer from 'components/common/base/footer';

import TermsOfServiceBlock from './components/termsOfServiceBlock';

const TermsOfServicePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Terms Of Service - StarBets Affiliates</title>
      </Helmet>
      <div className="flex w-full flex-col">
        <div className="container flex w-full flex-col items-center max-[670px]:w-4/5">
          <div className="my-8 w-full">
            <img
              className="pointer-events-none h-auto w-full selection:pointer-events-none"
              src={tosBanner}
              alt="ToS Banner"
            />
          </div>
          <div className="shadow-black mb-8 w-full rounded-[20px] bg-dark-purple px-16 py-8 max-[560px]:px-8 max-[560px]:py-4">
            <ol className="counter-item flex w-full list-decimal flex-col">
              <TermsOfServiceBlock data={DEFINITIONS} />
              <TermsOfServiceBlock data={AFFILIATE_OBLIGATIONS} />
              <TermsOfServiceBlock data={AFFILIATE_RIGHTS} />
              <TermsOfServiceBlock data={COMPANY_OBLIGATIONS} />
              <TermsOfServiceBlock data={COMPANY_RIGHTS} />
              <TermsOfServiceBlock data={COMMISSION_AND_PAYMENT} />
              <TermsOfServiceBlock data={CONFIDENTIAL_INFORMATION} />
              <TermsOfServiceBlock data={TERM_AND_TERMINATION} />
              <TermsOfServiceBlock data={MISCELLANEOUS} />
            </ol>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TermsOfServicePage;
