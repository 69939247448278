/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import clsx from 'clsx';

import { getCurrencyIcon, getFormattedAmount } from 'helpers';

import Box from 'components/common/content/box';
import Button from 'components/common/buttons/button';
import PageDescription from 'components/common/text/description/pageDescription';
import CurrencyTemplate from 'components/common/currencyTemplate';
import Select from 'components/common/select';

import { ICurrencyCard } from './types';
import styles from './styles.module.scss';

const CurrencyCard: React.FC<ICurrencyCard> = ({
  mode,
  title,
  description,
  totalAmount = '0',
  totalAmountCollected = '0',
  options = [],
  selectedOption = '',
  currencies = [],
  defaultBalances = [],
  withTotalCollected = false,
  disableButton = false,
  buttonTitle = '',
  selectPlaceholder,
  selectPlaceholderIcon,
  totalCollectedTitle,
  onSelectOption,
  onChangeMode,
  onSubmit,
  onWithdraw,
  collectedCurrencies = [],
}) => {
  return (
    <Box component="article" className="flex h-full w-full flex-grow flex-col p-8">
      <div className="mb-4 flex items-center text-2xl font-extrabold max-[1350px]:text-xl max-[1250px]:text-base max-[870px]:text-2xl max-[350px]:text-base">
        <h5
          className={clsx('cursor-pointer', mode !== 'default' ? 'text-light-gray' : '')}
          onClick={() => onChangeMode('default')}
        >
          {title}
        </h5>
        {withTotalCollected ? (
          <h5
            className={clsx('ml-8 cursor-pointer', mode !== 'total' ? 'text-light-gray' : '')}
            onClick={() => onChangeMode('total')}
          >
            {totalCollectedTitle}
          </h5>
        ) : null}
      </div>
      {description && mode === 'default' ? <PageDescription>{description}</PageDescription> : null}
      <div className="mb-4 flex items-center justify-between max-[500px]:flex-col">
        <div className="flex items-center">
          <img className="h-auto w-[42px]" src={getCurrencyIcon('USD')} alt="USD Icon" />
          <p className="text-2xl max-[500px]:text-base">
            ${getFormattedAmount(mode === 'default' ? totalAmount : totalAmountCollected)}
          </p>
        </div>
        {onWithdraw ? (
          <Button
            wrapperClassList="ml-4 max-[500px]:ml-0 max-[500px]:mt-4"
            variant="secondary"
            title="Withdraw"
            disabled={disableButton}
            onClick={onWithdraw}
          />
        ) : null}
      </div>
      {mode === 'default' && options.length ? (
        <div className="mb-4 flex w-full items-center max-[500px]:flex-col">
          <Select
            className="flex-grow"
            placeholder={selectPlaceholder}
            placeholderIcon={selectPlaceholderIcon}
            selected={selectedOption}
            options={options}
            onSelect={(v) => (onSelectOption ? onSelectOption(v) : null)}
          />
          <Button
            wrapperClassList={clsx(
              'ml-4 max-[500px]:ml-0 max-[500px]:mt-4',
              disableButton ? '' : styles.animationButton,
            )}
            variant="secondary"
            title={buttonTitle}
            disabled={disableButton}
            onClick={onSubmit as VoidFunction}
          />
        </div>
      ) : null}
      <div className="mb-auto flex flex-grow items-end">
        {mode === 'default' ? (
          <CurrencyTemplate currencies={currencies.length ? currencies : defaultBalances} />
        ) : (
          <CurrencyTemplate currencies={collectedCurrencies.length ? collectedCurrencies : defaultBalances} />
        )}
      </div>
    </Box>
  );
};

export default CurrencyCard;
