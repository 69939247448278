import Modal from 'components/common/base/modal';

import { IForbiddenModal } from './types';

const ForbiddenModal: React.FC<IForbiddenModal> = ({ text }) => {
  return (
    <Modal title="Forbidden" modalClassName="w-[460px]">
      <div className="relative mx-auto flex w-full flex-col items-center overflow-hidden py-6">
        <p className="mb-8 text-center text-sm">{text}</p>
      </div>
    </Modal>
  );
};

export default ForbiddenModal;
