import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { IFeatureCard } from './types';

import styles from './styles.module.scss';

const FeaturesItem: React.FC<IFeatureCard> = ({ feature, classList }) => {
  const wrapperStyles = twMerge(
    'relative mr-6 flex w-1/3 flex-col items-center rounded-[20px] bg-dark-purple px-3 py-10 last:mr-0 max-[1023px]:py-6 max-[670px]:mb-6 max-[670px]:mr-0 max-[670px]:w-full max-[670px]:pb-10 max-[670px]:pt-12 max-[400px]:pt-8',
    classList,
  );
  const iconStyles = twMerge(
    'pointer-events-none relative z-10 h-[144px] w-auto selection:pointer-events-none max-[1100px]:h-[120px] max-[1023px]:h-[80px] max-[670px]:h-[24vw]',
  );
  const descriptionStyles = twMerge(
    'h-[40px] text-center align-middle text-sm font-normal max-[1300px]:h-[60px] max-[1023px]:h-[80px] max-[720px]:h-[100px] max-[670px]:h-auto',
  );

  return (
    <li className={clsx(wrapperStyles, styles.shadow)}>
      <div className="mb-12 max-[670px]:mb-20">
        <img
          className="pointer-events-none absolute left-0 right-0 top-0 rounded-t-[20px]"
          src={feature.background}
          alt={feature.title}
        />
        <img className={iconStyles} src={feature.icon} alt={feature.title} />
      </div>
      <h5 className="mb-2 text-xl font-extrabold uppercase">{feature.title}</h5>
      <p className={descriptionStyles}>{feature.description}</p>
    </li>
  );
};

export default FeaturesItem;
