import { gql } from '@apollo/client';

export const INIT_LIST = gql`
  query initList {
    affiliateBalances {
      availableBalance
      totalCollected
      token {
        displayName
        tokenCode
      }
    }
    exchangeRates {
      usdValue
      token {
        displayName
        tokenCode
      }
    }
    wallets {
      availableBalance
      token {
        displayName
        tokenCode
      }
    }
  }
`;
