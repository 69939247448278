/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';

import Button from 'components/common/buttons/button';
import Modal from 'components/common/base/modal';
import VerificationCode from 'components/common/verificationCode';
import ErrorMessage from 'components/common/text/errorMessage';

import { IMfa } from './types';

const Mfa: React.FC<IMfa> = ({
  title,
  code,
  mfaType,
  errorMessage,
  emailSent,
  onChangeCode,
  onResendEmail,
  onSubmit,
}) => {
  return (
    <Modal title={`${title} 2FA`} modalClassName="w-[460px]">
      <div
        className="relative mx-auto flex w-full flex-col items-center overflow-hidden py-6"
        onKeyDown={(ev) => (ev.key === 'Enter' ? onSubmit() : null)}
      >
        <p className="mb-8 max-w-[80%] text-center text-sm">
          Enter the code {mfaType === 'google' ? 'generated by Google Authentificator' : 'sent by Email'}
        </p>
        <VerificationCode
          className="mb-8 max-w-[80%] max-[450px]:mx-0 max-[450px]:max-w-full"
          onChangeCode={onChangeCode}
        />
        <ErrorMessage className="mb-8 mt-0 w-full" message={errorMessage} />
        <Button
          variant="secondary"
          disabled={code.length < 6}
          wrapperClassList="w-full mb-8 max-w-[80%]"
          buttonsClassList="w-full"
          title="Submit"
          onClick={onSubmit}
        />
        {mfaType === 'google' ? (
          <p className="max-w-[80%] text-center text-xs ">
            If you you cannot access your secret key, please contact us at{' '}
            <a href="mailto:support@starbets.io" className="font-bold">
              support@starbets.io
            </a>
          </p>
        ) : (
          <p className="max-w-[80%] text-center text-xs">
            Haven&apos;t received the email?{' '}
            <button
              type="button"
              className="font-bold underline underline-offset-4"
              disabled={emailSent}
              onClick={onResendEmail}
            >
              Click here
            </button>
          </p>
        )}
        <p
          className={clsx(
            'absolute bottom-6 left-1/2 -translate-x-1/2 whitespace-nowrap rounded-[10px] bg-semi-black bg-opacity-90 px-8 py-2 text-center text-sm',
            emailSent ? 'animation translate-y-0' : 'translate-y-[200%]',
          )}
        >
          Check your Email
        </p>
      </div>
    </Modal>
  );
};

export default Mfa;
