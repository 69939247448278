import { Helmet } from 'react-helmet';

import accountIcon from 'assets/nav/account.webp';

import PageTitle from 'components/common/text/title/page';
import AccountInfoContainer from './components/accountInfo';
import AccountSecurityContainer from './components/accountSecurity';
import AccountTableContainer from './components/accountTable';

const AccountPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Account - StarBets Affiliates</title>
      </Helmet>
      <div className="flex w-full flex-grow flex-col">
        <PageTitle title="My Account" icon={accountIcon} color="blue" />
        <section className="mb-6 flex max-w-full flex-grow max-[870px]:flex-col">
          <AccountInfoContainer />
          <AccountSecurityContainer />
        </section>
        <AccountTableContainer />
      </div>
    </>
  );
};

export default AccountPage;
