import Modal from 'components/common/base/modal';
import Input from 'components/common/inputs/input';
import Button from 'components/common/buttons/button';
import Loader from 'components/common/loaders/loader';

import ErrorMessage from 'components/common/text/errorMessage';
import { IChangePasswordModal } from './types';

const ChangePasswordModal: React.FC<IChangePasswordModal> = ({ formik, errorMessage, loading, onClose }) => {
  return (
    <Modal title="Chagne Password" modalClassName="w-[500px]" onClose={onClose}>
      <div className="relative mx-auto flex w-full flex-col items-center overflow-hidden pb-6 max-[400px]:w-4/5">
        {loading ? <Loader /> : null}
        <form className="flex h-full w-full flex-col items-center" onSubmit={formik.handleSubmit}>
          <Input
            formik={formik}
            autoComplete="current-password"
            name="currentPassword"
            type="password"
            placeholder="Password"
            label="Password:"
            labelAlign="center"
            value={formik.values.currentPassword}
            errorMessage={formik.errors?.currentPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Input
            formik={formik}
            autoComplete="new-password"
            name="newPassword"
            type="password"
            placeholder="New Password"
            label="New Password:"
            labelAlign="center"
            value={formik.values.newPassword}
            errorMessage={formik.errors?.newPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Input
            formik={formik}
            autoComplete="new-password"
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            lableWrapperClassList="mb-4"
            label="Confirm Password:"
            labelAlign="center"
            value={formik.values.confirmPassword}
            errorMessage={formik.errors?.confirmPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <ErrorMessage message={errorMessage} />
          <Button
            wrapperClassList={errorMessage ? 'mt-4' : 'mt-6'}
            type="submit"
            variant="secondary"
            title="Change Password"
          />
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
