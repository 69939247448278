import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { detect } from 'detect-browser';

import defaultAvatar from 'assets/common/defaultAvatar.webp';
import btcIcon from 'assets/currencies/btcIcon.webp';
import etherIcon from 'assets/currencies/ltcIcon.webp';
import liteCoinIcon from 'assets/currencies/LTCcoin.webp';
import tronIcon from 'assets/currencies/tron.webp';
import startIcon from 'assets/currencies/starcoin.webp';
import bnbIcon from 'assets/currencies/bnb-coin.webp';
import usdtIcon from 'assets/currencies/USDTcoin.webp';
import usdIcon from 'assets/currencies/usdIcon.webp';
import xrpIcon from 'assets/currencies/XRPcoin.webp';
import dogeIcon from 'assets/currencies/DOGEcoin.webp';

import { MediaEndpoints } from 'constants/config';
import { ISetting, Settings } from 'types';

dayjs.extend(utc);

export const formatUSDValue = (value: string) => {
  const numberRegex = /\B(?=(\d{3})+(?!\d))/g;

  return value.replace(numberRegex, ',');
};

export const getCurrencyIcon = (currency: string) => {
  switch (currency) {
    case 'BTC':
      return btcIcon;
    case 'STAR':
      return startIcon;
    case 'ETH':
      return etherIcon;
    case 'LTC':
      return liteCoinIcon;
    case 'TRX':
      return tronIcon;
    case 'BNB':
      return bnbIcon;
    case 'BSC':
      return bnbIcon;
    case 'USDT':
      return usdtIcon;
    case 'XRP':
      return xrpIcon;
    case 'DOGE':
      return dogeIcon;
    case 'USD':
      return usdIcon;
    default: {
      return '';
    }
  }
};

export const getFormattedDate = (date: number | string | Date) => {
  return dayjs.utc(date).format('MMMM DD, YYYY');
};

export const getUserAvatar = (avatar?: string) => {
  return avatar ? `${MediaEndpoints.avatars}${avatar}` : defaultAvatar;
};

const applyComas = (amount: string) => {
  const [newAmount, decimal] = amount.split('.');

  const amountWithComas = newAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${amountWithComas}${decimal ? `.${decimal}` : ''}`;
};

export const getFormattedAmount = (amount: string | number, decimal = 8, comas = true): string => {
  const extraCharactersRegex = /[^-\d.]+/gim;
  const parsedAmount = amount.toString().replace(extraCharactersRegex, '');

  if (parsedAmount) {
    const includesDots = parsedAmount.includes('.');
    const includesMinus = parsedAmount.indexOf('-') !== -1;

    const additionalNumbers = Array(decimal).fill('0').join('');
    const formattedAmount = `${parsedAmount}${includesDots ? '' : '.'}${additionalNumbers}`;
    const newAmount = formattedAmount.slice(0, includesMinus ? 11 : 10);

    if (comas) {
      const amountWithComas = applyComas(newAmount);

      return amountWithComas;
    }

    return newAmount;
  }

  return '';
};

export const parseErrorMessage = (message: string): Record<string, string> | undefined => {
  try {
    return JSON.parse(message);
  } catch {
    return undefined;
  }
};

export const getSetting = (settings: ISetting[], setting: Settings): string => {
  const searchedSetting = settings.find((s) => s.name === setting);

  return searchedSetting?.value || '';
};

export const getAuthenticatorLink = () => {
  const agent = detect();

  if (agent?.os === 'Android OS') {
    return 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
  }

  if (agent?.os === 'iOS') {
    return 'https://apps.apple.com/app/google-authenticator/id388497605';
  }

  if (agent?.name === 'chrome') {
    return 'https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai';
  }

  if (agent?.name === 'safari' || agent?.os === 'Mac OS') {
    return 'https://apps.apple.com/app/authenticator-app/id1538761576';
  }

  if (agent?.name === 'firefox') {
    return 'https://addons.mozilla.org/firefox/addon/auth-helper/';
  }

  return 'https://www.google.com/search?q=google+authenticator+app';
};
