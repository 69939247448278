import { ExternalLinks } from 'constants/nav';

import DashboardOverviewPromo from './dashboardOverviewPromo';

const DashboardOverviewPromoContainer: React.FC = () => {
  const handleView = () => {
    window.open(ExternalLinks.promo, '_blank');
  };

  return <DashboardOverviewPromo onView={handleView} />;
};

export default DashboardOverviewPromoContainer;
