import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import UserContext from 'context/userContext/context';
import ModalsContext from 'context/modalsContext/context';
import { Modals } from 'context/modalsContext/types';
import { getUserAvatar } from 'helpers';
import { PROFILE, UPDATE_ALIAS } from 'graphql/user';

import AccountInfo from './accountInfo';

const AccountInfoContainer: React.FC = () => {
  const { profile } = useContext(UserContext);
  const { showModal } = useContext(ModalsContext);

  const [avatar, setAvatar] = useState('');
  const [alias, setAlias] = useState('');
  const [newAlias, setNewAlias] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [email, setEmail] = useState('');
  const [hideTimeout, setHideTimeout] = useState<NodeJS.Timeout>();

  const [updateAlias] = useMutation(UPDATE_ALIAS, { fetchPolicy: 'no-cache', refetchQueries: [PROFILE] });

  useEffect(() => {
    if (profile) {
      const { user } = profile;

      const newAvatar = getUserAvatar(user.avatar?.avatar);

      setAvatar(newAvatar);
      setAlias(user.alias);
      setNewAlias(user.alias);
      setEmail(user.email || '');
    }
  }, [profile]);

  const handleChangeAvatar = () => {
    showModal({ modal: Modals.avatar });
  };

  const handleChangeAlias = () => {
    setEditMode(true);
  };

  const handleChangeNewAlias = (value: string) => {
    if (errorMessage) setErrorMessage('');

    setNewAlias(value);
  };

  const setAliasError = (message: string) => {
    setErrorMessage(message);

    setTimeout(() => setErrorMessage(''), 3000);
  };

  const handleSaveAlias = async () => {
    if (newAlias.length < 3) {
      setAliasError('Alias cannot be less than 3 characters');
      return;
    }

    if (newAlias.length > 15) {
      setAliasError('Alias cannot be more than 15 characters');
      return;
    }

    const variables = { userFormData: { alias: newAlias } };

    await updateAlias({ variables })
      .then(() => setEditMode(false))
      .catch((err) => setAliasError(err.message));
  };

  const handleKeyDownAlias = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      handleSaveAlias();
      return;
    }

    if (ev.key === 'Escape') {
      setNewAlias(alias);
      setEditMode(false);
      setErrorMessage('');
    }
  };

  const handleFocusAlias = () => {
    if (hideTimeout) {
      clearTimeout(hideTimeout);
    }
  };

  const handleBlurAlias = (ev: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!ev.relatedTarget) {
      const timeout = setTimeout(() => setEditMode(false), 1500);

      setHideTimeout(timeout);
    }
  };

  const handleChangePassword = () => {
    showModal({ modal: Modals.changePassword });
  };

  return (
    <AccountInfo
      avatar={avatar}
      alias={alias}
      email={email}
      editMode={editMode}
      newAlias={newAlias}
      errorMessage={errorMessage}
      onChangeAvatar={handleChangeAvatar}
      onChangeAlias={handleChangeAlias}
      onChangeNewAlias={handleChangeNewAlias}
      onKeyDownAlias={handleKeyDownAlias}
      onFocusAlias={handleFocusAlias}
      onBlurAlias={handleBlurAlias}
      onSaveAlias={handleSaveAlias}
      onChangePassword={handleChangePassword}
    />
  );
};

export default AccountInfoContainer;
