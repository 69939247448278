import Button from 'components/common/buttons/button';

import Testimonial from './components/testimonial';

import { ITestimonialsComponent } from './types';

const Testimonials: React.FC<ITestimonialsComponent> = ({ testimonials, allHidden, onShowAll }) => {
  return (
    <section className="container mb-20 flex flex-col items-center">
      <ul className="mb-4 flex w-full flex-wrap justify-between max-[670px]:w-4/5 max-[670px]:flex-col">
        {testimonials.slice(0, allHidden ? 6 : undefined).map((t) => (
          <Testimonial key={t.id} testimonial={t} />
        ))}
      </ul>
      {testimonials.length > 6 ? (
        <Button variant="secondary" title={allHidden ? 'Show More' : 'Hide'} onClick={onShowAll} />
      ) : null}
    </section>
  );
};

export default Testimonials;
