/* eslint-disable react/no-danger */
import { ITermsOfServiceBlock } from './types';

const TermsOfServiceBlock: React.FC<ITermsOfServiceBlock> = ({ data }) => {
  return (
    <li className="-counter-item mb-6">
      <span className="mb-2 text-lg font-bold uppercase">{data.title}</span>
      <ol className="counter-item">
        {data.descriptions.map((d) => (
          <li
            key={d}
            className="mb-3 text-sm font-medium first:mt-2 last:mb-0"
            dangerouslySetInnerHTML={{ __html: d }}
          />
        ))}
      </ol>
    </li>
  );
};

export default TermsOfServiceBlock;
