import { gql } from '@apollo/client';

export const AFFILIATE_REFERRALS = gql`
  query AffiliateReferrals($offset: Int, $limit: Int, $from: DateTime, $to: DateTime) {
    affiliateReferrals(offset: $offset, limit: $limit, from: $from, to: $to) {
      total
      active
      items {
        alias
        createdAt
        totalWager
        wagers {
          tokenCode
          totalWager
        }
      }
    }
  }
`;

export const AFFILIATE_CAMPAIGN_REFERRALS = gql`
  query affiliateCampaignReferrals(
    $campaignId: String
    $to: DateTime
    $from: DateTime
    $limit: Int
    $offset: Int
    $alias: String
  ) {
    affiliateCampaignReferrals(
      campaignId: $campaignId
      to: $to
      from: $from
      limit: $limit
      offset: $offset
      alias: $alias
    ) {
      total
      active
      items {
        alias
        totalWager
        createdAt
        wagers {
          tokenCode
          totalWager
        }
      }
    }
  }
`;
