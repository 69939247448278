import { useContext } from 'react';

import ModalsContext from 'context/modalsContext/context';
import { Modals } from 'context/modalsContext/types';

import LoginContainer from 'components/modals/login';
import RegistrationContainer from 'components/modals/registration';
import MfaContainer from 'components/modals/mfa';
import ForbiddenModal from 'components/modals/forbidden';
import VerifyEmailContainer from 'components/modals/verifyEmail';
import CollectContainer from 'components/modals/collect';
import WithdrawContainer from 'components/modals/withdraw';
import SuccessContainer from 'components/modals/success';
import CreateCampaignContainer from 'components/modals/createCampaign';
import UpdateCampaignContainer from 'components/modals/updateCampaign';
import ForgotPasswordContainer from 'components/modals/forgotPassword';
import AuthenticatorContainer from 'components/modals/authenticator';
import AvatarContainer from 'components/modals/avatar';
import ChangePasswordContainer from 'components/modals/changePassword';

const ModalsContainer: React.FC = () => {
  const { modal } = useContext(ModalsContext);

  return (
    <>
      {modal === Modals.login ? <LoginContainer /> : null}
      {modal === Modals.registration ? <RegistrationContainer /> : null}
      {modal === Modals.success ? <SuccessContainer /> : null}
      {modal === Modals.forbidden ? <ForbiddenModal /> : null}
      {modal === Modals.verifyEmail ? <VerifyEmailContainer /> : null}
      {modal === Modals.collect ? <CollectContainer /> : null}
      {modal === Modals.withdraw ? <WithdrawContainer /> : null}
      {modal === Modals.authenticator ? <AuthenticatorContainer /> : null}
      {modal === Modals.createCampaign ? <CreateCampaignContainer /> : null}
      {modal === Modals.updateCampaign ? <UpdateCampaignContainer /> : null}
      {modal === Modals.forgotPassword ? <ForgotPasswordContainer /> : null}
      {modal === Modals.changePassword ? <ChangePasswordContainer /> : null}
      {modal === Modals.avatar ? <AvatarContainer /> : null}
      {modal === Modals.mfa ? <MfaContainer /> : null}
    </>
  );
};

export default ModalsContainer;
