import { DashboardTitle, DASHBOARD_TITLES } from 'constants/dashboard';

import TableTitles from 'components/common/table/tableTitles';

import DashboardReferralActivityTable from '../dashboardReferralActivityTable';

import { IDashboardTable } from './types';

const DashboardTable: React.FC<IDashboardTable> = ({ title, onChangeTitle }) => {
  return (
    <section className="mb-4 flex w-full flex-col">
      <TableTitles activeTitle={title} titles={DASHBOARD_TITLES} onChangeTitle={onChangeTitle} />
      {title === DashboardTitle.referralActivity ? <DashboardReferralActivityTable /> : null}
    </section>
  );
};

export default DashboardTable;
