/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { ApolloProvider as Provider, ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { useSession } from 'context/sessionManagementContext/context';

import PageLoader from 'components/common/loaders/pageLoader';

import createClient from './client';

const ApolloProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { refreshTokens, logout } = useSession();

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    const initApp = async () => {
      const newClient = await createClient(refreshTokens, logout);

      setClient(newClient);
    };

    if (!client) {
      initApp();
    }
  }, [client]);

  return client ? <Provider client={client}>{children}</Provider> : <PageLoader />;
};

export default ApolloProvider;
