import { useQuery } from '@apollo/client';
import { useMemo, useState, useEffect, useContext } from 'react';
import { pathOr } from 'ramda';

import { PROFILE } from 'graphql/user';
import { INIT_LIST } from 'graphql/init';
import { SETTINGS } from 'graphql/settings';
import { getAuthToken, getRefreshToken } from 'helpers/auth';
import { IProfile, ISetting } from 'types';
import { IShrinkedToken } from 'requestTypes';
import { TOKENS_LIST } from 'graphql/token';
import ZendeskContext from 'context/contextZendesk/context';

import UserContext from './context';

const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authUser: authZendeskUser } = useContext(ZendeskContext);

  const [profile, setProfile] = useState<IProfile | null>(null);
  const [settings, setSettings] = useState<ISetting[]>([]);
  const [tokensWithNetwork, setTokensWithNetwork] = useState<IShrinkedToken[]>([]);

  const { data, loading } = useQuery(PROFILE, {
    fetchPolicy: 'cache-and-network',
    skip: !(getAuthToken() || getRefreshToken()),
  });
  const { data: settingsData, loading: settingsLoading } = useQuery(SETTINGS, {
    fetchPolicy: 'cache-and-network',
    skip: !(getAuthToken() || getRefreshToken()),
  });
  const { data: tokensData, loading: tokensLoading } = useQuery(TOKENS_LIST, {
    fetchPolicy: 'cache-and-network',
    skip: !(getAuthToken() || getRefreshToken()),
  });

  useQuery(INIT_LIST, { fetchPolicy: 'cache-and-network', skip: !(getAuthToken() || getRefreshToken()) });

  useEffect(() => {
    if (data) {
      authZendeskUser();
      const newProfile = pathOr(null, ['affiliateProfile'], data);

      setProfile(newProfile);
    }
  }, [data]);

  useEffect(() => {
    if (settingsData) {
      const newSettings = pathOr([], ['getSettings'], settingsData);

      setSettings(newSettings);
    }
  }, [settingsData]);

  useEffect(() => {
    if (tokensData) {
      const newTokensWithNetwork = pathOr<IShrinkedToken[]>([], ['tokenWithNetworks'], tokensData);

      setTokensWithNetwork(newTokensWithNetwork);
    }
  }, [tokensData]);

  const userData = useMemo(
    () => ({ profile, settings, tokensWithNetwork, loading: loading || settingsLoading || tokensLoading }),
    [profile, settings, tokensWithNetwork, loading, settingsLoading, tokensLoading],
  );

  return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};

export default UserProvider;
