import { Helmet } from 'react-helmet';

import campaignsIcon from 'assets/nav/campaings.webp';

import PageTitle from 'components/common/text/title/page';
import CampaignsTable from './components/campaignsTable';
import CampaignsCreateNew from './components/campaignsCreateNew';
import CampaignReferralsTable from './components/campaignReferralsTable';

const CampaignsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Campaigns - StarBets Affiliates</title>
      </Helmet>
      <div className="flex h-full w-full flex-grow flex-col">
        <PageTitle title="Campaigns" icon={campaignsIcon} color="green">
          <CampaignsCreateNew />
        </PageTitle>
        <div className="mb-10 flex h-[700px] w-full flex-col">
          <CampaignsTable />
        </div>
        <div className="flex h-[800px] w-full flex-col min-[1400px]:h-[700px]">
          <CampaignReferralsTable />
        </div>
      </div>
    </>
  );
};

export default CampaignsPage;
