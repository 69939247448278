import clsx from 'clsx';
import { useState } from 'react';

import Button from 'components/common/buttons/button';

import styles from './styles.module.scss';
import { ISeoText } from './types';

/* eslint-disable react/no-danger */
const SeoText: React.FC<ISeoText> = ({ text }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <div className="shadow-black flex w-full flex-col items-center rounded-[20px] bg-dark-purple px-8 py-10 max-[670px]:w-4/5 max-[670px]:px-4 max-[670px]:py-5">
      <div className={clsx('relative mb-3 w-full overflow-hidden', hidden ? 'max-h-[200px]' : 'max-h-full')}>
        <div className={styles.wrapper} dangerouslySetInnerHTML={{ __html: text }} />
        {hidden ? (
          <div className="absolute bottom-0 h-[90px] w-full bg-[linear-gradient(0deg,rgba(32,45,74,1)0%,rgba(32,45,74,0)100%)]" />
        ) : null}
      </div>
      <Button
        type="button"
        variant="secondary"
        title={hidden ? 'Show More' : 'Hide'}
        onClick={() => setHidden((h) => !h)}
      />
    </div>
  );
};

export default SeoText;
