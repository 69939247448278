import { getUserAvatar } from 'helpers';

import ProfileDropdownContainer from './components/profileDropdown';

import { IProfile } from './types';

const Profile: React.FC<IProfile> = ({ wrapperRef, alias, avatar, open, onOpen }) => {
  return (
    <article ref={wrapperRef} className="relative flex items-center max-[620px]:hidden">
      <p className="mr-4 text-sm font-bold">{alias}</p>
      <button
        type="button"
        className="bgg-purple shadow-filter sh-purple flex h-[42px] w-[42px] items-center justify-center rounded-full outline-none"
        onClick={onOpen}
      >
        <img className="h-auto w-[38px] rounded-full" src={getUserAvatar(avatar)} alt={`${alias} avatar`} />
      </button>
      {open ? <ProfileDropdownContainer onOpen={onOpen} /> : null}
    </article>
  );
};

export default Profile;
