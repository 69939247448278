import url from 'assets/home/url.webp';
import urlBg from 'assets/home/url-bg.webp';
import invite from 'assets/home/invite.webp';
import inviteBg from 'assets/home/invite-bg.webp';
import rewards from 'assets/home/rewards.webp';
import rewardsBg from 'assets/home/rewards-bg.webp';

import { IFeature } from 'components/pages/home/components/features/components/featuresCard/types';

export const FEATURES: IFeature[] = [
  {
    id: 'features-url',
    icon: url,
    background: urlBg,
    title: 'SHARE URL',
    description: 'Sign up for the StarBets Affiliate Program and start promoting the platform to your audience.',
  },
  {
    id: 'features-invite',
    icon: invite,
    background: inviteBg,
    title: 'INVITE PLAYERS',
    description:
      'Whether you have a website, a blog, or a social media following, you can leverage your influence to refer users to StarBets.',
  },
  {
    id: 'features-rewards',
    icon: rewards,
    background: rewardsBg,
    title: 'Get Rewards',
    description: 'Earn a substantial commission of up to 50% from all users you refer to the platform.',
  },
];
