import { useContext, useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';
import { useFormik } from 'formik';

import { CAMPAIGN_REFERRALS_TABLE_HEAD, ITEMS_PER_PAGE } from 'constants/table';
import { aliasValidationSchema } from 'constants/validation';
import { IAffiliateReferralUser } from 'requestTypes';
import { ChangedDates, IDateRangeParams } from 'components/common/dateRange/types';
import { ITableRow } from 'components/common/table/tableRow/types';
import { AFFILIATE_CAMPAIGN_REFERRALS } from 'graphql/referrals';
import { getAffiliateCampaignReferralVariables, getDateForRequest, getOffset } from 'helpers/request';
import { getAffiliateReferralsRows } from 'helpers/table';
import UserContext from 'context/userContext/context';

import TableContainer from 'components/common/table/tableWithPagination';

import CampaignReferralsFilter from './components/campaignReferralsFilter';

const CampaignReferralsTable: React.FC = () => {
  const { profile } = useContext(UserContext);
  const userCreatedAt = profile?.user.createdAt;
  const initialDateRange = {
    from: userCreatedAt ? getDateForRequest(userCreatedAt) : '',
    to: getDateForRequest(new Date()),
  };

  const [rows, setRows] = useState<ITableRow[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(ITEMS_PER_PAGE);
  const [campaignId, setCampaignId] = useState('');
  const [datesRange, setDatesRange] = useState<IDateRangeParams>(initialDateRange);

  const [alias, setAlias] = useState<string | undefined>('');

  const formik = useFormik({
    initialValues: { alias: '' },
    validationSchema: aliasValidationSchema,
    onSubmit: (v) => setAlias(v.alias),
  });

  const { data, loading, refetch } = useQuery(AFFILIATE_CAMPAIGN_REFERRALS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!formik.values.alias) {
      setAlias(undefined);
    }
  }, [formik.values.alias]);

  useEffect(() => {
    const campaignReferrals = pathOr<IAffiliateReferralUser[]>([], ['affiliateCampaignReferrals', 'items'], data);
    const newCount = pathOr(0, ['affiliateCampaignReferrals', 'total'], data);
    const newActiveCount = pathOr(0, ['affiliateCampaignReferrals', 'active'], data);
    const newRows = getAffiliateReferralsRows(campaignReferrals);

    setRows(newRows);
    setCount(newCount);
    setActiveCount(newActiveCount);
  }, [data]);

  useEffect(() => {
    const offset = getOffset(page, showPerPage);
    const { from, to } = datesRange;
    const filters = {
      offset,
      limit: showPerPage,
      alias: alias || undefined,
      campaignId: !campaignId || campaignId === 'all' ? undefined : campaignId,
    };
    const filtersWithDate = from ? { ...filters, from, to } : filters;

    const variables = getAffiliateCampaignReferralVariables(filtersWithDate);

    refetch(variables);

    setPage(page);
  }, [page, showPerPage, campaignId, alias, datesRange.from, datesRange.to]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangePerPage = (newLimit: string | null) => {
    setShowPerPage(Number(newLimit));
  };

  const handleCampaignChange = (id: string | null) => {
    setCampaignId(id || '');
  };

  const handleDatesChange = (dates: ChangedDates) => {
    const [startDate, endDate] = dates;

    if (startDate === null) {
      setDatesRange(initialDateRange);
      return;
    }

    if (startDate) {
      const from = getDateForRequest(startDate);
      const to = getDateForRequest(endDate || new Date());

      setDatesRange({ from, to });
    }
  };

  return (
    <TableContainer
      headCells={CAMPAIGN_REFERRALS_TABLE_HEAD}
      rows={rows}
      page={page}
      count={count}
      showPerPage={showPerPage}
      loading={loading}
      onChangePage={handleChangePage}
    >
      <CampaignReferralsFilter
        count={count}
        active={activeCount}
        campaign={campaignId}
        alias={formik.values.alias}
        aliasError={formik.errors.alias}
        onCampaignChange={handleCampaignChange}
        showPerPage={showPerPage}
        onChangePerPage={handleChangePerPage}
        onDatesChange={handleDatesChange}
        onAliasChange={(value) => formik.setFieldValue('alias', value)}
        onAliasSubmit={formik.handleSubmit}
      />
    </TableContainer>
  );
};

export default CampaignReferralsTable;
