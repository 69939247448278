import { useState } from 'react';

import HomepageTitle from 'components/common/text/title/homapage';
import FAQArticlesContainer from 'components/common/base/faqArticles';

const FAQ: React.FC = () => {
  const [hidden, setHidden] = useState(false);

  return !hidden ? (
    <section className="container mb-20 flex flex-col items-center">
      <HomepageTitle component="h2">Frequently Asked Questions</HomepageTitle>
      <div className="flex w-full max-[670px]:w-4/5">
        <FAQArticlesContainer sectionName="Affiliates" onHide={(h) => setHidden(h)} />
      </div>
    </section>
  ) : null;
};

export default FAQ;
