import dashboardIcon from 'assets/nav/dashboard.webp';
import campaignsIcon from 'assets/nav/campaings.webp';
import reportsIcon from 'assets/nav/reports.webp';
import accountIcon from 'assets/nav/accountWithBlur.webp';
import transactionsIcon from 'assets/nav/transactionsWithBlur.webp';

export enum Links {
  home = '/',
  dashboard = '/dashboard',
  campaigns = '/campaigns',
  reports = '/reports',
  account = '/account',
  transactions = '/transactions',
  tos = '/terms-of-service',
}

export enum ExternalLinks {
  starbets = 'https://starbets.io/',
  promo = 'https://www.dropbox.com/sh/qs3h4q55mytai3t/AACP8pGdRAPTl5y-vtsAPhz2a?dl=0',
}

export const SIDEBAR_MENU = [
  { id: 'nav-dashboard', link: Links.dashboard, title: 'Dashboard', icon: dashboardIcon },
  { id: 'nav-campaigns', link: Links.campaigns, title: 'Campaigns', icon: campaignsIcon },
  { id: 'nav-reports', link: Links.reports, title: 'Reports', icon: reportsIcon },
];

export const USER_MENU = [
  { id: 'nav-account', link: Links.account, title: 'Account', icon: accountIcon },
  { id: 'nav-transactions', link: Links.transactions, title: 'Transactions', icon: transactionsIcon },
];
