/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { IZendeskContext } from './types';

const ZendeskContext = React.createContext<IZendeskContext>({
  authUser: async () => {},
});

export default ZendeskContext;
