import { twMerge } from 'tailwind-merge';

import { IHomepageDescription } from './types';

const HomepageDescription: React.FC<IHomepageDescription> = ({ classList, children }) => {
  const styles = twMerge('mb-8 w-4/5 text-center text-base font-semibold', classList);

  return <p className={styles}>{children}</p>;
};

export default HomepageDescription;
