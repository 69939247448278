/* eslint-disable no-console */
import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import { CAMPAIGNS, CREATE_CAMPAIGN } from 'graphql/campaigns';
import ModalsContext from 'context/modalsContext/context';

import CreateCampaignModal from './createCampaign';

const CreateCampaignContainer: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [createAffiliateCampaign, { loading }] = useMutation(CREATE_CAMPAIGN, {
    refetchQueries: [CAMPAIGNS],
  });

  const handleChangeName = (newName: string) => {
    if (errorMessage) setErrorMessage('');

    setName(newName);
  };

  const handleSubmit = async () => {
    if (name.length < 6) {
      setErrorMessage('Campaign name must be at least 6 characters');
      return;
    }

    if (name.length > 15) {
      setErrorMessage('Campaign name cannot be more than 15 characters');
      return;
    }

    const variables = { name };

    await createAffiliateCampaign({ variables })
      .then(() => showModal({ modal: undefined }))
      .catch((err) => {
        console.log('[CREATE_CAMPAIGN_ERROR]:', err);

        setErrorMessage(err.message);
      });
  };

  return (
    <CreateCampaignModal
      name={name}
      errorMessage={errorMessage}
      loading={loading}
      onChangeName={handleChangeName}
      onSubmit={handleSubmit}
    />
  );
};

export default CreateCampaignContainer;
