/* eslint-disable no-use-before-define */
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useRef, useState } from 'react';
import { authenticator } from 'otplib';
import QRCode from 'qrcode';
import { pathOr } from 'ramda';

import UserContext from 'context/userContext/context';
import ModalsContext from 'context/modalsContext/context';
import { GOOGLE_SECRET } from 'graphql/auth';
import { IAuthenticatorData } from 'context/modalsContext/types';

import AuthenticatorModal from './authenticator';

const AuthenticatorContainer: React.FC = () => {
  const { profile } = useContext(UserContext);
  const { data } = useContext(ModalsContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const [qrCode, setQRCode] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [saved, setSaved] = useState(false);
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { data: secretData, loading } = useQuery(GOOGLE_SECRET, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (secretData) {
      const secret = pathOr<string | null>(null, ['getGoogleMFASecret', 'recoveryCode'], secretData);

      if (secret) {
        generateQRCode(secret);
        setRecoveryCode(secret);
      }
    }
  }, [secretData]);

  async function generateQRCode(secret: string) {
    const url = window.location.hostname;
    const otpauth = authenticator.keyuri(profile?.user.email || 'Starbets', url, secret);

    const newQR = await QRCode.toDataURL(otpauth).then((res) => res);

    setQRCode(newQR);
  }

  const handleToggleSaved = () => {
    setSaved((s) => !s);
  };

  const handleCodeClick = () => {
    if (inputRef?.current) {
      inputRef.current.select();
    }
  };

  const handleChangeCode = (newCode: string) => {
    setCode(newCode);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(recoveryCode);
  };

  const handleSubmit = async () => {
    const { action } = data as IAuthenticatorData;

    if (action) {
      await action(code, (err) => setErrorMessage(err));
    }
  };

  return (
    <AuthenticatorModal
      inputRef={inputRef}
      qrCode={qrCode}
      recoveryCode={recoveryCode}
      saved={saved}
      code={code}
      errorMessage={errorMessage}
      loading={loading}
      onToggleSaved={handleToggleSaved}
      onChangeCode={handleChangeCode}
      onCodeClick={handleCodeClick}
      onCopy={handleCopy}
      onSubmit={handleSubmit}
    />
  );
};

export default AuthenticatorContainer;
