import { Helmet } from 'react-helmet';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from 'context/userContext/context';
import { HOMEPAGE_SEO_TEXT } from 'constants/seo';
import { Links } from 'constants/nav';

import SeoText from 'components/common/seo/seoText';
import Footer from 'components/common/base/footer';

import AffiliatesProgram from './components/affiliatesProgram';
import CommissionRates from './components/commissionRates';
import FAQ from './components/faq';
import Features from './components/features';
import Invitation from './components/invitation';
import Testimonials from './components/testimonials';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const { profile } = useContext(UserContext);

  useEffect(() => {
    if (profile) {
      navigate(Links.dashboard);
    }
  }, [profile]);

  return (
    <>
      <Helmet>
        <title>Crypto Casino Affiliate Program @ StarBets</title>
        <meta
          name="description"
          content="Do you have crypto gambling traffic? Become an affiliate and earn generous commission rates of up to 50%. Join now!"
        />
      </Helmet>
      <div className="flex w-full flex-col">
        <AffiliatesProgram />
        <Features />
        <CommissionRates />
        <section className="container mb-20 flex flex-col items-center">
          <SeoText text={HOMEPAGE_SEO_TEXT} />
        </section>
        <Testimonials />
        <Invitation />
        <FAQ />
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
