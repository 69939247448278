import ReCAPTCHA from 'react-google-recaptcha';

import config from 'constants/config';

import Modal from 'components/common/base/modal';
import Button from 'components/common/buttons/button';
import Input from 'components/common/inputs/input';
import Loader from 'components/common/loaders/loader';

import { IForgotPasswordModal } from './types';

const { captchaKey } = config;

const ForgotPasswordModal: React.FC<IForgotPasswordModal> = ({
  recaptcha,
  email,
  errorMessage,
  loading,
  onChangeEmail,
  onResetPassword,
}) => {
  return (
    <Modal title="Forgot Password" titleClassName="max-[1100px]:text-3xl" modalClassName="w-[500px]">
      <div className="relative mx-auto flex w-4/5 flex-col items-center overflow-hidden pb-4">
        {loading ? <Loader /> : null}
        <Input
          autoComplete="username"
          type="email"
          placeholder="Email"
          label="Email:"
          labelAlign="center"
          lableWrapperClassList="mb-12"
          value={email}
          errorMessage={errorMessage}
          onKeyDown={(ev) => (ev.key === 'Enter' ? onResetPassword() : null)}
          onChange={(ev) => onChangeEmail(ev.target.value)}
        />
        <Button
          type="button"
          variant="secondary"
          title="Reset Password"
          disabled={Boolean(errorMessage)}
          onClick={onResetPassword}
        />
      </div>
      <ReCAPTCHA ref={recaptcha} size="invisible" sitekey={captchaKey} />
    </Modal>
  );
};

export default ForgotPasswordModal;
