/* eslint-disable react/destructuring-assignment */
import { components } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CrossIcon } from 'assets/common/cross.svg';

import { TSelecClear } from './types';

const { ClearIndicator } = components;

const SelectClear: React.FC<TSelecClear> = ({ ...props }) => {
  return (
    <ClearIndicator {...props} className={twMerge(props.className)}>
      <CrossIcon className="h-auto w-[20px] cursor-pointer fill-[currentColor]" />
    </ClearIndicator>
  );
};

export default SelectClear;
