import { ReactComponent as GroupIcon } from 'assets/common/users.svg';
import { ReactComponent as ChannelIcon } from 'assets/common/share.svg';
import { ReactComponent as TelegramIcon } from 'assets/common/telegram.svg';
import { ReactComponent as SkypeIcon } from 'assets/common/skype.svg';

import { AFFILIATES_CONTACT_TYPES, AFFILIATES_TYPES } from 'constants/auth';

import Input from 'components/common/inputs/input';
import Button from 'components/common/buttons/button';
import Loader from 'components/common/loaders/loader';
import Select from 'components/common/select';
import ErrorMessage from 'components/common/text/errorMessage';

import { IRegistrationSecondStep } from './types';

const RegistrationSecondStep: React.FC<IRegistrationSecondStep> = ({ formik, loading, errorMessage, onSubmit }) => {
  return (
    <>
      {loading ? <Loader className="rounded-r-[20px]" /> : null}
      <h3 className="mb-2 text-3xl font-bold uppercase">Join Now</h3>
      <p className="mb-4 text-sm font-semibold text-light-blue">Step 2/2</p>
      <form className="flex w-full flex-grow flex-col" onSubmit={onSubmit}>
        <Input
          formik={formik}
          autoComplete="given-name"
          name="name"
          type="text"
          placeholder="Name"
          label="Name:"
          labelAlign="center"
          value={formik.values.name}
          errorMessage={formik.errors?.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <Select
          className="!mb-2"
          containerClassName="!pl-10"
          placeholderIconClassName="left-4"
          label="Type:"
          labelAlign="center"
          placeholder="Type"
          placeholderIcon={GroupIcon}
          selected={formik.values.type}
          options={AFFILIATES_TYPES}
          errorMessage={formik.errors?.type}
          onSelect={(v) => formik.setFieldValue('type', v)}
        />
        <Input
          formik={formik}
          name="website"
          type="text"
          placeholder="Channel"
          label="Website / Channel (URL):"
          labelAlign="center"
          icon={ChannelIcon}
          value={formik.values.website}
          errorMessage={formik.errors?.website}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <div className="flex w-full flex-col items-center">
          <p className="mb-2 text-sm font-medium text-light-gray">How can we contact you?</p>
          <div className="flex w-full items-center">
            <Select
              className="!mr-2 w-full max-w-[150px]"
              containerClassName="!pl-4"
              optionClassName="!px-4"
              placeholder="Type"
              options={AFFILIATES_CONTACT_TYPES}
              selected={formik.values.contactType}
              errorMessage={formik.errors.contact}
              onlyHighlightError
              onSelect={(v) => formik.setFieldValue('contactType', v)}
            />
            <Input
              formik={formik}
              name="contact"
              type="text"
              placeholder="Username"
              className="pr-2"
              icon={formik.values.contactType === 'Telegram' ? TelegramIcon : SkypeIcon}
              value={formik.values.contact}
              errorMessage={formik.errors.contact}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              withoutErrorMessage
            />
          </div>
          <ErrorMessage className="mb-2 mt-1 px-4" message={formik.errors.contact} />
        </div>
        <ErrorMessage message={errorMessage} />
        <Button
          title="Complete Registration"
          type="submit"
          wrapperClassList="mt-auto w-full"
          buttonsClassList="w-full"
          variant="main"
        />
      </form>
    </>
  );
};

export default RegistrationSecondStep;
