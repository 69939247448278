/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import UserContext from 'context/userContext/context';
import ModalsContext from 'context/modalsContext/context';
import { AVATARS, PROFILE, UPDATE_AVATAR } from 'graphql/user';
import { AvatarType, IAvatar, IRank } from 'types';
import { RANKS } from 'graphql/ranks';

import AvatarModal from './avatar';

const AvatarContainer = () => {
  const { profile } = useContext(UserContext);
  const { showModal } = useContext(ModalsContext);

  const [type, setType] = useState(AvatarType.default);
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [defaultAvatars, setDefaultAvatars] = useState<IAvatar[]>([]);
  const [premiumAvatars, setPremiumAvatars] = useState<IAvatar[]>([]);
  const [rankWithAvatar, setRankWithAvatar] = useState<IRank>();
  const [premiumAvatarsDisabled, setPremiumAvatarsDisabled] = useState(false);

  const { data, loading } = useQuery(AVATARS, { fetchPolicy: 'cache-and-network' });
  const { data: ranksData, loading: ranksLoading } = useQuery(RANKS, { fetchPolicy: 'cache-and-network' });
  const [updateAvatar, { loading: updateLoading }] = useMutation(UPDATE_AVATAR, {
    fetchPolicy: 'no-cache',
    refetchQueries: [PROFILE],
  });

  useEffect(() => {
    if (data) {
      const avatars = pathOr<IAvatar[]>([], ['avatars'], data);

      const newDefaultAvatars = avatars.filter((a) => a.type === AvatarType.default);
      const newPremiumAvatars = avatars.filter((a) => a.type === AvatarType.premium);

      setDefaultAvatars(newDefaultAvatars);
      setPremiumAvatars(newPremiumAvatars);
    }
  }, [data]);

  useEffect(() => {
    if (ranksData) {
      const ranks = pathOr<IRank[]>([], ['ranks'], ranksData);
      const sortedRanks = [...ranks].sort((a, b) => a.id - b.id);

      const newRankWithAvatar = sortedRanks.find((r) => r.avatars);

      setRankWithAvatar(newRankWithAvatar);
    }
  }, [ranksData]);

  useEffect(() => {
    const userAvatar = profile?.user?.avatar?.avatar;

    const currentAvatar =
      defaultAvatars.find((a) => a.avatar === userAvatar) || premiumAvatars.find((a) => a.avatar === userAvatar);

    if (currentAvatar) {
      setSelectedAvatar(currentAvatar.id!);
    }
  }, [profile, defaultAvatars, premiumAvatars]);

  useEffect(() => {
    if (profile && rankWithAvatar) {
      const { user } = profile;

      setPremiumAvatarsDisabled(user.rankId < rankWithAvatar.id);
    }
  }, [profile, rankWithAvatar]);

  const handleChangeType = (newType: AvatarType) => {
    setType(newType);
  };

  const handleChangeSelectedAvatar = (newAvatar: string) => {
    setSelectedAvatar(newAvatar);
  };

  const handleSubmit = async () => {
    const variables = { data: { avatarId: selectedAvatar } };

    await updateAvatar({ variables })
      .then(() => showModal({ modal: undefined }))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[UPDATE_AVATAR_ERROR]: ', err);
      });
  };

  return (
    <AvatarModal
      type={type}
      avatars={type === AvatarType.default ? defaultAvatars : premiumAvatars}
      selectedAvatar={selectedAvatar}
      premiumAvatarDisabled={premiumAvatarsDisabled}
      premiumAvatarRank={rankWithAvatar?.id || 1}
      loading={loading || ranksLoading || updateLoading}
      onChangeType={handleChangeType}
      onChangeSelectedAvatar={handleChangeSelectedAvatar}
      onSubmit={handleSubmit}
    />
  );
};

export default AvatarContainer;
