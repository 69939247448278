/* eslint-disable react/destructuring-assignment */
import { useState, useEffect } from 'react';
import { components } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { TSelectControl } from './types';

const { Control } = components;

const SelectControl: React.FC<TSelectControl> = ({ children, ...props }) => {
  const [icon, setIcon] = useState('');
  const [label, setLabel] = useState('');

  useEffect(() => {
    const options = props.getValue();

    if (options.length) {
      const option = options[0];

      setIcon(option?.icon || '');
      setLabel(option?.label || '');
    } else {
      setIcon('');
      setLabel('');
    }
  }, [props]);

  return (
    <Control {...props} className={twMerge(props.className, '!relative')}>
      {icon ? <img src={icon} className="absolute left-4 h-auto w-[32px]" alt={label} /> : null}
      {children}
    </Control>
  );
};

export default SelectControl;
