import { Routes, Route, Navigate } from 'react-router-dom';

import { Links } from 'constants/nav';

import AuthLayout from 'containers/authLayout';
import HomePage from 'components/pages/home';
import DashboardPage from 'components/pages/dashboard';
import TransactionsPage from 'components/pages/transactions';
import CampaignsPage from 'components/pages/campaigns';
import TermsOfServicePage from 'components/pages/termsOfService';
import AccountPage from 'components/pages/account';

const Router = () => {
  return (
    <Routes>
      <Route path={Links.home} element={<HomePage />} />
      <Route path={Links.tos} element={<TermsOfServicePage />} />
      <Route element={<AuthLayout />}>
        <Route path={Links.account} element={<AccountPage />} />
        <Route path={Links.dashboard} element={<DashboardPage />} />
        <Route path={Links.transactions} element={<TransactionsPage />} />
        <Route path={Links.campaigns} element={<CampaignsPage />} />
      </Route>
      <Route path="*" element={<Navigate to={Links.home} />} />
    </Routes>
  );
};

export default Router;
