import { gql } from '@apollo/client';

export const EXCHANGE_RATES_SETTINGS = gql`
  query exchangeRatesSettings {
    exchangeRates {
      usdValue
      token {
        displayName
        tokenCode
      }
    }
  }
`;

export const TOKENS_LIST = gql`
  query tokenWithNetworks {
    tokenWithNetworks {
      tokenCode
      displayName
      name
      networks {
        minimumDeposit
        minimumWithdrawal
        withdrawalFee
        network {
          code
        }
      }
    }
  }
`;

export const NETWORKS = gql`
  query networks {
    networks {
      code
      explorer
      name
      standard
    }
  }
`;

export const ACTIVE_TOKENS = gql`
  query activeTokens {
    activeTokens {
      tokenCode
      displayName
      weight
    }
  }
`;
