/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { EMAIL_REGEX } from 'constants/index';

import { IErrorMessage } from './types';

const ErrorMessage: React.FC<IErrorMessage> = ({ message = '', className = '' }) => {
  const [body, setBody] = useState<string>('');

  useEffect(() => {
    if (EMAIL_REGEX.test(message)) {
      const newBody = message.replace(EMAIL_REGEX, (v) => {
        return `<a href="${`mailto:${v}`}"><b>${v}</b></a>`;
      });

      setBody(newBody);
      return;
    }

    setBody(message);
  }, [message]);

  return message ? (
    <p className={twMerge('mt-2 text-center text-xs text-red', className)} dangerouslySetInnerHTML={{ __html: body }} />
  ) : null;
};

export default ErrorMessage;
