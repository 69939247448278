import { twMerge } from 'tailwind-merge';

import { ReactComponent as RocketIcon } from 'assets/common/rocket.svg';

import { ILoader } from './types';
import styles from './styles.module.scss';

const Loader: React.FC<ILoader> = ({ bg = 'default', className = '' }) => {
  const wrapper = twMerge(
    'absolute left-0 top-0 h-full w-full z-10 flex items-center justify-center',
    bg === 'default' ? 'bg-dark-purple' : '',
    bg === 'blue' ? 'bg-dark-blue' : '',
    bg === 'transparent' ? 'bg-transparent' : '',
    className,
  );

  return (
    <div className={wrapper}>
      <div className={styles.loader}>
        <div className={styles.face}>
          <div className={styles.circle}>
            <RocketIcon className={styles.rocket} />
          </div>
        </div>
        <div className={styles.face}>
          <div className={styles.circle}>
            <RocketIcon className={styles.rocket} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
