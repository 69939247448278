import Big from 'big.js';

import { IAffiliateBalance, IExchangeRate, IToken, IWallet, TokenCode } from 'types';
import { COLLECT_ALL_OPTION } from 'constants/index';
import { ISelectOption } from 'components/common/select/types';
import { IShrinkedToken } from 'requestTypes';

import { getCurrencyIcon, getFormattedAmount } from './index';

export interface IBalance {
  amount: string;
  displayName: string;
  tokenCode: string;
}

export const getDefaultBalances = (tokens: IShrinkedToken[], balances: IAffiliateBalance[]) => {
  return tokens.map<IAffiliateBalance>((t) => {
    const balance = balances.find((b) => b.token.tokenCode === t.tokenCode);

    return {
      availableBalance: balance?.availableBalance || '0',
      totalCollected: balance?.totalCollected || '0',
      token: {
        displayName: t.displayName,
        tokenCode: t.tokenCode,
      },
    };
  });
};

export const getAffiliateBalances = (balances: IAffiliateBalance[], withStarToken = false) => {
  return balances.reduce<{ available: IBalance[]; collected: IBalance[] }>(
    (result, balance) => {
      if (!withStarToken && balance.token.tokenCode === TokenCode.STAR) return result;

      const { tokenCode, displayName } = balance.token;

      const availableBalance = Big(balance.availableBalance).lt(0) ? '0' : balance.availableBalance;

      result.available.push({ amount: availableBalance, tokenCode, displayName });
      result.collected.push({ amount: balance.totalCollected, tokenCode, displayName });

      return result;
    },
    {
      available: [],
      collected: [],
    },
  );
};

export const convertTokensToUSD = (balances: IBalance[], exchangeRates: IExchangeRate[]): string => {
  const balance = balances.reduce<string>((result, b) => {
    const currentAmout = String(b.amount);
    const exchangeRate = exchangeRates.find((r) => r.token.tokenCode === b.tokenCode);
    const rate = exchangeRate?.usdValue || '0';
    const usdAmount = Big(currentAmout).mul(rate).toFixed(10);

    return Big(result).plus(usdAmount).toFixed(10);
  }, '0');

  return getFormattedAmount(balance);
};

const getConvertedTokenAmountWithFee = (
  fromTokenCode: string,
  toTokenCode: string,
  amount: string,
  fee: number,
  exchangeRates: IExchangeRate[],
): string => {
  const exchangeRateFrom = exchangeRates.find((r) => r.token.tokenCode === fromTokenCode);
  const exchangeRateTo = exchangeRates.find((r) => r.token.tokenCode === toTokenCode);

  const rateFrom = exchangeRateFrom?.usdValue || '0';
  const rateTo = exchangeRateTo?.usdValue || '1';

  const rate = Big(rateFrom).div(rateTo).toFixed(8, Big.roundDown);
  const convertedAmount = Big(amount).mul(rate).toFixed(8, Big.roundDown);

  return Big(convertedAmount)
    .mul(1 - fee)
    .toFixed(8, Big.roundDown);
};

export const getConvertedTokenAmount = (
  balances: IBalance[],
  tokenCode: string,
  fee: string,
  exchangeRates: IExchangeRate[],
): string => {
  const conversionFee = Big(fee || '0')
    .div(100)
    .toNumber();

  const totalCollectionAmount = balances.reduce<string>((result, b) => {
    const zeroBalance = Big(b.amount).lte(0);

    if (zeroBalance) return result;

    const amount = getFormattedAmount(b.amount, 8, false);

    if (b.tokenCode === tokenCode) {
      return Big(result).plus(amount).toFixed(8, Big.roundDown);
    }

    const convertedAmount = getConvertedTokenAmountWithFee(
      b.tokenCode,
      tokenCode,
      amount,
      conversionFee,
      exchangeRates,
    );

    return Big(result).plus(convertedAmount).toFixed(8, Big.roundDown);
  }, '0');

  return totalCollectionAmount;
};

export const getBalanceOptions = (
  balances: IBalance[],
  fee: string,
  exchangeRates: IExchangeRate[],
  withCollectAll = true,
  withCoversion = true,
) => {
  const options = balances
    .map<ISelectOption | undefined>((b) => {
      const { amount, tokenCode, displayName } = b;

      if (tokenCode === TokenCode.STAR) return undefined;

      const convertedAmount = withCoversion ? getConvertedTokenAmount(balances, tokenCode, fee, exchangeRates) : amount;
      const formattedAmount = getFormattedAmount(convertedAmount);

      return {
        label: `${formattedAmount} ${displayName}`,
        value: b.tokenCode,
        icon: getCurrencyIcon(displayName),
      };
    })
    .filter(Boolean) as ISelectOption[];

  return withCollectAll ? [COLLECT_ALL_OPTION, ...options] : options;
};

export const getWalletBalance = (wallets: IWallet[]): IBalance[] => {
  return wallets.map((wallet) => {
    const { availableBalance, token } = wallet;

    return {
      amount: availableBalance,
      tokenCode: token.tokenCode,
      displayName: token.displayName,
    };
  });
};

export const getDefaultCurrenciesList = (tokens: IToken[], withStarToken = false): IBalance[] => {
  return tokens
    .filter((t) => (withStarToken ? t : t.tokenCode !== TokenCode.STAR))
    .map((token) => {
      const { tokenCode, displayName } = token;

      return {
        amount: getFormattedAmount('0'),
        tokenCode,
        displayName,
      };
    });
};

export const getConvertedCurrency = (
  tokenCode: string,
  balances: IBalance[],
  fee: string,
  exchangeRates: IExchangeRate[],
): IBalance => {
  const currency = balances.find((b) => b.tokenCode === tokenCode);

  const convertedAmount = getConvertedTokenAmount(balances, currency?.tokenCode || '', fee, exchangeRates);
  const convertedCurrency: IBalance = {
    amount: convertedAmount,
    tokenCode: currency?.tokenCode || '',
    displayName: currency?.displayName || '',
  };

  return convertedCurrency;
};
