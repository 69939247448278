/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';

import { ITableTitles } from './types';

const TableTitles: React.FC<ITableTitles> = ({ activeTitle, titles, onChangeTitle }) => {
  return (
    <ul className="mb-6 flex items-center">
      {titles.map((t) => (
        <li
          key={t}
          className={clsx(
            'mr-4 rounded-[20px] px-4 py-2 selection:pointer-events-none last:mr-0',
            titles.length > 1 ? 'cursor-pointer' : 'cursor-default',
            activeTitle === t ? 'bgg-purple' : '',
          )}
          onClick={titles.length > 1 ? () => onChangeTitle(t) : undefined}
        >
          {t}
        </li>
      ))}
    </ul>
  );
};

export default TableTitles;
