export enum Settings {
  maintenanceMode = 'maintenance_mode',
  bonusMinDeposit = 'bonus_min_deposit',
  bonusMaxBonus = 'bonus_max_bonus',
  bonusDuration = 'bonus_duration',
  bonusWagerMultiplier = 'bonus_wager_multiplier',
  wheelStatus = 'wheel_status',
  wheelDuration = 'wheel_duration',
  wheelStyle = 'wheel_style',
  wheelBackgroundColor = 'wheel_background_color',
  wheelButtonColor = 'wheel_button_color',
  wheelHistoryButtonColor = 'wheel_history_button_color',
  miningDiscount = 'mining_discount',
  bonusRules = 'bonus_rules',
  bonusDate = 'bonus_date',
  bonusWithExistingDeposit = 'bonus_with_existing_deposit',
  starPoolBurnAmount = 'star_pool_burn_amount',
  starPoolLimit = 'star_pool_limit',
  starPoolAdjust = 'star_pool_adjust',
  conversionFee = 'conversion_fee',
  defaultAvatar = 'default_avatar',
  changeAliasDelay = 'change_alias_delay',
  withdrawal = 'withdrawal',
}

export enum TokenCode {
  STAR = 'STAR',
  BTC = 'BTC',
  TRX = 'TRX',
  BNB = 'BNB',
  BSC = 'BSC',
  ETH = 'ETH',
  USDT = 'USDT',
  LTC = 'LTC',
  XRP = 'XRP',
}

export enum AffiliateType {
  default = '',
  webmaster = 'Webmaster',
  creator = 'Content Creator',
}

export enum AffiliateContactType {
  telegram = 'Telegram',
  skype = 'Skype',
}

export enum UserStatus {
  active = 'Active',
  deleted = 'Deleted',
}

export enum AvatarStatus {
  active = 'Active',
  inactive = 'Inactive',
}

export enum AvatarType {
  default = 'Default',
  premium = 'Premium',
}

export enum UserPreferenceKey {
  promoEmail = 'promoEmail',
  withdrawal = 'withdrawal',
  banned = 'banned',
  language = 'language',
  preferredToken = 'preferredToken',
  enableBet = 'enableBet',
}

export enum TransactionStatus {
  complete = 'Complete',
  failed = 'Failed',
  pending = 'Pending',
}

export enum TransactionType {
  any = 'Any',
  deposit = 'Deposit',
  withdrawal = 'Withdrawal',
  dividends = 'Dividends',
  referral = 'Referral',
  bonus = 'Bonus',
  cashback = 'Cashback',
  rankBonus = 'Rank Bonus',
  event = 'Event',
  affiliate = 'Affiliate',
  lottery = 'Lottery',
  tips = 'Tips',
  wheelOfFortune = 'Wheel',
  burn = 'Burn',
}

export enum ActivityType {
  createAccount = 'Register',
  login = 'Login',
  sendVerificationEmail = 'Email Verification',
  resetPassword = 'Reset Password',
  createBet = 'Create Bet',
  changePassword = 'Change Password',
}

export enum ActivityStatus {
  completed = 'Completed',
  failed = 'Failed',
}

export enum ActivityDevice {
  console = 'Console',
  mobile = 'Mobile',
  tablet = 'Tablet',
  smarttv = 'Smart TV',
  wearable = 'Wearable',
  embedded = 'Embedded',
}

export type AffiliatePercentage = '10' | '15' | '20' | '25' | '30' | '35' | '40' | '45' | '50';

export type SVGIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export interface IZendeskSection {
  id: number;
  name: string;
  description: string;
  category_id: number;
  theme_template: string;
  created_at: string;
  updated_at: string;
  outdated: boolean;
  position: number;
  sorting: string;
  locale: 'en-us';
  source_locale: 'en-us';
  url: string;
  html_url: string;
  parent_section_id: number | null;
}

export interface IZendeskArticle {
  id: number;
  section_id: number;
  name: string;
  title: string;
  body: string;
  author_id: number;
  created_at: string;
  edited_at: string;
  updated_at: string;
  comments_disabled: boolean;
  content_tag_ids: number[];
  permission_group_id: number;
  url: string;
  html_url: string;
  label_names: string[];
  position: number;
  promoted: boolean;
  draft: boolean;
  outdated: boolean;
  locale: 'en-us';
  source_locale: 'en-us';
  outdated_locales: string[];
  user_segment_id: number | null;
  vote_count: number;
  vote_sum: number;
}

export interface IRank {
  id: number;
  wager: string;
  cashback: string;
  bonus: string;
  avatars: null | boolean;
  rakeback: null | boolean;
  tips: null | boolean;
  vault: null | boolean;
  vip: null | boolean;
}

export interface IAvatar {
  id?: string;
  avatar: string;
  rank?: number;
  status?: AvatarStatus;
  type?: AvatarType;
  weight?: number;
}

export interface IUserPreferences {
  accountActivityAlert: boolean;
  newsEmail: boolean;
  promoEmail: boolean;
}

export interface IAffiliate {
  email: string;
  userId: string;
  percentage: AffiliatePercentage;
  websites: string[] | null;
}

export interface IUser {
  id: string;
  alias: string;
  email?: string;
  telegramId: string;
  referralCode: string;
  totalWager: string;
  totalMined: string;
  totalPayout: string;
  totalBets: number;
  createdAt: string;
  rankId: number;
  avatar?: IAvatar | null;
  preferences?: IUserPreferences[] | null;
  roles: string[] | null;
  affiliate?: IAffiliate | null;
  status: UserStatus;
  lastLoginDate: string;
}

export interface IUserSecurity {
  google_2fa: boolean;
  email_2fa: boolean;
  requireLogin_2fa: boolean;
}

export interface IProfile {
  name: string;
  percentage: string;
  user: IUser;
}

export interface IToken {
  displayName: string;
  tokenCode: TokenCode;
  name: string;
  isNative: boolean;
  weight?: number;
}

export interface INetwork {
  code: string;
  explorer: string;
  name: string;
  standard: string;
}

export interface ITransaction {
  id: string;
  amount: string;
  createdAt: string;
  info: string;
  status: TransactionStatus;
  token: IToken;
  type: TransactionType;
  meta?: object;
  network: null | Partial<INetwork>;
  user?: IUser;
}

export interface IExchangeRate {
  token: {
    tokenCode: string;
  };
  usdValue: string;
}

export interface IWallet {
  availableBalance: string;
  token: {
    tokenCode: string;
    displayName: string;
  };
}

export interface IAffiliateBalance {
  availableBalance: string;
  totalCollected: string;
  token: {
    displayName: string;
    tokenCode: string;
  };
}

export interface ISetting {
  name: string;
  value: string;
}

export interface IAddressBook {
  id: string;
  address: string;
  isWhitelisted: boolean;
  name: string;
  network: INetwork;
  token: IToken;
  memo?: string;
}

export interface ITestimonial {
  id: string;
  logo: string;
  testimonial: string;
  website: string;
}

export interface IAffiliateCampaign {
  id: string;
  name: string;
  code: string;
  createdAt: string;
  registrations: number;
}

export interface IAffiliateCampaignWallet {
  tokenCode: string;
  totalWager: string;
}
