import { gql } from '@apollo/client';

export const RANKS = gql`
  query ranks {
    ranks {
      cashback
      avatars
      bonus
      id
      tips
      rakeback
      vault
      vip
      wager
    }
  }
`;
