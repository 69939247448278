/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import logoPlaceholder from 'assets/common/logo-placeholder.webp';

import Box from 'components/common/content/box';

import { ITestimonialComponent } from './types';

const Testimonial: React.FC<ITestimonialComponent> = ({ testimonial }) => {
  return (
    <Box
      component="li"
      className="mb-4 mr-4 flex min-w-[calc(50%-1rem)] max-w-[calc(50%-1rem)] flex-grow flex-col items-center even:mr-0 max-[670px]:w-4/5 max-[670px]:min-w-full max-[670px]:max-w-full"
    >
      <a className="mb-4" href={testimonial.website} target="_blank" rel="noreferrer">
        <img
          className="h-auto w-[120px]"
          src={testimonial.logo}
          alt={testimonial.testimonial}
          onError={(ev) => ((ev.target as HTMLImageElement).src = logoPlaceholder)}
        />
      </a>
      <p className="px-4 text-center text-sm font-medium">{testimonial.testimonial}</p>
    </Box>
  );
};

export default Testimonial;
