import AuthCode from 'react-auth-code-input';
import { twMerge } from 'tailwind-merge';

import { IVerificationCode } from './types';

const VerificationCode: React.FC<IVerificationCode> = ({ className = '', onChangeCode }) => {
  const wrapperStyles = twMerge('mx-auto', className);
  const inputStyles = twMerge(
    'mr-2 h-[40px] w-[40px] rounded-[10px] border-light-purple border-2 bg-dark-blue\
     text-center outline-none hover:border-purple hover:border-opacity-70 focus:border-purple\
     last:mr-0 max-[385px]:w-[35px] max-[385px]:h-[35px] max-[385px]:mr-1 ',
  );

  return (
    <div className={wrapperStyles}>
      <AuthCode
        length={6}
        allowedCharacters="numeric"
        autoFocus
        containerClassName="flex w-full items-center"
        inputClassName={inputStyles}
        onChange={onChangeCode}
      />
    </div>
  );
};

export default VerificationCode;
