import { useContext, useEffect, useState } from 'react';

import ModalsContext from 'context/modalsContext/context';
import { ISuccessModalData } from 'context/modalsContext/types';

import SuccessModal from './success';

const SuccessContainer: React.FC = () => {
  const { data, showModal } = useContext(ModalsContext);

  const [text, setText] = useState('');

  useEffect(() => {
    if (data) {
      const { message } = data as ISuccessModalData;

      setText(message);
    }
  }, [data]);

  const handleClose = () => {
    showModal({ modal: undefined });
  };

  return <SuccessModal text={text} onClose={handleClose} />;
};

export default SuccessContainer;
