import DashboardOverviewAvailableBalance from '../dashboardOverviewAvailableBalance';
import DashboardOverviewWallet from '../dashboardOverviewWallet';

const DashboardWalletOverview: React.FC = () => {
  return (
    <section className="mb-6 flex max-w-full flex-grow max-[870px]:flex-col">
      <div className="shadow-black mr-4 max-w-[calc(50%-0.5rem)] flex-grow max-[870px]:mb-4 max-[870px]:mr-0 max-[870px]:max-w-full">
        <DashboardOverviewAvailableBalance />
      </div>
      <div className="shadow-black h-full max-w-[calc(50%-0.5rem)] flex-grow max-[870px]:max-w-full">
        <DashboardOverviewWallet />
      </div>
    </section>
  );
};

export default DashboardWalletOverview;
