/* eslint-disable react/jsx-no-useless-fragment */
import { twMerge } from 'tailwind-merge';

import Loader from 'components/common/loaders/loader';

import TableHeader from './tableHeader';
import TableRow from './tableRow';

import { ITable } from './types';

const Table: React.FC<ITable> = ({ headCells, rows, loading, children }) => {
  return (
    <div className="shadow-black relative flex h-full w-full flex-grow flex-col overflow-hidden rounded-[16px] bg-dark-purple p-6 max-[450px]:p-3">
      {children ? <>{children}</> : null}
      <div className="relative flex h-full w-full flex-grow flex-col overflow-hidden overflow-y-auto">
        <div className="absolute left-0 top-0 z-[2] h-6 w-full rounded-t-[16px] bg-dark-purple" />
        <table className={twMerge('w-full table-fixed border-collapse border-spacing-0', loading ? 'h-full' : '')}>
          <TableHeader headCells={headCells} />
          <tbody className="relative w-full">
            {rows.map((r) => (
              <TableRow key={r.id} {...r} />
            ))}
            {!rows.length ? (
              <tr className="relative min-h-[100px]">
                <td>
                  <div className="pointer-events-none absolute left-0 flex h-[70px] w-full items-center justify-center pt-8">
                    <span className="text-sm font-bold text-light-blue selection:pointer-events-none">
                      No Entries Found
                    </span>
                  </div>
                </td>
              </tr>
            ) : null}
            {loading ? (
              <tr>
                <td>
                  <Loader />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
