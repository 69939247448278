import { gql } from '@apollo/client';

export const REGISTRATION = gql`
  mutation createAffiliateAccount($data: CreateAffiliateAccountDto!) {
    createAffiliateAccount(data: $data)
  }
`;

export const LOGIN = gql`
  query affiliateLogin($email: String!, $password: String!, $captcha: String!) {
    affiliateLogin(email: $email, password: $password, captcha: $captcha) {
      ... on TokenPayload {
        authToken
        refreshToken
      }
      ... on VerifyPayload {
        shortLivedToken
        mfaType
      }
    }
  }
`;

export const VERIFY_LOGIN = gql`
  query loginVerify($shortLivedToken: String!, $securityCode: String) {
    loginVerify(shortLivedToken: $shortLivedToken, securityCode: $securityCode, emailOtp: $securityCode) {
      authToken
      refreshToken
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($email: String!, $password: String!, $code: String!) {
    verifyEmail(email: $email, password: $password, code: $code) {
      authToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshTokens($refreshToken: String!) {
    refreshTokens(refreshToken: $refreshToken) {
      authToken
      refreshToken
    }
  }
`;

export const SEND_OTP_TO_EMAIL = gql`
  query sendOtpToEmail {
    sendOtpToEmail {
      status
    }
  }
`;

export const SEND_EMAIL_VERIFICATION_OTP = gql`
  query ($email: String!) {
    sendEmailVerificationOtp(email: $email) {
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  query resetPassword($email: String!, $captcha: String!) {
    resetPassword(email: $email, captcha: $captcha) {
      status
    }
  }
`;

export const USER_SECURITY = gql`
  query {
    userSecurity {
      google_2fa
      email_2fa
      requireLogin_2fa
    }
  }
`;

export const UPDATE_LOGIN_REQUIRE_MFA = gql`
  mutation updateLoginRequireMfa($isRequired: Boolean!, $code: String) {
    updateLoginRequireMfa(isRequired: $isRequired, securityCode: $code, emailOtp: $code) {
      status
    }
  }
`;

export const ENABLE_GOOGLE_MFA = gql`
  mutation enableGoogleMFA($code: String!) {
    enableGoogleMFA(code: $code) {
      message
    }
  }
`;

export const GOOGLE_SECRET = gql`
  query getGoogleMFASecret {
    getGoogleMFASecret {
      recoveryCode
    }
  }
`;

export const ENABLE_EMAIL_MFA = gql`
  mutation enableEmailMFA($code: String!) {
    enableEmailMFA(code: $code) {
      message
    }
  }
`;

export const DISABLE_MFA = gql`
  mutation disableMFA($code: String!) {
    disableMFA(securityCode: $code)
  }
`;
