/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import Big from 'big.js';
import { twMerge } from 'tailwind-merge';

import { RATES, RATES_CLASSES } from 'constants/index';
import { formatUSDValue } from 'helpers';

import styles from './styles.module.scss';
import { IRates } from './types';

const Rates: React.FC<IRates> = ({ listRef, showItems, isMobileView }) => {
  const wrapperStyles = twMerge(
    'flex w-full flex-col items-center overflow-hidden max-[720px]:flex-row-reverse max-[720px]:justify-center',
  );
  const listStyles = twMerge(
    'mb-2 flex w-[88%] items-end max-[720px]:mb-0 max-[720px]:ml-4 max-[720px]:w-max max-[720px]:flex-col max-[720px]:items-start',
  );
  const itemStyles = twMerge(
    'group flex flex-col items-center max-[720px]:mb-4 max-[720px]:!w-max max-[720px]:items-start max-[720px]:last:mb-0',
  );
  const rateInfoStyles = twMerge(
    'mb-2 flex flex-col items-center max-[720px]:flex-row max-[720px]:group-last:max-[720px]:mb-0',
  );
  const percentStyles = twMerge(
    'mb-4 rounded-[4px] px-4 py-2 text-2xl font-bold max-[1279px]:px-2 max-[1279px]:py-1 max-[1279px]:text-lg max-[1023px]:text-base max-[720px]:mb-0 max-[720px]:w-[50px] max-[720px]:text-center',
  );

  return (
    <div className={wrapperStyles}>
      <ul ref={listRef} className={listStyles}>
        {RATES.map((r) => {
          const percentClass = RATES_CLASSES[r.percent];

          return (
            <li
              key={r.id}
              style={{ width: `${100 / RATES.length}%` }}
              className={clsx(
                itemStyles,
                showItems
                  ? isMobileView
                    ? 'animate__animated animate__fadeInRight'
                    : 'animate__animated animate__fadeInLeft'
                  : '',
              )}
            >
              <div className={rateInfoStyles}>
                <div className="mr-4 hidden h-[4px] w-[50px] rounded-full bg-white max-[720px]:flex" />
                <span className={clsx(percentStyles, percentClass)}>{r.percent}%</span>
                <div className="flex flex-col items-center max-[720px]:ml-2 max-[720px]:items-start">
                  <span className="mb-1 text-2xl max-[1279px]:text-lg max-[1023px]:text-sm max-[720px]:mb-0">
                    ${formatUSDValue(r.wager)}
                  </span>
                  <span className="text-sm font-medium text-light-gray max-[1279px]:text-xs max-[1023px]:text-[0.65rem]">
                    total wager
                  </span>
                </div>
              </div>
              <div className="relative flex max-[720px]:hidden">
                <span
                  style={{ height: `${Big(40).mul(r.percent).div(10).toString()}px` }}
                  className="w-[4px] rounded-full group-odd:bg-white group-even:bg-gray "
                />
                <span className="absolute bottom-0 left-[32px] h-[24px] w-[4px] rounded-full bg-light-blue group-last:hidden max-[1279px]:left-[25px] max-[1023px]:left-[18px] min-[1536px]:left-[36px]" />
                <span className="absolute bottom-0 left-[64px] h-[24px] w-[4px] rounded-full bg-light-gray group-last:hidden max-[1279px]:left-[50px] max-[1023px]:left-[36px] min-[1536px]:left-[72px]" />
                <span className="absolute bottom-0 left-[96px] h-[24px] w-[4px] rounded-full bg-light-blue group-last:hidden max-[1279px]:left-[75px] max-[1023px]:left-[54px] min-[1536px]:left-[108px]" />
              </div>
            </li>
          );
        })}
      </ul>
      <div
        className={clsx('h-[16px] w-4/5 rounded-full max-[720px]:h-[490px] max-[720px]:w-[16px]', styles.ratesLine)}
      />
    </div>
  );
};

export default Rates;
