import { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import UserContext from 'context/userContext/context';
import { ADDRESS_BOOK } from 'graphql/user';
import { IAddressBook } from 'types';
import { ISelectOption } from 'components/common/select/types';
import { getCurrencyIcon } from 'helpers';

import Select from 'components/common/select';

import { IAddressBookSelect } from './types';

const AddressBookSelect: React.FC<IAddressBookSelect> = ({
  selected,
  selectedToken,
  selectedNetwork,
  onSelect,
  onMemo,
  onChangeWithMFA,
  ...props
}) => {
  const { profile } = useContext(UserContext);

  const [addresses, setAddresses] = useState<IAddressBook[]>([]);
  const [options, setOptions] = useState<ISelectOption[]>([]);

  const { data } = useQuery(ADDRESS_BOOK, { fetchPolicy: 'cache-and-network', skip: !profile });

  useEffect(() => {
    if (data) {
      const newAddresses = pathOr<IAddressBook[]>([], ['addressBook'], data);

      setAddresses(newAddresses);
    }
  }, [data]);

  useEffect(() => {
    if (selectedToken && selectedNetwork) {
      const addressesWithNetwork = addresses.filter(
        (a) => a.token.tokenCode === selectedToken && a.network.code === selectedNetwork,
      );
      const newOptions = addressesWithNetwork.map<ISelectOption>((ab) => ({
        label: `${ab.name} (${ab.address.slice(0, 8)}...${ab.address.slice(-8)})`,
        value: ab.address,
        icon: getCurrencyIcon(ab.network.code),
      }));

      setOptions(newOptions);
    }
  }, [addresses, selectedToken, selectedNetwork]);

  const handleCreateNew = (newAddress: string) => {
    setOptions((o) => [
      { label: newAddress, value: newAddress, icon: getCurrencyIcon(selectedNetwork), isCustom: true },
      ...o,
    ]);

    onSelect(newAddress);
    onChangeWithMFA(true);
  };

  const handleRemoveOption = (address: string | null) => {
    setOptions((co) => co.filter((o) => o.value !== address));
  };

  const handleSelect = (address: string | null) => {
    const addressBook = addresses.find((a) => a.address === address);
    const isWhitelisted = addressBook?.isWhitelisted || false;

    onChangeWithMFA(!isWhitelisted);
    onSelect(address);
    onMemo(addressBook?.memo || '');
  };

  return (
    <Select
      creatable
      clearable
      selected={selected}
      options={options}
      createLabel="Send To: "
      noOptionText="No saved addresses, please enter a new address"
      onSelect={handleSelect}
      onCreateNew={handleCreateNew}
      onRemoveOption={handleRemoveOption}
      {...props}
    />
  );
};

export default AddressBookSelect;
