import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';

import { CAMPAIGNS } from 'graphql/campaigns';
import { IAffiliateCampaign } from 'types';

import { ISelectOption } from 'components/common/select/types';

import { ICampaignReferralsFilterContainer } from './types';
import CampaignReferralsFilter from './campaignReferralsFilter';

const CampaignReferralsFilterContainer: React.FC<ICampaignReferralsFilterContainer> = ({
  onAliasSubmit,
  onAliasChange,
  alias,
  aliasError,
  ...props
}) => {
  const [campaignOptions, setCampaignOptions] = useState<ISelectOption[]>([]);

  const { data } = useQuery(CAMPAIGNS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data) {
      const campaigns = pathOr<IAffiliateCampaign[]>([], ['affiliateCampaigns'], data);

      const options = campaigns.map<ISelectOption>((c) => ({ value: c.id, label: c.name }));
      setCampaignOptions(options);
    }
  }, [data]);

  return (
    <CampaignReferralsFilter
      alias={alias}
      aliasError={aliasError}
      campaignOptions={campaignOptions}
      onAliasSubmit={onAliasSubmit}
      onAliasChange={onAliasChange}
      {...props}
    />
  );
};

export default CampaignReferralsFilterContainer;
