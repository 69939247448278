import Button from 'components/common/buttons/button';

import { IUnauthButtons } from './types';

const UnauthButtons: React.FC<IUnauthButtons> = ({ onLogin, onRegistration }) => {
  return (
    <div className="flex items-center">
      <Button
        type="button"
        wrapperClassList="mr-4"
        buttonsClassList="min-w-[100px] bg-semi-black"
        title="Log In"
        variant="secondary"
        onClick={onLogin}
      />
      <Button type="button" buttonsClassList="min-w-[100px]" title="Join Now" variant="main" onClick={onRegistration} />
    </div>
  );
};

export default UnauthButtons;
