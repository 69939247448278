/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ReactPaginate from 'react-paginate';
import clsx from 'clsx';

import { ReactComponent as ArrowIcon } from 'assets/common/arrow-right.svg';

import { ITEMS_PER_PAGE } from 'constants/table';

import Table from './table';

import { ITableWithPagination } from './types';

const TableContainer: React.FC<ITableWithPagination> = ({
  page,
  count,
  showPerPage = ITEMS_PER_PAGE,
  onChangePage,
  ...props
}) => {
  const pageCount = count ? Math.ceil(count / showPerPage) : 0;

  return (
    <div className="flex h-full w-full flex-grow flex-col overflow-hidden">
      <Table {...props} />
      {pageCount > 1 ? (
        <ReactPaginate
          className="mt-4 flex items-center justify-center text-light-gray selection:pointer-events-none"
          breakClassName="mx-1"
          pageClassName="mx-1 first:ml-0 last:mr-0"
          pageLinkClassName={`
          w-8 h-8 flex items-center justify-center border-[1px] border-transparent hover:text-white hover:text-opacity-80
        `}
          activeClassName="border-white border-[1px] rounded-full text-white"
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          onPageChange={({ selected }) => (onChangePage ? onChangePage(selected + 1) : null)}
          previousLabel={
            <ArrowIcon
              className={clsx(
                'mr-2 h-auto w-[24px] rotate-180 fill-[currentColor] hover:text-white hover:text-opacity-80',
                page === 1 ? 'hidden' : '',
              )}
            />
          }
          nextLabel={
            <ArrowIcon
              className={clsx(
                'ml-2 h-auto w-[24px] fill-[currentColor] hover:text-white hover:text-opacity-80',
                page === pageCount ? 'hidden' : '',
              )}
            />
          }
        />
      ) : null}
    </div>
  );
};

export default TableContainer;
