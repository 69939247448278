import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import logo from 'assets/common/logo.webp';
import planet1 from 'assets/home/planet-1.webp';
import planet2 from 'assets/home/planet-2.webp';
import planet3 from 'assets/home/planet-3.webp';
import planet4 from 'assets/home/planet-4.webp';
import land from 'assets/home/land.webp';

import HomepageTitle from 'components/common/text/title/homapage';
import HomepageDescription from 'components/common/text/description/homepageDescription';

import styles from './styles.module.scss';

const AffiliatesProgram: React.FC = () => {
  const bigPlanetStyles = twMerge(
    'absolute -top-16 h-auto w-[240px] max-[1440px]:w-[200px] max-[1300px]:w-[160px] max-[1200px]:w-[120px] max-[900px]:w-[100px] max-[725px]:w-[80px]',
  );
  const smallPlanetStyles = twMerge(
    'absolute -top-12 h-auto w-[160px] max-[1300px]:w-[140px] max-[1200px]:w-[120px] max-[900px]:w-[100px] max-[725px]:w-[80px]',
  );

  const planet1Styles = twMerge(bigPlanetStyles, 'left-0');
  const planet4Styles = twMerge(bigPlanetStyles, 'right-0');
  const planet2Styles = twMerge(
    smallPlanetStyles,
    'right-0 max-[1300px]:right-6 max-[1200px]:right-20 max-[900px]:right-28  max-[725px]:right-36',
  );
  const planet3Styles = twMerge(
    smallPlanetStyles,
    'left-0 max-[1300px]:left-6 max-[1200px]:left-20 max-[900px]:left-28 max-[725px]:left-36',
  );

  return (
    <section className={clsx('relative mb-20 flex flex-col items-center overflow-hidden', styles.wrapper)}>
      <div className="animate__animated animate__fadeInLeft pointer-events-none absolute left-0 top-0 w-[300px]">
        <img className={planet1Styles} src={planet1} alt="First Planet" />
        <img className={planet2Styles} src={planet2} alt="First Planet" />
      </div>
      <div className="animate__animated animate__fadeInRight pointer-events-none absolute right-0 top-0 w-[300px]">
        <img className={planet4Styles} src={planet4} alt="First Planet" />
        <img className={planet3Styles} src={planet3} alt="First Planet" />
      </div>
      <div className="container flex flex-col items-center">
        <img className="mt-16 h-auto w-[250px] max-[580px]:mt-32" src={logo} alt="Starbets Logo" />
        <HomepageTitle component="h1" classList="mt-8">
          Affiliate program
        </HomepageTitle>
        <HomepageDescription classList="w-3/5">
          If you&apos;re interested in earning some extra income, then the StarBets Affiliate Program may be just the
          opportunity you&apos;ve been looking for. As an affiliate partner with StarBets, you can earn a generous 50%
          of the platform revenue from all users you refer.
          <br /> This is an exclusive opportunity that&apos;s not available anywhere else.
        </HomepageDescription>
        <img className="animate__animated animate__fadeInUp pointer-events-none h-auto w-4/5" src={land} alt="Land" />
      </div>
    </section>
  );
};

export default AffiliatesProgram;
