export const HOMEPAGE_SEO_TEXT = `
  <h3>Getting that extra cash in your pocket has never been more exciting!</h3>
  <p>We're happy to announce the fantastic affiliate program of our crypto casino, where earning money is easy, fun, and super rewarding!</p>
  <p>It only takes a few simple clicks to get in and start earning. All you have to do is register for our affiliate program, and once it’s approved and activated, you get full access to all our tools and promo materials. You’ll have plenty of interactive tools to promote your business and generate income out of your traffic! And, of course, you know how it all works: the more traffic you bring, the more you earn - so it's a win-win!</p>
  <p>Whether you have one website or multiple, our easy-to-use program features simple tools that help you track multiple traffic sources without worrying about missing out!</p>
  <p>You're free to be creative and use as many materials as you like as long as it keeps that traffic flowing and your pocket full! Feel free to reach out if you have any fresh ideas to help increase traffic and brand awareness! Our team is super open-minded and always happy to discuss new opportunities!</p>
  <p>Keeping track of your traffic and income will be fun and easy since all the data is updated daily, and you can count on 100% accuracy!</p>
  <p>To make things even more exciting, you can earn even more for every additional affiliate you bring, which in turn brings even more traffic!</p>
  <p>Crypto lovers worldwide also have the fantastic opportunity to earn dividends and STAR tokens! Star tokens can be earned while betting and playing in our crypto casino, and players can either use them to participate in lotteries or hold them to earn even more dividends.</p>
  <p>Believe us when we say that this is not all! No more annoying processing times, no more waiting 5 to 7 business days to get your money, since the withdrawals are processed INSTANTLY!</p>
  <p>So, what are you waiting for? <b>Let's get that cash flowing!</b></p>
`;
