import { ReactComponent as GlobeIcon } from 'assets/common/globe.svg';

import Modal from 'components/common/base/modal';
import Input from 'components/common/inputs/input';
import Button from 'components/common/buttons/button';
import Loader from 'components/common/loaders/loader';

import { IUpdateCampaignModal } from './types';

const UpdateCampaignModal: React.FC<IUpdateCampaignModal> = ({
  name,
  errorMessage,
  loading,
  onChangeName,
  onSubmit,
}) => {
  return (
    <Modal title="Update Campaign" modalClassName="w-[460px]">
      <div className="relative mx-auto flex w-full flex-col items-center overflow-hidden py-6 max-[400px]:w-4/5">
        {loading ? <Loader /> : null}
        <Input
          type="text"
          placeholder="Name"
          label="Name:"
          labelAlign="center"
          lableWrapperClassList="mb-8"
          icon={GlobeIcon}
          value={name}
          errorMessage={errorMessage}
          onChange={(ev) => onChangeName(ev.target.value)}
        />
        <Button variant="main" title="Save" onClick={onSubmit} />
      </div>
    </Modal>
  );
};

export default UpdateCampaignModal;
