/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';

import { IZendeskArticle } from 'types';
import { getZendeskArticles } from 'helpers/zendesk';

import FAQArticles from './faqArticles';
import { IFAQArticlesContainer } from './types';

const FAQArticlesContainer: React.FC<IFAQArticlesContainer> = ({ sectionName, onHide }) => {
  const [articles, setArticles] = useState<IZendeskArticle[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    if (onHide && loaded) {
      onHide(!articles.length);
    }
  }, [articles, loaded, onHide]);

  async function getArticles() {
    const newArticles = await getZendeskArticles(sectionName);

    setArticles(newArticles);
    setLoaded(true);
  }

  return <FAQArticles articles={articles} />;
};

export default FAQArticlesContainer;
