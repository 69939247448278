import { useMemo } from 'react';
import { pathOr } from 'ramda';
import { useLazyQuery } from '@apollo/client';

import { AUTH_ZENDESK } from 'graphql/zendesk';
import { updateZendeskScript } from 'helpers/zendesk';

import ZendeskContext from './context';

const ZendeskProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [zendeskJwt] = useLazyQuery(AUTH_ZENDESK);

  const authUser = async () => {
    const tokenResponse = await zendeskJwt()
      .then((res) => res.data)
      .catch(() => null);
    const zendeskJwtToken = pathOr(null, ['zendeskJwt'], tokenResponse);

    if (zendeskJwtToken) {
      updateZendeskScript(zendeskJwtToken);
    }
  };

  const zendeskData = useMemo(() => ({ authUser }), [authUser]);

  return <ZendeskContext.Provider value={zendeskData}>{children}</ZendeskContext.Provider>;
};

export default ZendeskProvider;
