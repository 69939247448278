/* eslint-disable react/destructuring-assignment */
import { components } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { ISelectPlaceholder } from './types';

const { Placeholder } = components;

const SelectPlaceholder: React.FC<ISelectPlaceholder> = ({
  placeholderIcon: Icon,
  placeholderIconClassName = '',
  children,
  ...props
}) => {
  return (
    <Placeholder {...props} className={twMerge(props.className)}>
      {Icon ? (
        <Icon
          className={twMerge(
            'absolute left-5 top-1/2 h-auto w-[16px] -translate-y-1/2 cursor-pointer fill-light-gray',
            placeholderIconClassName,
          )}
        />
      ) : null}
      {children}
    </Placeholder>
  );
};

export default SelectPlaceholder;
