/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-use-before-define */
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';
import { useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

import { AffiliateContactType } from 'types';
import ModalsContext from 'context/modalsContext/context';
import { Modals } from 'context/modalsContext/types';
import { initialRegistrationFirstStepValues, initialRegistrationSecondStepValues } from 'constants/formik';
import { registrationFirstStepValidationSchema, registrationSecondStepValidationSchema } from 'constants/validation';
import { REGISTRATION } from 'graphql/auth';
import { parseErrorMessage } from 'helpers';
import { AFFILIATE_CATEGORY, AuthErrors } from 'constants/auth';

import Registration from './registration';
import { IRegistrationFirstStepValues, IRegistrationSecondStepValues } from './types';

const RegistrationContainer: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const formikFirstStep = useFormik<IRegistrationFirstStepValues>({
    initialValues: initialRegistrationFirstStepValues,
    validationSchema: registrationFirstStepValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {},
  });
  const formikSecondStep = useFormik<IRegistrationSecondStepValues>({
    initialValues: initialRegistrationSecondStepValues,
    validationSchema: registrationSecondStepValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');

  const [createAccount, { loading }] = useMutation(REGISTRATION, { fetchPolicy: 'no-cache' });

  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (step === 1) {
      const valid = await formikFirstStep.validateForm();

      if (!Object.values(valid).length) setStep(2);
      return;
    }

    if (step === 2) {
      const valid = await formikSecondStep.validateForm();

      if (!Object.values(valid).length) {
        const captcha = await recaptchaRef.current?.executeAsync();

        const { email, password, confirmPassword } = formikFirstStep.values;
        const { type, name, contactType, contact, website } = formikSecondStep.values;

        const data = {
          email: email.toLowerCase(),
          password,
          confirmPassword,
          captcha,
          category: AFFILIATE_CATEGORY[type],
          name,
          skype: contactType === AffiliateContactType.skype ? contact : null,
          telegram: contactType === AffiliateContactType.telegram ? contact : null,
          websites: [website],
        };
        const variables = { data };

        await createAccount({ variables })
          .then(() => {
            showModal({
              modal: Modals.verifyEmail,
              data: { email: email.toLowerCase(), password },
            });
          })
          .catch((err) => {
            const errorBody = parseErrorMessage(err.message);
            const requestErrorMessage = errorBody?.description || err.message;

            switch (requestErrorMessage) {
              case AuthErrors.unavailable: {
                showModal({ modal: Modals.unavailable });
                break;
              }
              case AuthErrors.alreadyExists: {
                setErrorMessage('This account already exists in StarBets, please contact us at affiliate@starbets.io');
                break;
              }
              default: {
                setErrorMessage(requestErrorMessage);
                break;
              }
            }

            setTimeout(() => recaptchaRef.current?.reset(), 500);
          });
      }
    }
  };

  const handleLogin = () => {
    showModal({ modal: Modals.login });
  };

  const handleClose = () => {
    showModal({ modal: undefined });
  };

  return (
    <Registration
      formikFirstStep={formikFirstStep}
      formikSecondStep={formikSecondStep}
      step={step}
      recaptcha={recaptchaRef}
      loading={loading}
      errorMessage={errorMessage}
      onLogin={handleLogin}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export default RegistrationContainer;
