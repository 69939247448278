import { gql } from '@apollo/client';

export const CAMPAIGNS = gql`
  query affiliateCampaigns {
    affiliateCampaigns {
      id
      name
      code
      createdAt
      registrations
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation ($name: String!) {
    createAffiliateCampaign(name: $name) {
      id
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation ($name: String!, $id: String!) {
    updateAffiliateCampaign(name: $name, id: $id)
  }
`;
