import { pathOr } from 'ramda';
import { useContext, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import ModalsContext from 'context/modalsContext/context';
import { IVerifyEmailData, Modals } from 'context/modalsContext/types';
import { SEND_EMAIL_VERIFICATION_OTP, VERIFY_EMAIL } from 'graphql/auth';
import { ITokenPayload } from 'requestTypes';

import VerifyEmailModal from './verifyEmail';

const VerifyEmailContainer: React.FC = () => {
  const { data, showModal } = useContext(ModalsContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [verifyEmail, { loading: verifyLoading }] = useMutation(VERIFY_EMAIL);
  const [sendEmailVerificationOtp, { loading: sendLoading }] = useLazyQuery(SEND_EMAIL_VERIFICATION_OTP, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      const { email: userEmail, password: userPassword } = data as IVerifyEmailData;

      setEmail(userEmail);
      setPassword(userPassword);
    }
  }, [data]);

  const handleChangeCode = (newCode: string) => {
    setCode(newCode);
  };

  const handleSubmit = async () => {
    const variables = { email, password, code };

    await verifyEmail({ variables })
      .then((res) => {
        const tokens = pathOr<ITokenPayload | undefined>(undefined, ['data', 'verifyEmail'], res);

        if (tokens) {
          showModal({
            modal: Modals.success,
            data: { message: 'Your registration was successful, please wait for approval.' },
          });
        } else {
          setErrorMessage('Something went wrong, please try again later.');
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[VERIFY_EMAIL_ERROR]:', err);

        setErrorMessage(err.message || 'Something went wrong, please try again later.');
      });
  };

  const handleResendEmail = async () => {
    const variables = { email };

    await sendEmailVerificationOtp({ variables })
      .then(() => {
        setEmailSent(true);

        setTimeout(() => setEmailSent(false), 2000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[SEND_OTP_ERROR]:', err);

        setErrorMessage(err?.message || '');
      });
  };

  return (
    <VerifyEmailModal
      email={email}
      code={code}
      emailSent={emailSent}
      errorMessage={errorMessage}
      loading={verifyLoading || sendLoading}
      onChangeCode={handleChangeCode}
      onSubmit={handleSubmit}
      onResendEmail={handleResendEmail}
    />
  );
};

export default VerifyEmailContainer;
