/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactComponent as MailIcon } from 'assets/security/mail.svg';
import { ReactComponent as AuthenticatorIcon } from 'assets/security/authenticator.svg';

import Box from 'components/common/content/box';
import Toggle from 'components/common/inputs/toggle';
import BoxTitle from 'components/common/text/title/box';
import Checkbox from 'components/common/inputs/checkbox';
import Button from 'components/common/buttons/button';
import Loader from 'components/common/loaders/loader';

import MfaMethod from './components/mfaMethod';
import { IAccountSecurity } from './types';

const AccountSecurity: React.FC<IAccountSecurity> = ({ formik, loading, disabled }) => {
  return (
    <Box
      component="div"
      className="shadow-black relative mr-4 flex w-full max-w-[calc(50%-0.5rem)] flex-grow flex-col p-8 max-[870px]:mb-4 max-[870px]:mr-0 max-[870px]:max-w-full"
    >
      {loading ? <Loader className="z-20 rounded-[20px]" /> : null}
      <BoxTitle title="Security" />
      <p className="mb-4 text-xs">
        2-Factor Authentication is an added layer of security that will keep your account protected from unauthorized
        access.
      </p>
      <form className="flex flex-grow flex-col" onSubmit={formik.handleSubmit}>
        <Toggle
          className="mb-4"
          label="2FA Enabled"
          checked={formik.values.mfaType !== 'none'}
          onChange={() => formik.setFieldValue('mfaType', formik.values.mfaType === 'none' ? 'google' : 'none')}
        />
        <ul className="mb-4 flex flex-grow items-center max-[520px]:flex-col">
          <MfaMethod
            checked={formik.values.mfaType === 'email'}
            title="Email"
            description="(less secure)"
            icon={MailIcon}
            onCheck={() => formik.setFieldValue('mfaType', 'email')}
          />
          <MfaMethod
            checked={formik.values.mfaType === 'google'}
            title="Google Authenticator"
            description="(more secure)"
            icon={AuthenticatorIcon}
            onCheck={() => formik.setFieldValue('mfaType', 'google')}
          />
        </ul>
        <div className="flex items-center justify-between max-[340px]:flex-col">
          <Checkbox
            lableWrapperClassList="max-[340px]:mb-4"
            checked={formik.values.requredAtLogin}
            label={<>Require 2FA at Login</>}
            onChange={(ev) => formik.setFieldValue('requredAtLogin', ev.target.checked)}
          />
          <Button type="submit" variant="secondary" size="small" title="Save" disabled={disabled} />
        </div>
      </form>
    </Box>
  );
};

export default AccountSecurity;
