import dayjs from 'dayjs';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { twMerge } from 'tailwind-merge';

import { IDateRange } from './types';

const DateRange: React.FC<IDateRange> = ({ minDate, maxDate, className = '', disabled, onDatesChange }) => {
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    setDateFrom(start);
    setDateTo(end);
    onDatesChange(dates);
  };

  return (
    <DatePicker
      startDate={dateFrom}
      endDate={dateTo}
      minDate={minDate}
      maxDate={maxDate}
      wrapperClassName="!w-auto !flex max-[870px]:ml-auto"
      className={twMerge(
        'min-sm:mr-[36px] cursor-pointer rounded-[20px] border-2 border-dark-blue/80 bg-dark-blue px-4 py-2 text-center text-xs outline-none hover:border-purple hover:border-opacity-50 max-sm:w-[300px] max-[530px]:!w-[200px] min-[640px]:!w-[230px]',
        className,
      )}
      calendarClassName="!rounded-[14px] !bg-dark-blue !border-purple/80 !border-2"
      dayClassName={() => `!text-white`}
      placeholderText={`${dayjs(minDate).format('MM/DD/YYYY')} - ${dayjs(maxDate).format('MM/DD/YYYY')}`}
      isClearable
      selectsRange
      dateFormat="MM/dd/y"
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default DateRange;
