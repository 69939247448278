import { useContext, useEffect, useState } from 'react';

import ModalsContext from 'context/modalsContext/context';

import ForbiddenModal from './forbidden';

const ForbiddenModalContainer: React.FC = () => {
  const { data } = useContext(ModalsContext);

  const [text, setText] = useState('');

  useEffect(() => {
    if (data) setText(data as string);
  }, [data]);

  return <ForbiddenModal text={text} />;
};

export default ForbiddenModalContainer;
