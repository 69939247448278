import Box from 'components/common/content/box';
import BoxTitle from 'components/common/text/title/box';
import Button from 'components/common/buttons/button';
import Input from 'components/common/inputs/input/input';
import Tooltip from 'components/common/text/tooltip';

import { IAccountInfo } from './types';

const AccountInfo: React.FC<IAccountInfo> = ({
  avatar,
  alias,
  email,
  editMode,
  newAlias,
  errorMessage,
  onChangeAvatar,
  onChangeAlias,
  onChangeNewAlias,
  onKeyDownAlias,
  onFocusAlias,
  onBlurAlias,
  onSaveAlias,
  onChangePassword,
}) => {
  return (
    <Box
      component="div"
      className="shadow-black mr-4 w-full max-w-[calc(50%-0.5rem)] flex-grow p-8 max-[870px]:mb-4 max-[870px]:mr-0 max-[870px]:max-w-full"
    >
      <BoxTitle title="Account Info" />
      <div className="mb-4 flex w-full flex-col items-center">
        <div className="shadow-filter bgg-purple mb-4 flex h-[104px] w-[104px] items-center justify-center rounded-full">
          <img className="h-[102px] w-auto rounded-full" src={avatar} alt={`${alias} avatar`} />
        </div>
        <Button variant="secondary" title="Change Avatar" onClick={onChangeAvatar} />
      </div>
      <ul>
        <li className="mb-2 flex h-[26px] w-full items-center max-[455px]:mb-4">
          <div className="flex w-full text-sm max-[455px]:justify-between">
            <span className="mr-2 w-[80px] text-gray max-[455px]:w-[unset]">Email:</span>
            <span>{email}</span>
          </div>
        </li>
        <li className="relative mb-2 flex w-full items-center justify-between max-[455px]:mb-4 max-[455px]:flex-col">
          <div className="flex flex-grow items-center text-sm max-[455px]:mb-2 max-[455px]:w-full max-[455px]:justify-between">
            <span className="mr-2 w-[80px] text-gray max-[455px]:w-[unset]">Alias:</span>
            <span className="flex min-w-[150px] max-w-[300px] flex-grow">
              {editMode ? (
                <Input
                  type="text"
                  className="-ml-3 w-full px-[10px] py-1"
                  value={newAlias}
                  errorMessage={errorMessage}
                  withoutErrorMessage
                  onFocus={onFocusAlias}
                  onBlur={onBlurAlias}
                  onKeyDown={onKeyDownAlias}
                  onChange={(ev) => onChangeNewAlias(ev.target.value)}
                />
              ) : (
                alias
              )}
            </span>
          </div>
          <Tooltip errorMessage={errorMessage} textClassName="min-w-[200px]" showOnError position="top">
            <Button
              wrapperClassList="max-[455px]:w-full"
              buttonsClassList="max-[455px]:w-full"
              type="button"
              variant="secondary"
              size="small"
              title={editMode ? 'Save' : 'Edit'}
              onClick={editMode ? onSaveAlias : onChangeAlias}
            />
          </Tooltip>
        </li>
        <li className="flex w-full items-center justify-between max-[455px]:flex-col">
          <div className="flex items-center text-sm max-[455px]:mb-2 max-[455px]:w-full max-[455px]:justify-between">
            <span className="mr-2 w-[80px] text-gray max-[455px]:w-[unset]">Password:</span>
            <span>************</span>
          </div>
          <Button
            wrapperClassList="max-[455px]:w-full"
            buttonsClassList="max-[455px]:w-full"
            type="button"
            variant="secondary"
            size="small"
            title="Edit"
            onClick={onChangePassword}
          />
        </li>
      </ul>
    </Box>
  );
};

export default AccountInfo;
