import { twMerge } from 'tailwind-merge';

import { getCurrencyIcon, getFormattedAmount } from 'helpers';

import { ICurrencyTemplate } from './types';

const CurrencyTemplate: React.FC<ICurrencyTemplate> = ({ currencies, classList }) => {
  const currencyTemplateStyle = twMerge(
    'grid grid-cols-1 w-full bg-dark-blue rounded-[8px] max-h-[270px] gap-[3px] overflow-y-auto',
    'border-t-4 border-b-4 border-r-8 border-transparent',
    '2xl:text-[24px]',
    'xl:text-[18px]',
    'lg:text-[13px]',
    'md:text-[12px] ',
    'sm:grid-cols-2 sm:max-h-[148px] sm:gap-[10px]',
    classList,
  );

  const iconStyle = twMerge('inline-block w-[40px]');

  return (
    <div className={currencyTemplateStyle}>
      {currencies.map((el) => (
        <div key={`${el.tokenCode}${el.amount}`} className="flex items-center justify-start">
          <img src={getCurrencyIcon(el.displayName)} alt={el.displayName} className={iconStyle} />
          <p>{`${getFormattedAmount(el.amount)} ${el.displayName}`}</p>
        </div>
      ))}
    </div>
  );
};

export default CurrencyTemplate;
