import { useState } from 'react';

import { AccountTitle } from 'constants/account';

import AccountTable from './accountTable';

const AccountTableContainer: React.FC = () => {
  const [title, setTitle] = useState<AccountTitle>(AccountTitle.loginActivity);

  const handleChangeTitle = (newTitle: string) => {
    setTitle(newTitle as AccountTitle);
  };

  return <AccountTable title={title} onChangeTitle={handleChangeTitle} />;
};

export default AccountTableContainer;
