import { gql } from '@apollo/client';

export const PROFILE = gql`
  query affiliateProfile {
    affiliateProfile {
      user {
        id
        alias
        email
        telegramId
        referralCode
        totalWager
        totalMined
        totalPayout
        totalBets
        createdAt
        status
        lastLoginDate
        rankId
        avatar {
          avatar
        }
        preferences {
          accountActivityAlert
          newsEmail
          promoEmail
        }
        roles
      }
      name
      percentage
    }
  }
`;

export const WALLETS = gql`
  query wallets {
    wallets {
      availableBalance
      token {
        displayName
        tokenCode
      }
    }
  }
`;

export const ADDRESS_BOOK = gql`
  query addressBook {
    addressBook {
      id
      address
      isWhitelisted
      name
      token {
        tokenCode
        displayName
      }
      network {
        code
      }
      memo
    }
  }
`;

export const AVATARS = gql`
  query avatars {
    avatars {
      avatar
      id
      rank
      type
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation updateUserAvatar($data: UserAvatarDto!) {
    updateUserAvatar(formData: $data) {
      avatar {
        id
        avatar
      }
    }
  }
`;

export const ACTIVITY = gql`
  query activity($input: ActivityLogsPaginationParams!) {
    activity(paginationData: $input) {
      count
      items {
        activity
        createdAt
        device
        ipAddress
        status
        source
      }
    }
  }
`;

export const UPDATE_ALIAS = gql`
  mutation updateUserAlias($userFormData: UserDto!) {
    updateUserAlias(formData: $userFormData) {
      alias
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  query changePassword($currentPassword: String!, $newPassword: String!, $securityCode: String, $emailOtp: String) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      securityCode: $securityCode
      emailOtp: $emailOtp
    ) {
      status
    }
  }
`;
