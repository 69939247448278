/* eslint-disable no-multi-str */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as MailIcon } from 'assets/common/mail.svg';
import { ReactComponent as LockIcon } from 'assets/common/lock.svg';
import { ReactComponent as UserIcon } from 'assets/common/user.svg';
import { ReactComponent as EyeIcon } from 'assets/common/eye.svg';
import { ReactComponent as CrossedEyeIcon } from 'assets/common/crossed-eye.svg';

import { SVGIcon } from 'types';

import ErrorMessage from 'components/common/text/errorMessage';

import { ICustomInput } from './types';

const CustomInput: React.FC<ICustomInput> = ({
  formik,
  type,
  name,
  errorMessage = '',
  withoutErrorMessage = false,
  helperText = '',
  withIcon = true,
  icon,
  currencyIcon,
  className = '',
  iconClassList = '',
  buttonText = '',
  onChange,
  onBlur,
  onButtonClick,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const [Icon, setIcon] = useState<SVGIcon>();

  const inputStyles = twMerge(
    'animation w-full rounded-[20px] border-2 border-transparent bg-dark-blue/80 px-10 \
     py-2 text-sm outline-none focus:border-purple placeholder:text-light-gray hover:border-purple \
     hover:border-opacity-50',
    errorMessage ? 'border-red bg-red bg-opacity-10' : '',
    props.disabled ? 'bg-dark-blue/60' : '',
    className,
  );
  const iconStyles = twMerge(
    'absolute left-4 top-1/2 h-auto w-[16px] -translate-y-1/2 cursor-pointer fill-light-gray',
    iconClassList,
  );
  const currencyIconStyles = twMerge(
    'absolute left-4 top-1/2 h-auto w-[32px] -translate-y-1/2 cursor-pointer fill-light-gray',
    iconClassList,
  );
  const passwordIconStyles = twMerge(iconStyles, 'left-[unset] right-4');

  useEffect(() => {
    if (withIcon && (icon || type)) {
      if (icon) {
        setIcon(icon);
        return;
      }

      if (type === 'email') {
        setIcon(MailIcon);
        return;
      }

      if (type === 'password') {
        setIcon(LockIcon);
        return;
      }

      if (name?.toLowerCase() === 'name' || name?.toLowerCase() === 'username') {
        setIcon(UserIcon);
      }
    }
  }, [withIcon, icon, type]);

  const handleTogglePassword = () => {
    setInputType((t) => (t === 'password' ? 'text' : 'password'));
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (formik) {
      formik.setErrors({});
    }

    if (onChange) onChange(ev);
  };

  const handleBlur = (ev: React.FocusEvent<HTMLInputElement, Element>) => {
    if (type === 'password') {
      setInputType(type);
    }

    if (onBlur) onBlur(ev);
  };

  const handleButtonClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.stopPropagation();

    if (onButtonClick) onButtonClick();
  };

  return (
    <>
      <div className="relative w-full">
        <input
          type={inputType}
          name={name}
          className={inputStyles}
          onChange={handleChange}
          onBlur={handleBlur}
          {...props}
        />
        {Icon ? <Icon className={iconStyles} /> : null}
        {currencyIcon ? <img className={currencyIconStyles} src={currencyIcon} alt="Currency" /> : null}
        {type === 'password' ? (
          inputType === 'text' ? (
            <EyeIcon className={passwordIconStyles} onClick={handleTogglePassword} />
          ) : (
            <CrossedEyeIcon className={passwordIconStyles} onClick={handleTogglePassword} />
          )
        ) : null}
        {onButtonClick ? (
          <button
            className="absolute right-0 top-1/2 h-full -translate-y-1/2 rounded-r-[20px] pr-4"
            type="button"
            disabled={props.disabled}
            onClick={handleButtonClick}
          >
            <span className="text-sm font-medium">{buttonText}</span>
          </button>
        ) : null}
      </div>
      {!withoutErrorMessage ? <ErrorMessage className={helperText ? 'mb-0' : ''} message={errorMessage} /> : null}
      {helperText ? <p className="mb-2 px-4 text-center text-xs text-yellow">{helperText}</p> : null}
    </>
  );
};

export default CustomInput;
