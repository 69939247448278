import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

import ModalsContext from 'context/modalsContext/context';
import { IMFAData } from 'context/modalsContext/types';
import { SEND_OTP_TO_EMAIL } from 'graphql/auth';

import Mfa from './mfa';

const MfaContainer: React.FC = () => {
  const { data } = useContext(ModalsContext);

  const [title, setTitle] = useState('');
  const [mfaType, setMfaType] = useState<'google' | 'email'>('google');
  const [code, setCode] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [sendOtpToEmail] = useLazyQuery(SEND_OTP_TO_EMAIL, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    const { mfaType: newMfaType, sendEmail = false } = data as IMFAData;

    if (newMfaType === 'email' && sendEmail) {
      sendOtpToEmail();
    }

    setTitle(newMfaType === 'email' ? 'Email' : 'Google');
    setMfaType(newMfaType);
  }, [data]);

  const handleChangeCode = (newCode: string) => {
    if (errorMessage) setErrorMessage('');

    setCode(newCode);
  };

  const handleResendEmail = async () => {
    await sendOtpToEmail()
      .then(() => {
        setEmailSent(true);

        setTimeout(() => setEmailSent(false), 2000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[SEND_OTP_ERROR]:', err);

        setErrorMessage(err?.message || '');
      });
  };

  const handleSubmit = async () => {
    if (code.length < 6) {
      setErrorMessage('Code must be at least 6 characters');
      return;
    }

    const { action } = data as IMFAData;

    if (action) await action(code, (message: string) => setErrorMessage(message));
  };

  return (
    <Mfa
      title={title}
      code={code}
      mfaType={mfaType}
      errorMessage={errorMessage}
      emailSent={emailSent}
      onChangeCode={handleChangeCode}
      onResendEmail={handleResendEmail}
      onSubmit={handleSubmit}
    />
  );
};

export default MfaContainer;
