/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ITooltip } from './types';

const Tooltip: React.FC<ITooltip> = ({
  text = '',
  errorMessage = '',
  position = 'bottom',
  showOnAction = false,
  showOnError = false,
  className = '',
  textClassName = '',
  arrowClassName = '',
  children,
}) => {
  const wrapperStyles = twMerge(
    'inline-flex items-center relative group',
    position === 'top' || position === 'bottom' ? 'justify-center' : '',
    className,
  );

  const textStyles = twMerge(
    `
      absolute inline-flex justify-center items-center z-50 px-4 py-2 font-medium text-xs 
      text-center border-2 border-transparent rounded-[6px] bg-semi-black shadow-2xl invisible opacity-0 
    `,
    position === 'top' ? 'translate-y-2/4 group-hover:translate-y-0 mb-3 bottom-[100%]' : '',
    position === 'bottom' ? '-translate-y-2/4 group-hover:translate-y-0 mt-3 top-[100%]' : '',
    position === 'right' ? '-translate-x-2/4 group-hover:translate-x-0 ml-3 left-[100%]' : '',
    position === 'left' ? 'translate-x-2/4 group-hover:translate-x-0 mr-3 right-[100%]' : '',
    showOnAction || showOnError
      ? 'transition-all ease-in-out duration-200'
      : 'group-hover:visible group-hover:opacity-100 group-hover:transition-all group-hover:ease-in-out group-hover:duration-200',
    showOnAction || showOnError
      ? position === 'top' || position === 'bottom'
        ? 'translate-y-0'
        : 'translate-x-0'
      : '',
    errorMessage ? 'border-red bg-red/40' : '',
    textClassName,
  );

  const arrowStyles = twMerge(
    'absolute h-2 w-3 transform bg-opacity-0',
    position === 'top'
      ? `h-2 w-3 border-solid border-t-semi-black border-t-8 border-x-transparent border-x-8 border-b-0 top-[100%]`
      : '',
    position === 'bottom'
      ? `h-2 w-3 border-solid 
        drop-shadow-[0_-2px_1px_rgba(0,0,0,0.15)] border-b-semi-black
        border-b-8 border-x-transparent border-x-8 border-t-0 bottom-[100%]`
      : '',
    position === 'right'
      ? `h-3 w-2 border-solid 
        drop-shadow-[-2px_0_1px_rgba(0,0,0,0.15)] border-r-semi-black
        border-r-8 border-y-transparent border-y-8 border-l-0 right-[100%]`
      : '',
    position === 'left'
      ? `h-3 w-2 border-solid 
        drop-shadow-[2px_0_1px_rgba(0,0,0,0.15)] border-l-semi-black
        border-l-8 border-y-transparent border-y-8 border-r-0 left-[100%]`
      : '',
    errorMessage ? 'border-red' : '',
    arrowClassName,
  );

  const [visible, setVisible] = useState(false);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();

    if (showOnAction) {
      setVisible(true);

      setTimeout(() => setVisible(false), 1000);
    }
  };

  return (
    <div className={wrapperStyles}>
      <div className={className} onClick={handleClick}>
        {children}
      </div>
      <span
        className={twMerge(
          textStyles,
          (showOnAction && visible) || (showOnError && errorMessage) ? 'visible opacity-100' : '',
        )}
      >
        {text || errorMessage}
        <span className={arrowStyles} />
      </span>
    </div>
  );
};

export default Tooltip;
