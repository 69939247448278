import ReCAPTCHA from 'react-google-recaptcha';

import banner from 'assets/login/banner.webp';

import config from 'constants/config';

import Modal from 'components/common/base/modal';
import Input from 'components/common/inputs/input';
import Button from 'components/common/buttons/button';
import PlainButton from 'components/common/buttons/plainButton';
import Loader from 'components/common/loaders/loader';
import ErrorMessage from 'components/common/text/errorMessage';

import { ILogin } from './types';

const { captchaKey } = config;

const Login: React.FC<ILogin> = ({ formik, recaptcha, loading, errorMessage, onRegistration, onForgotPassword }) => {
  return (
    <Modal modalClassName="w-full" withPaddings={false}>
      <div className="flex w-full">
        <img
          className="pointer-events-none h-auto w-full max-w-[45%] rounded-l-[20px] selection:pointer-events-none max-[1100px]:hidden"
          src={banner}
          alt="Login Banner"
        />
        <div className="relative flex min-h-[657px] flex-grow flex-col items-center px-20 py-10 max-[550px]:px-10 max-[400px]:px-4">
          {loading ? <Loader className="rounded-r-[20px]" /> : null}
          <h3 className="mb-4 text-3xl font-bold uppercase">Log In</h3>
          <form className="flex w-full flex-grow flex-col" onSubmit={formik.handleSubmit}>
            <Input
              formik={formik}
              autoComplete="username"
              name="email"
              type="email"
              placeholder="Email"
              label="Email:"
              labelAlign="center"
              value={formik.values.email}
              errorMessage={formik.errors?.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              formik={formik}
              autoComplete="password"
              name="password"
              type="password"
              placeholder="Password"
              label="Password:"
              labelAlign="center"
              value={formik.values.password}
              errorMessage={formik.errors?.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <ErrorMessage message={errorMessage} />
            <Button
              title="Log In"
              type="submit"
              wrapperClassList="mt-auto w-full"
              buttonsClassList="w-full"
              variant="secondary"
            />
          </form>
          <PlainButton
            classList="my-4 text-sm text-yellow underline underline-offset-4"
            type="button"
            title="Forgot your password?"
            onClick={onForgotPassword}
          />
          <div className="flex items-center text-sm text-light-gray max-[350px]:flex-col">
            <p className="mr-1">Don&apos;t have an account?</p>
            <PlainButton
              classList="underline underline-offset-4"
              type="button"
              title="Join Now"
              onClick={onRegistration}
            />
          </div>
        </div>
        <ReCAPTCHA ref={recaptcha} size="invisible" sitekey={captchaKey} />
      </div>
    </Modal>
  );
};

export default Login;
