/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from 'react';

import UserContext from 'context/userContext/context';

import Profile from './profile';

const ProfileContainer: React.FC = () => {
  const { profile } = useContext(UserContext);

  const wrapperRef = useRef<HTMLElement>(null);

  const [avatar, setAvatar] = useState('');
  const [alias, setAlias] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (profile) {
      const { user } = profile;

      setAvatar(user.avatar?.avatar || '');
      setAlias(user.alias);
    }
  }, [profile]);

  const handleOpen = () => {
    setOpen((o) => !o);
  };

  function handleClickOutside(ev: MouseEvent) {
    const profileBlock = wrapperRef.current;

    if (profileBlock && !profileBlock.contains(ev.target as any)) {
      setOpen(false);
    }
  }

  return <Profile wrapperRef={wrapperRef} alias={alias} avatar={avatar} open={open} onOpen={handleOpen} />;
};

export default ProfileContainer;
