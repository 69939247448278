import Box from 'components/common/content/box';

import FAQArticleContainer from './components/faqArticle';

import { IFAQArticles } from './types';

const FAQArticles: React.FC<IFAQArticles> = ({ articles }) => {
  return (
    <Box component="ul" className="flex w-full flex-col px-6 py-8">
      {articles.map((a) => (
        <FAQArticleContainer key={a.id} article={a} />
      ))}
    </Box>
  );
};

export default FAQArticles;
