import clsx from 'clsx';
import { Link } from 'react-router-dom';

import logo from 'assets/common/logo.webp';
import { ReactComponent as CrossIcon } from 'assets/common/cross.svg';
import { ReactComponent as LogoutIcon } from 'assets/common/logout.svg';

import { Links, SIDEBAR_MENU, USER_MENU } from 'constants/nav';

import { IMobileMenu } from './types';

const MobileMenu: React.FC<IMobileMenu> = ({ authorized, mobileMenuVisible, onShowMenu, onLogout }) => {
  return (
    <aside
      className={clsx(
        `animation absolute z-50 hidden h-full w-full flex-col bg-semi-black max-[1023px]:flex`,
        mobileMenuVisible ? '' : '-translate-x-full',
      )}
    >
      <div className="flex h-[68px] items-center justify-between border-b-[1px] border-light-purple px-6 py-4">
        <Link to={authorized ? Links.dashboard : Links.home} onClick={onShowMenu}>
          <img className="w-[130px] max-[400px]:w-[100px] max-[330px]:w-[80px] " src={logo} alt="Starbets Logo" />
        </Link>
        <button type="button" onClick={onShowMenu}>
          <CrossIcon className="h-auto w-[34px] fill-white" />
        </button>
      </div>
      <ul className="flex flex-col border-b-[1px] border-light-purple px-6 py-8">
        {SIDEBAR_MENU.map((m) => (
          <li key={m.id} className="mb-8 last:mb-0">
            <Link to={m.link} className="flex items-center" onClick={onShowMenu}>
              <img className="mr-4 h-auto w-[28px]" src={m.icon} alt={m.title} />
              <span className="text-base font-bold">{m.title}</span>
            </Link>
          </li>
        ))}
      </ul>
      <ul className="mb-8 flex flex-col px-6 pt-8">
        {USER_MENU.map((m) => (
          <li key={m.id} className="mb-8 last:mb-0">
            <Link to={m.link} className="flex items-center" onClick={onShowMenu}>
              <img className="mr-4 h-auto w-[28px]" src={m.icon} alt={m.title} />
              <span className="text-base font-bold">{m.title}</span>
            </Link>
          </li>
        ))}
      </ul>
      <div className="flex flex-col px-7">
        <button type="button" className="flex items-center hover:text-orange hover:text-opacity-90" onClick={onLogout}>
          <LogoutIcon className="mr-6 h-auto w-[18px] fill-[currentColor]" />
          <span className="text-base font-bold">Log Out</span>
        </button>
      </div>
    </aside>
  );
};

export default MobileMenu;
