/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CopyIcon } from 'assets/common/copy.svg';
import { ReactComponent as EditIcon } from 'assets/common/pen.svg';

import Tooltip from 'components/common/text/tooltip';

import { ITableRow } from './types';

const TableRow: React.FC<ITableRow> = ({ rows }) => {
  return (
    <tr className="max-h-fit even:bg-dark-blue/50">
      {rows.map((r) => {
        const {
          id,
          colSize = 0,
          content,
          amountIcon = '',
          amountTokenCode = '',
          icon: Icon,
          iconAfter: IconAfter,
          tdClassName = '',
          wrapperClassName = '',
          amountIconClassName = '',
          iconClassName = '',
          iconAfterClassName = '',
          textClassName = '',
          changeClassName = '',
          changeTooltip = '',
          onClick,
          onCopy,
          onChange,
        } = r;

        return (
          <td
            key={id}
            colSpan={colSize}
            className={twMerge(
              'max-h-fit w-fit max-w-full whitespace-nowrap py-3 text-sm first:rounded-l-[14px] first:pl-6 last:rounded-r-[14px] last:pr-6 max-[1279px]:text-xs max-[680px]:text-[0.6rem] max-[680px]:first:pl-3 max-[680px]:last:pr-3',
              tdClassName,
            )}
          >
            <div className={twMerge('flex h-[30px] w-fit max-w-full items-center', wrapperClassName)}>
              {amountIcon ? (
                <Tooltip text={amountTokenCode}>
                  <img
                    className={twMerge(
                      'mr-2 h-auto w-[36px] max-[1279px]:w-[32px] max-[450px]:mr-0 max-[430px]:w-[24px]',
                      amountIconClassName,
                    )}
                    src={amountIcon}
                    alt={amountTokenCode}
                  />
                </Tooltip>
              ) : null}
              {Icon ? (
                <Icon
                  className={twMerge('mr-2 h-auto w-[16px] fill-[currentColor] max-[430px]:w-[12px]', iconClassName)}
                />
              ) : null}
              <span className={twMerge(textClassName)} onClick={onClick}>
                {content}
              </span>
              {IconAfter ? (
                <IconAfter
                  className={twMerge(
                    'mr-2 h-auto w-[16px] fill-[currentColor] max-[430px]:w-[12px]',
                    iconAfterClassName,
                  )}
                />
              ) : null}
              {onCopy ? (
                <Tooltip
                  text="Copied"
                  showOnAction
                  position="bottom"
                  className="relative z-[1] ml-auto mr-2 flex items-center justify-center"
                >
                  <button type="button" className="animation hover:opacity-70" onClick={onCopy}>
                    <CopyIcon className="h-auto w-[16px]" />
                  </button>
                </Tooltip>
              ) : null}
              {onChange ? (
                <Tooltip
                  text={changeTooltip || 'Change'}
                  position="left"
                  className={twMerge(
                    'relative z-[1] ml-2 flex items-center justify-center',
                    !onCopy ? 'ml-auto' : '',
                    changeClassName,
                  )}
                >
                  <button type="button" className="animation hover:opacity-70" onClick={onChange}>
                    <EditIcon className="h-auto w-[16px] fill-[currentColor]" />
                  </button>
                </Tooltip>
              ) : null}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
