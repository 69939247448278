/* eslint-disable react/button-has-type */
import { twMerge } from 'tailwind-merge';

import { IPlainButton } from './types';

const PlainButton: React.FC<IPlainButton> = ({ title, type, classList = '', ...props }) => {
  const styles = twMerge('animation hover:opacity-70 disabled:opacity-60', classList);

  return (
    <button type={type} className={styles} {...props}>
      {title}
    </button>
  );
};

export default PlainButton;
