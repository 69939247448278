import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { jwtDecode as decode } from 'jwt-decode';

import { useSession } from 'context/sessionManagementContext/context';
import { getAuthToken, getRefreshToken } from 'helpers/auth';

import Sidebar from 'components/common/base/sidebar';
import { IDecodedToken } from './types';

const AuthLayout: React.FC = () => {
  const { refreshTokens, logout } = useSession();

  useEffect(() => {
    const authToken = getAuthToken();
    const refreshToken = getRefreshToken();

    if (authToken) {
      const { exp } = decode<IDecodedToken>(authToken);

      const now = dayjs();
      const expireAt = dayjs(exp * 1000);

      // is expired
      if (expireAt.isBefore(now)) {
        refreshTokens();
        return;
      }
    }

    // does the user have a session
    if (!authToken && refreshToken) {
      refreshTokens();
      return;
    }

    // session expired
    if (!authToken && !refreshToken) {
      logout();
    }
  }, []);

  return (
    <div className="flex h-full w-full flex-grow">
      <Sidebar />
      <div className="container w-full flex-grow overflow-hidden px-9 py-10 max-[1023px]:max-w-full max-[420px]:px-4">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
