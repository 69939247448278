import { useEffect, useRef, useState } from 'react';

import useOnScreen from 'hooks/useOnScreen';
import useMediaQuery from 'hooks/useMediaQuery';

import Rates from './rates';

const RatesContainer: React.FC = () => {
  const listRef = useRef<HTMLUListElement>(null);
  const isVisible = useOnScreen(listRef);
  const isMobileView = useMediaQuery('(max-width: 767px)');

  const [showItems, setShowItems] = useState(false);

  useEffect(() => {
    if (isVisible && !showItems) {
      setShowItems(true);
    }
  }, [isVisible]);

  return <Rates listRef={listRef} showItems={showItems} isMobileView={isMobileView} />;
};

export default RatesContainer;
