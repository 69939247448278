import { FEATURES } from 'constants/home';

import FeaturesItem from '../featuresCard';
import { IFeaturesList } from './types';

const FeaturesList: React.FC<IFeaturesList> = ({ listRef, showItems }) => {
  return (
    <ul ref={listRef} className="flex w-full items-center justify-between max-[670px]:w-4/5 max-[670px]:flex-col">
      {FEATURES.map((f) => (
        <FeaturesItem key={f.id} classList={showItems ? 'animate__animated animate__fadeInLeft' : ''} feature={f} />
      ))}
    </ul>
  );
};

export default FeaturesList;
