import { useContext } from 'react';

import ModalsContext from 'context/modalsContext/context';
import { Modals } from 'context/modalsContext/types';

import UnauthButtons from './unauthButtons';

const UnauthButtonsContainer: React.FC = () => {
  const { showModal } = useContext(ModalsContext);

  const handleLogin = () => {
    showModal({ modal: Modals.login });
  };

  const handleRegistration = () => {
    showModal({ modal: Modals.registration });
  };

  return <UnauthButtons onLogin={handleLogin} onRegistration={handleRegistration} />;
};

export default UnauthButtonsContainer;
