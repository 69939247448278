import { twMerge } from 'tailwind-merge';

import { IBlockSkeleton } from './types';

const BlockSkeleton: React.FC<IBlockSkeleton> = ({ width = 100, height = 100, className = '' }) => {
  return (
    <div
      style={{ width, height }}
      className={twMerge('animate-pulse rounded-[10px] bg-light-gray bg-opacity-40', className)}
    />
  );
};

export default BlockSkeleton;
