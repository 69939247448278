/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import React, { useMemo, useState } from 'react';

import ModalsContext from './context';
import { IModalsContext, Modals, TModalData, TModalSetup } from './types';

const ModalsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<Modals>();
  const [data, setData] = useState<TModalData>(undefined);

  const modalData = useMemo<IModalsContext>(
    () => ({ modal: activeModal, data, showModal: handleShowModal }),
    [activeModal, data],
  );

  function handleShowModal(setup: TModalSetup) {
    const { modal, data = undefined } = setup;

    setActiveModal(modal);
    setData(data);
  }

  return <ModalsContext.Provider value={modalData}>{children}</ModalsContext.Provider>;
};

export default ModalsProvider;
