import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { ITableRow } from 'components/common/table/tableRow/types';
import { ITEMS_PER_PAGE, LOGIN_ACTVITY_TABLE_HEAD } from 'constants/table';
import { ACTIVITY } from 'graphql/user';
import { getLoginActivityVariables, getOffset } from 'helpers/request';
import { IUserActivity } from 'requestTypes';
import { getLoginActvityRows } from 'helpers/table';

import TableContainer from 'components/common/table/tableWithPagination';

const AccountActivityTable: React.FC = () => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const { data, loading, refetch } = useQuery(ACTIVITY, {
    fetchPolicy: 'cache-and-network',
    variables: getLoginActivityVariables({}),
  });

  useEffect(() => {
    if (data) {
      const activity = pathOr<IUserActivity[]>([], ['activity', 'items'], data);
      const newCount = pathOr(0, ['activity', 'count'], data);
      const newRows = getLoginActvityRows(activity);

      setRows(newRows);
      setCount(newCount);
    }
  }, [data]);

  const handleChangePage = (newPage: number) => {
    const offset = getOffset(newPage, ITEMS_PER_PAGE);
    const variables = getLoginActivityVariables({ offset, limit: ITEMS_PER_PAGE });

    setPage(newPage);
    refetch(variables);
  };

  return (
    <div className="flex h-[550px] w-full flex-col">
      <TableContainer
        headCells={LOGIN_ACTVITY_TABLE_HEAD}
        rows={rows}
        page={page}
        count={count}
        loading={loading}
        onChangePage={handleChangePage}
      />
    </div>
  );
};

export default AccountActivityTable;
