import { ReactComponent as CoinsIcon } from 'assets/common/coins.svg';
import { ReactComponent as NetworkIcon } from 'assets/common/network.svg';
import warningIcon from 'assets/common/warning-icon.webp';

import { getCurrencyIcon, getFormattedAmount } from 'helpers';

import Input from 'components/common/inputs/input';
import Select from 'components/common/select';
import AddressBookSelect from 'components/common/addressBookSelect';
import Button from 'components/common/buttons/button';

import { IWithdrawFirstStep } from './types';

const WithdrawFirstStep: React.FC<IWithdrawFirstStep> = ({
  formik,
  walletsOptions,
  networksOptions,
  wallet,
  network,
  withMemo,
  onChangeAmount,
  onMaxAmount,
  onChangeWithMFA,
}) => {
  return (
    <form className="flex w-full flex-col max-[400px]:w-[90%]" onSubmit={formik.handleSubmit}>
      <Select
        label="Select Currency:"
        labelAlign="center"
        labelWrapperClassList="mb-2"
        placeholder="Select Currency"
        placeholderIcon={CoinsIcon}
        selected={formik.values.currency}
        options={walletsOptions}
        errorMessage={formik.errors?.currency}
        onSelect={(v) => formik.setFieldValue('currency', v || '')}
      />
      <Select
        key={formik.values.currency}
        label="Select Network:"
        labelAlign="center"
        labelWrapperClassList="mb-2"
        placeholder="Select Network"
        placeholderIcon={NetworkIcon}
        selected={formik.values.network}
        options={networksOptions}
        disabled={!formik.values.currency}
        errorMessage={formik.errors?.network}
        onSelect={(v) => formik.setFieldValue('network', v)}
      />
      <Input
        formik={formik}
        name="amount"
        type="text"
        placeholder="Amount"
        label="Amount to Withdraw:"
        labelAlign="center"
        className="px-[calc(3rem+2px)]"
        iconClassList="left-[calc(1rem+2px)]"
        disabled={!formik.values.network}
        value={formik.values.amount}
        currencyIcon={getCurrencyIcon(formik.values.currency)}
        errorMessage={formik.errors?.amount}
        onBlur={formik.handleBlur}
        onChange={onChangeAmount}
        buttonText="MAX"
        onButtonClick={onMaxAmount}
      />
      <div className="my-2 flex h-[48px] w-full flex-col items-center text-[0.6rem] leading-4 text-light-gray">
        {wallet && network ? (
          <>
            <p className="flex items-center">
              <span>Available Balance:</span>
              <span className="mx-1 font-medium text-white">{getFormattedAmount(wallet.amount)}</span>
              <span>{wallet.tokenCode}</span>
            </p>
            <p className="flex items-center">
              <span>The minimum withdrawal amount is:</span>
              <span className="mx-1 font-medium text-white">{getFormattedAmount(network.minimumWithdrawal)}</span>
              <span>{wallet.tokenCode}</span>
            </p>
            <p className="flex items-center">
              <span>Network fee:</span>
              <span className="mx-1 font-medium text-white">{getFormattedAmount(network.withdrawalFee)}</span>
              <span>{wallet.tokenCode}</span>
            </p>
          </>
        ) : null}
      </div>
      <AddressBookSelect
        key={`${formik.values.currency}-${formik.values.network}`}
        label="Your Destination Address:"
        labelAlign="center"
        labelWrapperClassList="mb-2"
        placeholder="Enter or Select Address"
        selected={formik.values.address}
        selectedToken={formik.values.currency}
        selectedNetwork={formik.values.network}
        disabled={!formik.values.currency}
        errorMessage={formik.errors?.address}
        onSelect={(v) => formik.setFieldValue('address', v)}
        onMemo={(v) => formik.setFieldValue('memo', v)}
        onChangeWithMFA={onChangeWithMFA}
      />
      {withMemo ? (
        <>
          <Input
            formik={formik}
            name="memo"
            type="text"
            placeholder="MEMO phrase"
            label="MEMO phrase:"
            labelAlign="center"
            className="px-[calc(3rem+2px)]"
            disabled={!formik.values.network}
            value={formik.values.memo}
            errorMessage={formik.errors?.memo}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <div className="my-2 w-full flex-col rounded-lg bg-semi-black/70 px-4 py-2.5 shadow-lg backdrop-blur-sm">
            <div className="mb-2 flex items-center">
              <img className="mr-2 h-auto w-4" src={warningIcon} alt="Warning icon" />
              <h4 className="font-semibold">Attention</h4>
            </div>
            <p className="text-xs font-normal text-light-gray">
              The network you select supports MEMO. If the platform you are going to deposit funds on requires you to
              fill out MEMO information, please ensure it is filled out correctly. Missing or incorrectly filling out
              the MEMO may result in the loss of your funds.
            </p>
          </div>
        </>
      ) : null}
      <Button wrapperClassList="mt-8 w-[200px] mx-auto" type="submit" variant="main" title="Continue" />
    </form>
  );
};

export default WithdrawFirstStep;
