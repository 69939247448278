import { twMerge } from 'tailwind-merge';

import { IPageTitle } from './types';

const PageTitle: React.FC<IPageTitle> = ({ title, icon, color = 'orange', children }) => {
  const wrapperStyle = twMerge('mb-10 flex w-full items-center justify-between max-[640px]:flex-col');
  const pageTitleStyle = twMerge(
    'relative flex items-center pt-4 max-[640px]:w-full max-[640px]:justify-center max-[640px]:mb-4',
  );
  const lineStyle = twMerge(
    'absolute left-[calc(47px+1.5rem)] top-0 h-[3px] w-[100px] max-[640px]:left-1/2 max-[640px]:-translate-x-1/2',
    color === 'orange' ? 'bg-orange' : '',
    color === 'green' ? 'bg-green' : '',
    color === 'blue' ? 'bg-blue' : '',
  );
  const iconStyle = twMerge('pointer-events-none h-auto w-[45px] selection:pointer-events-none max-[640px]:hidden');
  const titleStyle = twMerge(
    'pointer-events-none ml-6 text-5xl font-extrabold uppercase selection:pointer-events-none max-[640px]:ml-0 max-[640px]:text-3xl max-[370px]:text-xl',
  );

  return (
    <div className={wrapperStyle}>
      <div className={pageTitleStyle}>
        <span className={lineStyle} />
        <img className={iconStyle} src={icon} alt={title} />
        <h1 className={titleStyle}>{title}</h1>
      </div>
      {children}
    </div>
  );
};

export default PageTitle;
