import { useState } from 'react';

import { DashboardTitle } from 'constants/dashboard';

import DashboardTable from './dashboardTable';

const DashboardTableContainer: React.FC = () => {
  const [title, setTitle] = useState<DashboardTitle>(DashboardTitle.referralActivity);

  const handleChangeTitle = (newTitle: string) => {
    setTitle(newTitle as DashboardTitle);
  };

  return <DashboardTable title={title} onChangeTitle={handleChangeTitle} />;
};

export default DashboardTableContainer;
