/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo, useState, useEffect } from 'react';
import DefaultSelect, { Props, PropsValue, MultiValue, SingleValue, ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { twMerge } from 'tailwind-merge';

import ErrorMessage from 'components/common/text/errorMessage';

import SelectClear from './components/selectClear';
import SelectControl from './components/selectControl';
import SelectNoOption from './components/selectNoOption';
import SelectOption from './components/selectOption';
import SelectPlaceholder from './components/selectPlaceholder';

import { ISelect, ISelectOption } from './types';

const CustomSelect: React.FC<ISelect> = ({
  selected,
  options,
  placeholder = '',
  placeholderIcon,
  className = '',
  containerClassName = '',
  menuClassName = '',
  optionClassName = '',
  placeholderIconClassName = '',
  createLabel = '',
  noOptionText = '',
  errorMessage = '',
  onlyHighlightError,
  disabled,
  creatable,
  clearable = false,
  onSelect,
  onCreateNew,
  onRemoveOption,
}) => {
  const getContainerStyles = () => twMerge('!pl-12', containerClassName);
  const getControlStyles = (disabled?: boolean, focused?: boolean) =>
    twMerge(
      '!rounded-[20px] !border: none  h-[38px] !border-2 !border-dark-blue !border-opacity-80 !bg-dark-blue/80',
      disabled ? '!border-dark-blue !border-opacity-60 !bg-dark-blue/60' : 'group-hover:!border-purple/50',
      focused ? '!border-purple/100 !shadow-none' : '',
      errorMessage ? '!border-red !bg-red/10' : '',
    );
  const getInputStyles = (disabled?: boolean) => twMerge('!text-white !text-sm', disabled ? '' : '');
  const getValueStyles = () => twMerge('!text-white !text-sm', disabled ? '' : '');
  const getMenuStyles = () =>
    twMerge(
      'animate__animated animate__fadeIn',
      'shadow-black !bg-transparent !border-none !rounded-[20px]',
      menuClassName,
    );
  const getMenuListStyles = () =>
    twMerge(
      '!max-h-[200px] !p-2 !rounded-[20px] !bg-dark-blue/90 !backdrop-blur !border-transparent !border-y-[10px] !border-r-[4px]',
    );
  const getOptionStyles = (focused?: boolean, selected?: boolean) =>
    twMerge(
      '!rounded-[20px] !px-10 !py-2 !text-sm !cursor-pointer',
      focused ? '!bg-dark-purple/80' : '',
      selected ? '!bg-dark-purple' : '',
      optionClassName,
    );
  const getPlaceholderStyles = () => twMerge('!text-sm !text-light-gray');
  const getIndicatiorStyles = () => twMerge('!mr-1');

  const props: Partial<Props<ISelectOption>> = useMemo(
    () => ({
      className: twMerge('group', className),
      classNames: {
        valueContainer: () => getContainerStyles(),
        control: ({ isDisabled, isFocused }) => getControlStyles(isDisabled, isFocused),
        input: ({ isDisabled }) => getInputStyles(isDisabled),
        singleValue: () => getValueStyles(),
        menu: () => getMenuStyles(),
        menuList: () => getMenuListStyles(),
        option: ({ isFocused, isSelected }) => getOptionStyles(isFocused, isSelected),
        placeholder: () => getPlaceholderStyles(),
        indicatorSeparator: () => '!hidden',
        indicatorsContainer: () => getIndicatiorStyles(),
        dropdownIndicator: ({ isDisabled }) => (!isDisabled ? 'cursor-pointer' : ''),
      },
      isSearchable: true,
      isClearable: clearable,
      menuShouldBlockScroll: true,
      backspaceRemovesValue: true,
      controlShouldRenderValue: true,
      components: {
        Option: SelectOption as any,
        Control: SelectControl as any,
        ClearIndicator: SelectClear as any,
        NoOptionsMessage: (props) => <SelectNoOption message={noOptionText} {...(props as any)} />,
        Placeholder: (props) => (
          <SelectPlaceholder
            placeholderIcon={placeholderIcon}
            placeholderIconClassName={placeholderIconClassName}
            {...(props as any)}
          />
        ),
      },
    }),
    [className, errorMessage, clearable, noOptionText, placeholderIcon, placeholderIconClassName],
  );

  const [value, setValue] = useState<PropsValue<ISelectOption>>();

  useEffect(() => {
    const selectedOption = options.find((o) => o.value === selected);

    setValue(selectedOption);
  }, [selected, options]);

  const handleChange = (
    newValue: MultiValue<ISelectOption> | SingleValue<ISelectOption>,
    triggeredAction: ActionMeta<ISelectOption>,
  ) => {
    const newSelected = newValue as ISelectOption | null;

    if (newSelected) {
      onSelect(newSelected.value);
      return;
    }

    if (triggeredAction.action === 'clear') {
      const option = triggeredAction.removedValues?.[0];

      if (option?.isCustom && onRemoveOption) {
        onRemoveOption(option.value);
      }
    }

    onSelect(null);
  };

  const handleCreate = (option: string) => {
    if (onCreateNew) onCreateNew(option);
  };

  return (
    <>
      {creatable ? (
        <CreatableSelect
          {...props}
          value={value}
          placeholder={placeholder}
          formatCreateLabel={createLabel ? (input) => `${createLabel}${input}` : undefined}
          options={options}
          isDisabled={disabled}
          isMulti={false}
          onChange={handleChange}
          onCreateOption={handleCreate}
        />
      ) : (
        <DefaultSelect
          {...props}
          value={value}
          placeholder={placeholder}
          options={options}
          isDisabled={disabled}
          isMulti={false}
          onChange={handleChange}
        />
      )}
      {errorMessage && !onlyHighlightError ? <ErrorMessage message={errorMessage} /> : null}
    </>
  );
};

export default CustomSelect;
