import Big from 'big.js';

import walletIcon from 'assets/withdraw/wallet.webp';

import { getFormattedAmount, getCurrencyIcon } from 'helpers';

import Button from 'components/common/buttons/button';

import { IWithdrawSecondStep } from './types';

const WithdrawSecondStep: React.FC<IWithdrawSecondStep> = ({ formik, network, onWithdraw }) => {
  return (
    <div className="flex h-[436px] w-full flex-col items-center">
      <div className="relative mb-6">
        <img className="relative z-10 h-auto w-[160px]" src={walletIcon} alt="Wallet Icon" />
        <div className="absolute left-1/2 top-1/2 h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 bg-purple blur-[40px]" />
      </div>
      <ul className="flex w-[320px] flex-col items-center text-sm max-[440px]:w-full max-[400px]:w-[90%]">
        <li className="flex w-full items-center justify-between border-b-[1px] border-light-gray py-2">
          <span className="text-gray">Amount:</span>
          <div className="flex items-center">
            <img
              className="mr-1 h-auto w-[32px]"
              src={getCurrencyIcon(formik.values.currency)}
              alt="formik.values.currency"
            />
            <span>{getFormattedAmount(formik.values.amount)}</span>
          </div>
        </li>
        <li className="flex w-full items-center justify-between border-b-[1px] border-light-gray py-2">
          <span className="text-gray">Network Fee:</span>
          <div className="flex items-center">
            <img
              className="mr-1 h-auto w-[32px]"
              src={getCurrencyIcon(formik.values.currency)}
              alt="formik.values.currency"
            />
            <span>{getFormattedAmount(network?.withdrawalFee || '0')}</span>
          </div>
        </li>
        <li className="flex w-full items-center justify-between border-b-[1px] border-light-gray py-2">
          <span className="text-gray">Total:</span>
          <div className="flex items-center">
            <img
              className="mr-1 h-auto w-[32px]"
              src={getCurrencyIcon(formik.values.currency)}
              alt="formik.values.currency"
            />
            <span>
              {getFormattedAmount(
                Big(formik.values.amount || '0')
                  .plus(network?.withdrawalFee || '0')
                  .toFixed(10),
              )}
            </span>
          </div>
        </li>
      </ul>
      <p className="mt-2 flex w-[320px] flex-col max-[440px]:w-full max-[400px]:w-[90%]">
        <span className="mb-2 text-sm text-gray">Sending to</span>
        <span className="truncate text-xs">{formik.values.address}</span>
      </p>
      <Button
        wrapperClassList="mt-8 w-[200px] mx-auto"
        type="button"
        variant="main"
        title="Withdraw"
        onClick={onWithdraw}
      />
    </div>
  );
};

export default WithdrawSecondStep;
