const config = {
  captchaKey: process.env.REACT_APP_CAPTCHA_KEY || '',
  zendeskHCUrl: process.env.REACT_APP_ZENDESK_HC_URL || '',
  graphqlUrl: process.env.REACT_APP_GRAPHQL_URL || '',
  mediaUrl: process.env.REACT_APP_MEDIA_URL || '',
};

export enum ZendeskEndpoints {
  sections = 'sections.json',
  articles = 'articles.json',
}

export const MediaEndpoints = {
  avatars: `${config.mediaUrl}/avatars/`,
  games: `${config.mediaUrl}/games_thumbnail/`,
  providers: `${config.mediaUrl}/providers_thumbnail/`,
  events: `${config.mediaUrl}/events_thumbnail/`,
  slider: `${config.mediaUrl}/sliders_thumbnail/`,
  background: `${config.mediaUrl}/backgrounds_thumbnail/`,
  logo: `${config.mediaUrl}/logo_thumbnail/`,
  footerLogos: `${config.mediaUrl}/footer_logos_thumbnail/`,
  wheel: `${config.mediaUrl}/wheel/`,
};

export enum StarbetsLinks {
  affiliate = 'https://starbets.io/?aff=',
  referral = 'https://starbets.io/?ref=',
}

export default config;
