/* eslint-disable react/button-has-type */
import clsx from 'clsx';

import { IIconButton } from './types';
import styles from './styles.module.scss';

const IconButton: React.FC<IIconButton> = ({ classList = '', children, ...props }) => {
  return (
    <button
      className={clsx(
        'animation rounded-full p-[2px] hover:bg-light-gray hover:bg-opacity-20',
        styles.button,
        classList,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default IconButton;
