import Big from 'big.js';
import { ISecurityValues } from 'components/pages/account/components/accountSecurity/types';
import { IUserSecurity } from 'types';
import { IBalance } from './currencies';
import { getUserSecurityMethod } from './auth';

export const isDisableCollectInCurrenciesButton = (availableBalance: IBalance[]): boolean => {
  const amount = availableBalance.find((el) => Big(el.amount).gte('0.00000001'));

  return !amount;
};

export const isDisabledChangeMFAButton = (state: ISecurityValues, defaultState?: IUserSecurity) => {
  const securityChangedStatus = [
    state.requredAtLogin !== defaultState?.requireLogin_2fa,
    getUserSecurityMethod(defaultState) !== state.mfaType,
  ];
  const isChanged = securityChangedStatus.find(Boolean);

  return !isChanged;
};
