import { gql } from '@apollo/client';

export const AFFILIATE_BALANCE = gql`
  query affiliateBalances {
    affiliateBalances {
      availableBalance
      totalCollected
      token {
        displayName
        tokenCode
      }
    }
  }
`;

export const CURRENCY_TO_WALLET = gql`
  mutation affiliateConvertBalances($token: String!) {
    affiliateConvertBalances(token: $token)
  }
`;

export const ALL_CURRENCIES_TO_WALLET = gql`
  mutation affiliateCollectBalances {
    affiliateCollectBalances
  }
`;
