import clsx from 'clsx';
import { Link } from 'react-router-dom';

import logo from 'assets/common/logo.webp';
import logoMobile from 'assets/common/logoMobile.webp';

import { Links } from 'constants/nav';

import { ILogo } from './types';
import styles from './styles.module.scss';

const Logo: React.FC<ILogo> = ({ auth = true }) => {
  return (
    <div className={clsx('flex items-center', auth ? 'max-[1023px]:mx-auto' : '')}>
      <Link to={auth ? Links.dashboard : Links.home} className="flex items-center">
        <div className={clsx('relative overflow-hidden', styles.logoAnimate)}>
          <picture>
            {!auth ? <source srcSet={logoMobile} media="(max-width: 580px)" /> : null}
            <img
              className={clsx(
                `relative w-[130px] max-[400px]:w-[100px] max-[330px]:w-[80px]`,
                auth ? '' : 'max-[580px]:w-[30px] max-[400px]:w-[30px] max-[330px]:w-[30px]',
              )}
              src={logo}
              alt="Starbets Logo"
            />
          </picture>
        </div>
      </Link>
      <div className={clsx('mx-6 h-[16px] w-[1px] bg-light-purple', auth ? '' : 'max-[580px]:hidden')} />
      <span
        className={clsx(
          'pointer-events-none font-semibold uppercase selection:pointer-events-none max-[400px]:text-sm max-[330px]:text-xs',
          auth ? '' : 'max-[580px]:hidden',
        )}
      >
        Affiliate
      </span>
    </div>
  );
};

export default Logo;
