/* eslint-disable jsx-a11y/label-has-associated-control */
import { twMerge } from 'tailwind-merge';

import CustomInput from './input';
import { IInput } from './types';

const Input: React.FC<IInput> = ({
  label = '',
  labelAlign = 'left',
  lableWrapperClassList = '',
  labelClassList = '',
  ...props
}) => {
  const lableWrapperStyles = twMerge('w-full flex flex-col mb-2', lableWrapperClassList);
  const labelStyles = twMerge(
    'mb-2 text-sm font-medium text-light-gray cursor-pointer',
    labelAlign === 'left' ? 'text-left' : '',
    labelAlign === 'center' ? 'text-center' : '',
    labelAlign === 'right' ? 'text-right' : '',
    labelClassList,
  );

  return label ? (
    <label className={lableWrapperStyles}>
      <span className={labelStyles}>{label}</span>
      <CustomInput {...props} />
    </label>
  ) : (
    <CustomInput {...props} />
  );
};

export default Input;
