import { twMerge } from 'tailwind-merge';

import { IHomepageTitle } from './types';

const HomepageTitle: React.FC<IHomepageTitle> = ({ component: Tag = 'h2', classList, children }) => {
  const styles = twMerge(
    'mb-8 text-center text-5xl font-extrabold uppercase tracking-[0.38px] max-[770px]:text-4xl max-[670px]:w-4/5 max-[670px]:mb-8 max-[425px]:font-bold',
    classList,
  );

  return <Tag className={styles}>{children}</Tag>;
};

export default HomepageTitle;
