import { useContext } from 'react';

import UserContext from 'context/userContext/context';

import DashboardOverviewProfile from './dashboardOverviewProfile';

const DashboardOverviewProfileContainer: React.FC = () => {
  const { profile, loading } = useContext(UserContext);

  return (
    <DashboardOverviewProfile
      avatar={profile?.user?.avatar?.avatar}
      email={profile?.user?.email}
      alias={profile?.user?.alias}
      loading={loading}
    />
  );
};

export default DashboardOverviewProfileContainer;
