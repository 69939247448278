import DashboardOverviewLink from '../dashboardOverviewLink';
import DashboardOverviewProfile from '../dashboardOverviewProfile';
import DashboardOverviewPromo from '../dashboardOverviewPromo';
import DashboardOverviewRate from '../dashboardOverviewRate';

const DashboardOverview: React.FC = () => {
  return (
    <ul className="mb-6 flex w-full items-center justify-between max-[1280px]:flex-wrap max-[720px]:flex-col">
      <DashboardOverviewProfile />
      <DashboardOverviewLink />
      <DashboardOverviewPromo />
      <DashboardOverviewRate />
    </ul>
  );
};

export default DashboardOverview;
