import { BrowserRouter } from 'react-router-dom';

import ApolloProvider from 'containers/apollo';
import Layout from 'containers/layout';
import Router from 'containers/router';
import ModalsProvider from 'context/modalsContext';
import UserProvider from 'context/userContext';
import SessionManagementProvider from 'context/sessionManagementContext';
import ZendeskProvider from 'context/contextZendesk';

const App: React.FC = () => {
  return (
    <SessionManagementProvider>
      <ApolloProvider>
        <BrowserRouter>
          <ZendeskProvider>
            <ModalsProvider>
              <UserProvider>
                <Layout>
                  <Router />
                </Layout>
              </UserProvider>
            </ModalsProvider>
          </ZendeskProvider>
        </BrowserRouter>
      </ApolloProvider>
    </SessionManagementProvider>
  );
};

export default App;
