import { twMerge } from 'tailwind-merge';

import cashbackIcon from 'assets/common/iconCashback.webp';

import Modal from 'components/common/base/modal';
import Button from 'components/common/buttons/button';
import CurrencyTemplate from 'components/common/currencyTemplate';

import { ICollect } from './types';

const Collect: React.FC<ICollect> = ({ onClick, currencies }) => {
  const textStyle = twMerge('xl:text-[14px] 2xl:text-[14px] max-h-[140px]');
  return (
    <Modal modalClassName="w-[460px] overflow-hidden" withPaddings={false}>
      <div className="flex h-full w-full flex-col items-center justify-start bg-gradient-radial p-[30px]">
        <img src={cashbackIcon} alt="wallet" className="mt-[30px] inline-block w-[180px]" />
        <p className="mb-[30px] mt-[30px] text-4xl font-bold text-yellow">CONGRATS!</p>
        <CurrencyTemplate currencies={currencies} classList={textStyle} />
        <p className="mt-[30px] w-[220px] text-center sm:w-[300px]">
          has successfully been transferred to your wallet.
        </p>
        <Button variant="main" wrapperClassList="w-[220px] sm:w-[300px] mt-[30px]" title="Continue" onClick={onClick} />
      </div>
    </Modal>
  );
};

export default Collect;
