/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CrossIcon } from 'assets/common/cross.svg';

import ModalsContext from 'context/modalsContext/context';

import IconButton from 'components/common/buttons/iconButton';

import { IModal } from './types';

const Modal: React.FC<IModal> = ({
  title = '',
  wrapperClassName = '',
  modalClassName = '',
  titleClassName = '',
  withPaddings = true,
  onClose,
  children,
}) => {
  const { showModal } = useContext(ModalsContext);

  const wrapperStyles = twMerge(
    'fixed z-50 flex h-screen w-screen items-center justify-center backdrop-blur-sm overflow-hidden outline-none',
    wrapperClassName,
  );
  const modalStyles = twMerge(
    'shadow-2xl relative max-w-[800px] max-h-[90%] flex flex-col items-center overflow-hidden overflow-y-auto rounded-[20px] bg-dark-purple max-[1100px]:max-w-[460px] max-[550px]:max-w-[400px] max-[450px]:max-w-[80%]',
    'animate__animated animate__fadeIn',
    withPaddings ? 'px-8 py-5 max-[450px]:px-4 max-[400px]:px-0' : '',
    modalClassName,
  );
  const lineStyles = twMerge('bgg-purple z-[15] absolute left-14 right-14 top-0 h-[3.5px] rounded-3xl');
  const titleStyles = twMerge(
    'mb-8 mx-auto uppercase text-4xl font-extrabold max-[1100px]:text-3xl max-[550px]:text-2xl max-[470px]:text-xl',
    titleClassName,
  );

  useEffect(() => {
    if (document?.body?.style) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (document?.body?.style) {
        document.body.style.overflow = 'unset';
      }
    };
  }, []);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    showModal({ modal: undefined });
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLElement>) => {
    if (ev.key === 'Escape') {
      handleClose();
    }
  };

  const handleClickOutside = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const element = ev.target as HTMLElement;

    if (element?.dataset?.modalWrapper) {
      handleClose();
    }
  };

  return (
    <article
      data-modal-wrapper
      tabIndex={0}
      className={wrapperStyles}
      onKeyDownCapture={handleKeyDown}
      onClick={handleClickOutside}
    >
      <div className={modalStyles}>
        <div className={lineStyles} />
        <IconButton type="button" classList="absolute z-[15] right-4 top-4" onClick={handleClose}>
          <CrossIcon className="fill-light-gray" />
        </IconButton>
        {title ? <h6 className={titleStyles}>{title}</h6> : null}
        {children}
      </div>
    </article>
  );
};

export default Modal;
