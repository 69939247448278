/* eslint-disable camelcase */
import { IUserSecurity } from 'types';
import { IChangePasswordVariables } from 'components/modals/changePassword/types';

export const getAuthToken = () => {
  return sessionStorage.getItem('authToken');
};

export const setAuthToken = (token: string) => {
  sessionStorage.setItem('authToken', token);
};

export const removeAuthToken = () => {
  sessionStorage.removeItem('authToken');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem('refreshToken', token);
};

export const removeRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

export const getUserSecurityMethod = (security?: IUserSecurity) => {
  const { google_2fa, email_2fa } = security || { google_2fa: false, email_2fa: false };

  if (google_2fa) {
    return 'google';
  }

  if (email_2fa) {
    return 'email';
  }

  return 'none';
};

export const persistChangePasswordData = (data: Partial<IChangePasswordVariables>) => {
  sessionStorage.setItem('changePasswordData', JSON.stringify(data));
};

export const getChangePasswordData = (): Partial<IChangePasswordVariables> | null => {
  const changePasswordData = sessionStorage.getItem('changePasswordData');

  if (changePasswordData) {
    const data = JSON.parse(changePasswordData);

    return data as Partial<IChangePasswordVariables>;
  }

  return null;
};

export const removePersistedChangePasswordData = () => {
  sessionStorage.removeItem('changePasswordData');
};
