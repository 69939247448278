import { ISelectOption } from 'components/common/select/types';
import { AffiliateContactType, AffiliateType } from 'types';

export enum AuthErrors {
  uppercaseLetter = 'The password must contain at least one uppercase letter.',
  lowercaseLetter = 'The password must contain at least one lowercase letter.',
  number = 'The password must contain at least one number.',
  passLength = 'The password must be at least 8 characters long.',
  forbiddenCharacters = "The password contains prohibited characters. Allowed characters: a-z, A-Z, 0-9 and special characters(such as the: *.!@$%^`'&(){}[]:;<>,.?\\/~_+-=| )",
  passwordIsNotValid = 'Password must contain 1 Uppercase, 1 Lowercase, 1 number minimum and 8 characters long.',
  passwordDoesNotMatch = 'Password confirmation must match the password.',
  notAdult = 'You should be 18 years old.',
  promocodeIsNotValid = 'The promo code must contain 10 characters.',
  unavailable = 'Unfortunately, StarBets.io is not available in your region',
  notVerified = 'Email is not verified',
  expiredSLT = 'Invalid or expired short-lived token.',
  tooManyRequests = 'Too Many Requests',
  wrongCreds = 'Wrong credentials',
  notApproved = 'Affiliate account is not approved',
  notFound = 'User not found',
  alreadyExists = 'Email already exists or has been used for another account',
}

export enum GraphqlErrors {
  mfaNotSelected = '2-Step Verification method not selected',
  mfaGoogle = 'Please, enter two-factor authentication (2FA) code',
  mfaEmail = 'Please, enter email two-factor authentication (2FA) one-time password',
  wrongCreds = 'Wrong credentials',
  unauthorized = 'Unauthorized',
}

export const AFFILIATE_CATEGORY = Object.entries(AffiliateType).reduce<{ [key: string]: string }>(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {},
);

export const AFFILIATES_TYPES = Object.values(AffiliateType)
  .filter(Boolean)
  .map<ISelectOption>((t) => ({
    label: t,
    value: t,
  }));

export const AFFILIATES_CONTACT_TYPES = Object.values(AffiliateContactType).map<ISelectOption>((t) => ({
  label: t,
  value: t,
}));
