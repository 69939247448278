/* eslint-disable jsx-a11y/label-has-associated-control */
import { twMerge } from 'tailwind-merge';

import CustomSelect from './select';
import { ISelectContainer } from './types';

const Select: React.FC<ISelectContainer> = ({
  label,
  labelAlign = 'left',
  labelWrapperClassList = '',
  labelClassList = '',
  ...props
}) => {
  const lableWrapperStyles = twMerge('w-full flex flex-col', labelWrapperClassList);
  const labelStyles = twMerge(
    'mb-2 text-sm font-medium text-light-gray cursor-pointer',
    labelAlign === 'left' ? 'text-left' : '',
    labelAlign === 'center' ? 'text-center' : '',
    labelAlign === 'right' ? 'text-right' : '',
    labelClassList,
  );

  return label ? (
    <label className={lableWrapperStyles}>
      <span className={labelStyles}>{label}</span>
      <CustomSelect {...props} />
    </label>
  ) : (
    <CustomSelect {...props} />
  );
};

export default Select;
