/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';
import { useEffect, useContext, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import ModalsContext from 'context/modalsContext/context';
import { initialLoginValues } from 'constants/formik';
import { loginValidationSchema } from 'constants/validation';
import { Modals } from 'context/modalsContext/types';
import { LOGIN, VERIFY_LOGIN } from 'graphql/auth';
import { PROFILE } from 'graphql/user';
import { ITokenPayload, IVerifyPayload } from 'requestTypes';
import { Links } from 'constants/nav';
import { removeAuthToken, removeRefreshToken, setAuthToken, setRefreshToken } from 'helpers/auth';
import { AuthErrors } from 'constants/auth';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';

import Login from './login';
import { ILoginValues } from './types';

const LoginContainer = () => {
  const { showModal } = useContext(ModalsContext);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const formik = useFormik<ILoginValues>({
    initialValues: initialLoginValues,
    validationSchema: loginValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const [login, { data, error: loginError, loading }] = useLazyQuery<ITokenPayload | IVerifyPayload>(LOGIN, {
    fetchPolicy: 'no-cache',
  });
  const [verifyLogin] = useLazyQuery<ITokenPayload>(VERIFY_LOGIN, { fetchPolicy: 'no-cache' });
  const [profile, { error: profileError }] = useLazyQuery(PROFILE);

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
  }, [formik.values]);

  useEffect(() => {
    if (data) {
      const loginData = pathOr<ITokenPayload | IVerifyPayload | null>(null, ['affiliateLogin'], data);

      if (loginData?.__typename === 'TokenPayload') {
        authUser(loginData.authToken, loginData.refreshToken);
      }

      if (loginData?.__typename === 'VerifyPayload') {
        showModal({
          modal: Modals.mfa,
          data: {
            mfaType: loginData.mfaType,
            action: handleVerifyLogin(loginData.shortLivedToken),
          },
        });
      }
    }
  }, [data]);

  useEffect(() => {
    const error = loginError || profileError;

    if (error) {
      switch (error.message) {
        case AuthErrors.unavailable: {
          showModal({ modal: Modals.blockedIp });
          break;
        }
        case AuthErrors.notVerified: {
          showModal({
            modal: Modals.verifyEmail,
            data: { email: formik.values.email, password: formik.values.password },
          });
          break;
        }
        case AuthErrors.wrongCreds: {
          setErrorMessage('Invalid email or password');
          break;
        }
        case AuthErrors.notFound: {
          setErrorMessage(
            'The user was not found or is not an affiliate. If this is not the case, please contact support',
          );
          break;
        }
        case AuthErrors.notApproved: {
          setErrorMessage('Affiliate account is not approved');
          break;
        }
        default: {
          setErrorMessage(DEFAULT_ERROR_MESSAGE);
          break;
        }
      }

      setTimeout(() => recaptchaRef.current?.reset(), 500);
    }
  }, [loginError, profileError]);

  async function authUser(authToken: string, refreshToken: string) {
    setAuthToken(authToken);
    setRefreshToken(refreshToken);

    await profile()
      .then((res) => {
        const affiliate = pathOr(null, ['data', 'affiliateProfile'], res);
        const isAffiliate = Boolean(affiliate);

        if (isAffiliate) {
          showModal({ modal: undefined });
          window.open(Links.dashboard, '_self');
        } else {
          removeAuthToken();
          removeRefreshToken();

          showModal({ modal: Modals.forbidden, data: 'You must be an affiliate to access the platform' });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[PROFILE_ERROR]', err);

        setErrorMessage(DEFAULT_ERROR_MESSAGE);
      });
  }

  async function handleSubmit(values: ILoginValues) {
    const valid = await formik.validateForm();

    if (Object.values(valid).length) {
      return;
    }

    const tokenCaptcha = await recaptchaRef.current?.executeAsync();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const variables = {
      email: values.email.toLowerCase(),
      password: values.password,
      captcha: tokenCaptcha,
    };

    login({ variables });
  }

  function handleVerifyLogin(sltToken: string) {
    return async (code: string, onError: (err: string) => void) => {
      const variables = { shortLivedToken: sltToken, securityCode: code };

      verifyLogin({ variables }).then((res) => {
        const { data, error } = res;

        if (error?.message === AuthErrors.tooManyRequests) {
          onError('You are temporarily banned for entering 5 incorrect codes. Please try again later.');
          return;
        }

        if (error?.message === AuthErrors.expiredSLT) {
          setErrorMessage('The authorization token has expired. Please login again.');
          return;
        }

        if (error?.message) {
          onError(error?.message);
          return;
        }

        const tokens = pathOr<ITokenPayload | null>(null, ['loginVerify'], data);

        if (tokens) {
          authUser(tokens.authToken, tokens.refreshToken);
        } else {
          setErrorMessage(DEFAULT_ERROR_MESSAGE);
        }
      });
    };
  }

  const handleRegistration = () => {
    showModal({ modal: Modals.registration });
  };

  const handleForgotPassword = () => {
    showModal({ modal: Modals.forgotPassword });
  };

  return (
    <Login
      formik={formik}
      recaptcha={recaptchaRef}
      errorMessage={errorMessage}
      loading={loading}
      onRegistration={handleRegistration}
      onForgotPassword={handleForgotPassword}
    />
  );
};

export default LoginContainer;
